import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { actionCreators as ruleActionCreators } from '../../store/modules/Rule';
import { actionCreators as rankingActionCreators } from '../../store/modules/Ranking';
import { actionCreators as actionTypeActionCreators } from '../../store/modules/ActionType';
import { actionCreators as filterActionCreators } from '../../store/modules/Filter';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import SelectField, { Convert } from '../../controls/SelectField';
import DeleteButton from '../../controls/DeleteButton';
import DataTypeField from '../../controls/DataTypeField';
import OperatorField from '../../controls/OperatorField';
import { Aggregate, DataType, ConvertToOptions, SortOrder, Operator } from '../../utils/enum';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import CopyButton from '../../controls/CopyButton';
import helper from '../../utils/helper';

class RuleEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      template: {
        name: '',
        description: '',
        actionType: { id: null },
        filters: [],
        conditions: [],
        imageId: '',
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const {
      id, requestGet, requestAllActionTypes, requestAllFilters,
    } = this.props;

    if (id) {
      requestGet(id);
    }
    requestAllActionTypes();
    requestAllFilters();
  }

  handleSave(values) {
    const {
      id, copy, gameMode, requestPost, requestPut,
    } = this.props;

    if (id && !copy) {
      requestPut(id, values, !gameMode);
    } else {
      requestPost(values, !gameMode);
    }
  }

  handleStatus(enabled) {
    const { id, requestEnable, requestDisable } = this.props;

    if (enabled) {
      requestEnable(id);
    } else {
      requestDisable(id);
    }
  }

  renderRule = (values, errors, index, arrayHelpers) => {
    const { id, actionTypes, isRuleMode } = this.props;

    const actionTypeId = id ? values.actionType.id : values.actionType.id;

    const actionType = actionTypes.find(type => type.id === actionTypeId);

    let actionTypeFields = actionTypeId && values.conditions[index].aggregate && actionType ? actionType.fields : [];

    if (Aggregate[values.conditions[index].aggregate] === Aggregate.Count) {
      actionTypeFields = actionTypeFields.filter(field => field.dataType === DataType.Text || field.dataType === DataType.Number);
    } else {
      actionTypeFields = actionTypeFields.filter(field => field.dataType === DataType.Number);
    }

    const actionTypeField = actionTypeFields.find(field => field.id === values.conditions[index].actionTypeFieldId);

    return (
      <div className="row attribute" key={index}>
        {index > 0 && <span className="and-label">and</span>}
        <button type="button" className="glyphbutton"
          title="Delete Condition" onClick={() => arrayHelpers.remove(index)}>
          <span className="btn-sm btn-danger link glyphicon glyphicon-minus pull-right" />
        </button>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.aggregate`} className="control-label font-bold">
              Aggregate
              <Field id={`conditions.${index}.aggregate`}
                name={`conditions.${index}.aggregate`}
                component={SelectField}
                options={ConvertToOptions(Aggregate)}
                onChange={form => {
                  form.setFieldValue(`conditions.${index}.operator`, isRuleMode ? null : Operator.Sort);
                  form.setFieldValue(`conditions.${index}.value`, '');
                  form.setFieldValue(`conditions.${index}.actionTypeFieldId`, '');
                }}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].aggregate
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className={isRuleMode ? "col-lg-3 col-md-3 col-sm-12 col-xs-12" : "col-lg-6 col-md-6 col-sm-12 col-xs-12"}>
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.actionTypeFieldId`} className="control-label font-bold">
              Field
              <Field id={`conditions.${index}.actionTypeFieldId`}
                name={`conditions.${index}.actionTypeFieldId`}
                component={SelectField}
                options={Convert(actionTypeFields)}
                onChange={form => {
                  form.setFieldValue(`conditions.${index}.operator`, isRuleMode ? null : Operator.Sort);
                  form.setFieldValue(`conditions.${index}.value`, '');
                }}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].actionTypeFieldId
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        { isRuleMode ? <>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="form-group form-group-lg">
              <label htmlFor={`conditions.${index}.operator`} className="control-label font-bold">
                Operator
                <Field id={`conditions.${index}.operator`}
                  name={`conditions.${index}.operator`}
                  component={OperatorField}
                  dataType={actionTypeField ? actionTypeField.dataType : null}
                  className={
                    errors.conditions && errors.conditions[index] && errors.conditions[index].operator
                      ? 'form-control error'
                      : 'form-control'
                  } />
              </label>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <div className="form-group form-group-lg">
              <label htmlFor={`conditions.${index}.value`} className="control-label font-bold">
                Value
                <Field id={`conditions.${index}.value`}
                  name={`conditions.${index}.value`}
                  component={DataTypeField}
                  dataType={DataType.Number}
                  className={
                    errors.conditions && errors.conditions[index] && errors.conditions[index].value
                      ? 'form-control error'
                      : 'form-control'
                  } />
              </label>
            </div>
          </div>
          </>
          :
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="form-group form-group-lg">
              <label htmlFor={`conditions.${index}.value`} className="control-label font-bold">
                Sort
                <Field id={`conditions.${index}.value`}
                name={`conditions.${index}.value`}
                component={SelectField}
                options={ConvertToOptions(SortOrder)}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].value ? 'form-control error' : 'form-control'
                } />
              </label>
            </div>
          </div>
        }
      </div>
    );
  };

  render() {
    const {
      id, copy, gameMode, rule, requestDelete, actionTypes, onBackClick, filters, isRuleMode,
    } = this.props;
    const { template } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(50)
            .required(),
          description: Yup.string().nullable(),
          actionType: Yup.object().shape({
            id: Yup.number()
              .integer()
              .required(),
          }),
          conditions: Yup.array().of(
            Yup.object().shape({
              aggregate: Yup.number()
                .integer()
                .required(),
              actionTypeFieldId: Yup.number()
                .integer()
                .required(),
              operator: Yup.number()
                .integer()
                .required(),
              value: Yup.string().required(),
            }),
          ).required().min(1),
          imageId: Yup.string().nullable(),
        })}
        initialValues={rule || template}>
          {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">
                    {id && !copy && `Edit ${isRuleMode ? 'Rule' : 'Ranking'}`}
                    {id && copy && `Copy ${isRuleMode ? 'Rule' : 'Ranking'}`}
                    {!id && `New ${isRuleMode ? 'Rule' : 'Ranking'}`}
                  </span>
                  <span className="sub-heading hex-img">
                    {
                      isRuleMode ? 
                      'Rule is a set of conditions telling players what they should do in order to achieve success in a game' : 
                      'Ranking is a condition telling players what they should do in order to get higher position in a leaderboard'
                    }
                  </span>
                  <div className="pageStyle">
                    <BackButton onClick={onBackClick} />
                    {id && !copy && !gameMode && <CopyButton to={`${id}/copy`} />}
                    {id && !copy && !gameMode && <DeleteButton onClick={() => requestDelete(id)} />}
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} form={this.form}
                        single />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="name" className="control-label font-bold">
                                Name
                                <Field id="name"
                                  name="name"
                                  autoFocus
                                  className={errors.name ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          {!id && (
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="actionType.id" className="control-label font-bold">
                                  Action
                                  <Field id="actionType.id"
                                    name="actionType.id"
                                    component={SelectField}
                                    options={Convert(actionTypes)}
                                    onChange={form => {
                                      form.setFieldValue('conditions', []);
                                      form.setFieldValue('filters', []);
                                    }}
                                    className={errors.actionType ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="description" className="control-label font-bold">
                                Description
                                <Field id="description"
                                  name="description"
                                  value={values.description || ''}
                                  className={errors.description ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="filters" className="control-label font-bold">
                                Filters
                                <Field id="filters"
                                  name="filters"
                                  component={SelectField}
                                  options={Convert(filters.filter(f => f.actionType.id === values.actionType.id))}
                                  isMulti
                                  className={errors.filters ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <FieldArray name="conditions"
                          render={arrayHelpers => (
                            <div className="form-subsection">
                              <h4>Conditions</h4>
                              <button type="button"
                                className="glyphbutton"
                                title="Add"
                                disabled={!isRuleMode && values.conditions && values.conditions.length > 0}
                                onClick={() => arrayHelpers.push({
                                  aggregate: '',
                                  actionTypeFieldId: '',
                                  operator: isRuleMode ? null : Operator.Sort,
                                  value: '',
                                })}>
                                <span className={`btn-sm btn-success pull-right link glyphicon glyphicon-plus ${values.conditions && values.conditions.length === 0 ? 'btn-error' : ''}`} />
                              </button>
                              {values.conditions
                                && values.conditions.map((obj, index) => this.renderRule(values, errors, index, arrayHelpers))}
                            </div>
                          )} />
                      </div>
                      {!copy
                        && rule && (
                        <div className="col-md-3 form-additional-info">
                          <label>
                            Created
                            <div>{date.long(rule.createdOn)}</div>
                          </label>
                          <label>
                            Action
                            <div>{rule.actionType.name}</div>
                          </label>
                          <label htmlFor="status-switch">
                            Disabled
                            <div>
                              <Switch id="status-switch"
                                onChange={() => this.handleStatus(rule.isDisabled)}
                                checked={rule.isDisabled}
                                checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
        </Formik>
    );
  }
}

RuleEdit.propTypes = {
  id: PropTypes.number,
  isRuleMode: PropTypes.bool.isRequired,
  copy: PropTypes.bool,
  gameMode: PropTypes.bool,
  rule: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
  requestAllActionTypes: PropTypes.func.isRequired,
  requestAllFilters: PropTypes.func.isRequired,
  actionTypes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  onBackClick: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
};

RuleEdit.defaultProps = {
  id: null,
  copy: false,
  gameMode: false,
  rule: null,
  onBackClick: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    isRuleMode: helper.ruleResolver(ownProps, true, false),
    copy: helper.getCopyFromProps(ownProps),
    rule: helper.ruleResolver(ownProps, helper.getEntityById(state.rule.details, ownProps), helper.getEntityById(state.ranking.details, ownProps),),
    actionTypes: state.actionType.all,
    filters: state.filter.all,
  }),
  (dispatch, ownProps) => bindActionCreators(
    {
      ...helper.ruleResolver(ownProps, ruleActionCreators, rankingActionCreators),
      requestAllActionTypes: actionTypeActionCreators.requestAll,
      requestAllFilters: filterActionCreators.requestAll,
    },
    dispatch,
  ),
)(RuleEdit);
