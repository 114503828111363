import React from 'react';
import PropTypes from 'prop-types';

const SaveButton = props => {
  const { onClick, disabled } = props;

  if (onClick) {
    return (
      <button type="button" className="active"
        disabled={disabled} onClick={onClick}>
        <i className="glyphicon glyphicon-floppy-disk" />
        <div className="hover-text">Save</div>
      </button>
    );
  }
  return (
    <button type="submit" className="active"
      disabled={disabled}>
      <i className="glyphicon glyphicon-floppy-disk" />
      <div className="hover-text">Save</div>
    </button>
  );
};

SaveButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SaveButton.defaultProps = {
  onClick: null,
  disabled: false,
};

export default SaveButton;
