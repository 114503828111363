import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Conjunction } from '../../utils/enum';
import GameRewardUnion from './RewardUnion';
import GameUnionActions from './UnionActions';
import ConjunctionIndicator from '../../controls/ConjunctionIndicator';
import GameRewardsRank from './RewardsRank';

class GameRewards extends Component {
  removeFromGame = (array, element) => {
    const { onChange } = this.props;

    const index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
      onChange();
    }
  };

  render() {
    const {
      game, onChange, advancedMode, onAdd, onClick, isGameMode,
    } = this.props;

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

    const onUnionAddClick = () => {
      game.rewardUnions.push({
        id: new Date().getMilliseconds(),
        participantId: activeParticipant ? activeParticipant.participant.id : null,
        rewards: [],
        currency: null,
        experience: null,
        rankFrom: isGameMode ? null : 1,
        rankTo: isGameMode ? null : 100,
      });
      onChange();
    };

    const rewardUnions = game.rewardUnions.filter(ru => ru.participantId === (activeParticipant ? activeParticipant.participant.id : null));

    const getWarningMessage = () => {
      let hasAnyReward = false;
      rewardUnions.forEach((union) => {
        if (union.rewards.length > 0 || union.experience > 0 || union.currency > 0) hasAnyReward = true;
      });
      if (!hasAnyReward) {
        if (activeParticipant)
          return 'Default player profile used!';
        else
          return 'Players will receive no rewards!';
      }
      if (hasAnyReward && !isGameMode && !game.period & !game.endOn) return 'Leaderboard has unlimited period!';
      return null;
    }

    return (
      <React.Fragment>
        <div className={getWarningMessage() ? 'union-title warning' : 'union-title valid'}>
          <i className="glyphicon glyphicon-gift" />
          {advancedMode ? 'Reward Groups' : 'Rewards'}{' '}
          <span className="warning">{getWarningMessage()}</span>
          {!isGameMode && !advancedMode && rewardUnions.length > 0 && 
            <GameRewardsRank rewardUnion={rewardUnions[0]} canEdit={true} onChange={onChange} />
          }
        </div>
        <ul className={advancedMode ? 'tree' : 'no-tree'}>
          {(isGameMode || !activeParticipant) && rewardUnions.map((union, index) => (
            <li key={`union-${union.id}`}>
              {index > 0 && (
                <ConjunctionIndicator conjunction={Conjunction.And}
                  then={false} />
              )}
              <GameRewardUnion union={union}
                onChange={onChange}
                onUnionRemove={() => this.removeFromGame(game.rewardUnions, union)}
                advancedMode={advancedMode}
                canEdit={true}
                onAdd={onAdd}
                onClick={onClick} />
            </li>
          ))}
        </ul>

        {(isGameMode || !activeParticipant) && advancedMode && (
            <GameUnionActions data={rewardUnions}
              conjunction={Conjunction.And}
              groupMode
              then={false}
              or={false}
              onSelect={() => onUnionAddClick()} />
        )}
      </React.Fragment>
    );
  }
}

GameRewards.propTypes = {
  game: PropTypes.shape({ id: PropTypes.number }).isRequired,
  isGameMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  advancedMode: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GameRewards;
