import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from 'reactjs-popup';
import { actionCreators } from '../../store/modules/Game';
import { actionCreators as ruleActionCreators } from '../../store/modules/Rule';
import { actionCreators as rankingActionCreators } from '../../store/modules/Ranking';
import { actionCreators as participantActionCreators } from '../../store/modules/Participant';
import { actionCreators as rewardActionCreators } from '../../store/modules/Reward';
import Image, { GetImageSrc } from '../../controls/Image';
import { GameElement } from '../../utils/enum';
import CopyButton from '../../controls/CopyButton';

class GameElementSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: null,
      dataLoaded: false,
      selected: [],
    };
    this.currency = { id: 'currency', name: 'Currency' };
    this.experience = { id: 'experience', name: 'Experience' };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      isOpen, mode, requestAll, requestAllRules, requestAllRankings, requestAllParticipants, requestAllRewards,
    } = this.props;

    const { dataLoaded } = this.state;

    if (!dataLoaded && newProps.isOpen && !isOpen) {
      if (mode === GameElement.Participants) {
        requestAllParticipants();
      } else if (mode === GameElement.Rewards) {
        requestAllRewards();
      } else if (mode === GameElement.Rules) {
        requestAllRules();
      } else if (mode === GameElement.Games) {
        requestAll();
      } else if (mode === GameElement.Rankings) {
        requestAllRankings();
      }

      this.setState({ searchText: null, selected: [], dataLoaded: true });
    } else {
      this.setState({ searchText: null, selected: [] });
    }
  }

  handleSelectElement = element => {
    const { multiple } = this.props;
    const { selected } = this.state;

    if (selected.indexOf(element) > -1) {
      this.setState({ selected: selected.filter(el => el !== element) });
    } else {
      if (multiple)
        this.setState({ selected: [...selected, element] });
      else
        this.setState({ selected: [element] });
    }
  };

  handleSelect = () => {
    const { onSelect } = this.props;
    const { selected } = this.state;

    selected.forEach(element => {
      if (element === this.currency) onSelect(null, null, 5);
      else if (element === this.experience) onSelect(null, 100);
      else onSelect(element);
    });
  };

  render() {
    const { searchText, dataLoaded, selected } = this.state;
    const {
      games, rules, rewards, participants, isOpen, mode, onClose, existing, onAdd, rankings,
    } = this.props;

    let data = [];

    if (mode === GameElement.Participants) {
      data = participants;
    } else if (mode === GameElement.Rewards) {
      data = [this.currency, this.experience, ...rewards];
    } else if (mode === GameElement.Rules) {
      data = rules;
    } else if (mode === GameElement.Rankings) {
      data = rankings;
    } else if (mode === GameElement.Games) {
      data = games.filter(game => game.publishedOn && !game.hasGames);
    }

    data = data.filter(
      d => !existing.find(exist => exist.id === d.id)
        && (!searchText || d.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
        && !d.isDisabled,
    );

    return (
      <Popup open={isOpen} closeOnDocumentClick
        onClose={() => onClose()} className="game-editor">
        <div className="game-element-selector">
          <div id="dataForm">
            <div className="row m-b-sm">
              <div className="col-xs-12">
                <span className="page-title">{GameElement[mode]}</span>
              </div>
            </div>
            <div className="row p-l-md">
              <div className="form-group form-group-lg is-empty">
                <input id="searchBox"
                  autoFocus
                  className="form-control"
                  placeholder="Search"
                  value={searchText || ''}
                  onChange={e => this.setState({ searchText: e.target.value })} />
              </div>
            </div>
            <div className="row p-l-md" id="scroller">
              {data.map(reward => (
                <div className="col-xs-12" key={`add-reward-${reward.id}`}>
                  <button type="button"
                    onClick={() => this.handleSelectElement(reward)}
                    className={`${reward.id} ${selected.indexOf(reward) > -1 ? 'selected' : ''}`}>
                    <Image src={GetImageSrc(reward.imageId, 40)} alt="reward" />
                    {reward.name}
                  </button>
                  {onAdd
                    && reward.id !== 'currency'
                    && reward.id !== 'experience' && <CopyButton onClick={() => onAdd(reward)} />}
                </div>
              ))}
              {dataLoaded && data.length === 0 && <p>No records found!</p>}
            </div>
          </div>

          <div className="actions">
            <button type="button" onClick={this.handleSelect}
              className="btn-select">
              Select
            </button>
            <button type="button" onClick={onClose}
              className="btn-close">
              Close
            </button>

            {onAdd && (
              <button type="button" onClick={onAdd}
                className="btn-new">
                New
              </button>
            )}
          </div>
        </div>
      </Popup>
    );
  }
}

GameElementSelector.propTypes = {
  requestAll: PropTypes.func.isRequired,
  requestAllRules: PropTypes.func.isRequired,
  requestAllRankings: PropTypes.func.isRequired,
  requestAllParticipants: PropTypes.func.isRequired,
  requestAllRewards: PropTypes.func.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  rules: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  rewards: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  rankings: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  existing: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onAdd: PropTypes.func,
  multiple: PropTypes.bool,
};

GameElementSelector.defaultProps = {
  existing: [],
  onAdd: null,
  multiple: true,
};

export default connect(
  state => ({
    rules: state.rule.all,
    participants: state.participant.all,
    rewards: state.reward.all,
    games: state.game.all,
    rankings: state.ranking.all,
  }),
  dispatch => bindActionCreators(
    {
      ...actionCreators,
      requestAllRules: ruleActionCreators.requestAll,
      requestAllRankings: rankingActionCreators.requestAll,
      requestAllParticipants: participantActionCreators.requestAll,
      requestAllRewards: rewardActionCreators.requestAll,
    },
    dispatch,
  ),
)(GameElementSelector);
