import React from 'react';
import PropTypes from 'prop-types';
import SelectField from './SelectField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import DateSelectField, { Mode } from './DateSelectField';
import {
  DataType, YesNo, ConvertToOptions, Operator,
} from '../utils/enum';

const DataTypeField = ({
  field, form, className, dataType, operator, readOnly, onChange,
}) => {
  let { value } = field;

  if (value === undefined) value = null;

  if (value === 0) value = '0';

  if (!dataType || dataType === DataType.Text || dataType === DataType.Number) {
    return (
      <input name={field.name}
        placeholder=""
        readOnly={readOnly}
        className={className}
        value={value || ''}
        type={dataType === DataType.Number ? 'number' : 'text'}
        onChange={e => {
          form.setFieldValue(field.name, e.target.value ? e.target.value : null);
          if (typeof onChange !== 'undefined' && onChange != null) {
            onChange(form, e.target.value ? e.target.value : null);
          }
        }}
        onBlur={field.onBlur} />
    );
  }

  if (dataType === DataType.Boolean) {
    return (
      <SelectField options={ConvertToOptions(YesNo)} field={field}
        onChange={onChange}
        form={form} className={className}
        isDisabled={readOnly} />
    );
  }

  if (dataType === DataType.Date) {
    if (operator === Operator.WeekdayIs || operator === Operator.WeekdayIsNot) {
      return (
        <DateSelectField field={field} form={form}
          mode={Mode.Weekday} className={className}
          onChange={onChange}
          isDisabled={readOnly} />
      );
    }
    if (operator === Operator.YearIs || operator === Operator.YearIsNot) {
      return (
        <DateSelectField field={field} form={form}
          mode={Mode.Year} className={className}
          onChange={onChange}
          isDisabled={readOnly} />
      );
    }
    if (operator === Operator.MonthIs || operator === Operator.MonthIsNot) {
      return (
        <DateSelectField field={field} form={form}
          mode={Mode.Month} className={className}
          onChange={onChange}
          isDisabled={readOnly} />
      );
    }
    if (operator === Operator.DayIs || operator === Operator.DayIsNot) {
      return (
        <DateSelectField field={field} form={form}
          mode={Mode.Day} className={className}
          onChange={onChange}
          isDisabled={readOnly} />
      );
    }
    if (operator === Operator.HourIs || operator === Operator.HourIsNot) {
      return (
        <DateSelectField field={field} form={form}
          mode={Mode.Hour} className={className}
          onChange={onChange}
          isDisabled={readOnly} />
      );
    }
    return (
      <DateTimeField field={field} form={form}
        onChange={onChange}
        className={className} readOnly={readOnly} />
    );
  }

  if (dataType === DataType.DateOnly) {
    return (
      <DateField field={field} form={form}
        onChange={onChange}
        className={className} readOnly={readOnly} />
    );
  }

  return <div className={className} />;
};

DataTypeField.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  className: PropTypes.string.isRequired,
  dataType: PropTypes.number,
  operator: PropTypes.number,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

DataTypeField.defaultProps = {
  dataType: null,
  operator: null,
  readOnly: false,
  onChange: null,
};

export default DataTypeField;
