import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import * as User from './modules/User';
import * as Tenant from './modules/Tenant';
import * as Alert from './modules/Alert';
import * as Player from './modules/Player';
import * as File from './modules/File';
import * as Game from './modules/Game';
import * as Leaderboard from './modules/Leaderboard';
import * as Rule from './modules/Rule';
import * as Ranking from './modules/Ranking';
import * as Filter from './modules/Filter';
import * as Reward from './modules/Reward';
import * as Participant from './modules/Participant';
import * as Group from './modules/Group';
import * as PlayerAttribute from './modules/PlayerAttribute';
import * as GameAttribute from './modules/GameAttribute';
import * as Level from './modules/Level';
import * as ActionType from './modules/ActionType';
import * as Action from './modules/Action';
import * as Log from './modules/Log';
import * as Transaction from './modules/Transaction';
import * as Notification from './modules/Notification';
import * as NotificationType from './modules/NotificationType';
import * as Dashboard from './modules/Dashboard';
import * as Setting from './modules/Setting';
import * as Search from './modules/Search';
import { SettingType } from '../utils/enum';

export default function configureStore(history, initialState) {
  const reducers = {
    user: User.reducer,
    player: Player.reducer,
    tenant: Tenant.reducer,
    alert: Alert.reducer,
    file: File.reducer,
    game: Game.reducer,
    leaderboard: Leaderboard.reducer,
    rule: Rule.reducer,
    ranking: Ranking.reducer,
    filter: Filter.reducer,
    reward: Reward.reducer,
    participant: Participant.reducer,
    group: Group.reducer,
    playerAttribute: PlayerAttribute.reducer,
    gameAttribute: GameAttribute.reducer,
    level: Level.reducer,
    actionType: ActionType.reducer,
    action: Action.reducer,
    log: Log.reducer,
    transaction: Transaction.reducer,
    notification: Notification.reducer,
    notificationType: NotificationType.reducer,
    dashboard: Dashboard.reducer,
    setting: Setting.reducer,
    search: Search.reducer,
  };

  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const appReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  const rootReducer = (state, action) => {
    if (
      action.type === User.requestLogoutType
      || (action.type === LOCATION_CHANGE
        && action.payload
        && action.payload.location
        && (action.payload.location.pathname === '/reset'
          || action.payload.location.pathname === '/logout'))
    ) {
      return appReducer(undefined, action);
    }

    if (action.type === User.selectTenant) {
      Object.keys(state).forEach(key => {
        if (state[key].list) {
          state[key].list.data = [];
          if (state[key].list.paging) {
            state[key].list.paging.pageIndex = 0;
          }
        }
        if (key !== 'tenant' && state[key].all) {
          state[key].all = [];
        }
      });
    }

    if (action.type === Setting.afterReceiveUserType) {
      Object.keys(state).forEach(key => {
        if (state[key].list && state[key].list.preferTable !== undefined) {
          state[key].list.preferTable = Setting.selectors.getSettingAsBoolean(state, SettingType.PreferTable);
        }
      });
    }

    return appReducer(state, action);
  };

  const persistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: ['alert', 'router'],
    stateReconciler: autoMergeLevel2,
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  return createStore(
    persistedReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers,
    ),
  );
}
