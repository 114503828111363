import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { LinkContainer } from 'react-router-bootstrap';
import { AnimatePresence } from 'framer-motion';
import { selectors } from '../store/modules/Setting';
import { SettingType } from '../utils/enum';
import animate from '../utils/animate';
import ListFilter from './ListFilter';

class List extends Component {
  componentDidMount() {
    const {
      requestList, pageIndex, pageSize, sortBy, sortDescending, filters,
    } = this.props;

    if (requestList) requestList(pageIndex, pageSize, sortBy, sortDescending, filters);
  }

  handlePageChange = data => {
    const {
      requestList, pageSize, sortBy, sortDescending, pageIndex, filters,
    } = this.props;
    const { selected } = data;

    if (pageIndex !== selected && requestList) requestList(selected, pageSize, sortBy, sortDescending, filters);
  };

  handleSortChange = (sortBy, sortDescending) => {
    const {
      requestList, pageSize, filters,
    } = this.props;

    if (requestList) requestList(0, pageSize, sortBy, sortDescending, filters);
  };

  handleFilterChange = (field, value) => {
    const {
      requestList, pageSize, sortBy, sortDescending, filters,
    } = this.props;

    const filter = filters.fields.find(f => f.name === field.name);
    if (!filter || !filters.isVisible) return;

    if (filter.value !== value) {
      filter.value = value;
    } else return;

    if (requestList) requestList(0, pageSize, sortBy, sortDescending, filters);
  };

  handleFilterVisibilityChange = () => {
    const {
      requestList, pageSize, sortBy, sortDescending, filters, switchFilterVisibility,
    } = this.props;

    switchFilterVisibility();

    if (requestList && filters.fields.find(field => field.value != null)) {
      requestList(0, pageSize, sortBy, sortDescending, { ...filters, isVisible: !filters.isVisible });
    }
  };

  render() {
    const {
      children,
      title,
      subtitle,
      totalPages,
      totalItems,
      tableColumns,
      tableData,
      newLink,
      sortBy,
      sortDescending,
      pageSize,
      pageIndex,
      preferTable,
      switchDisplayStyle,
      subComponent,
      filters,
      switchFilterVisibility,
      requestExport,
    } = this.props;

    return (
      <div id="dataForm" className="list">
        <div className="row m-b-sm p-l-md">
          <div className="col-xs-12">
            <span className="page-title">
              {totalItems} {title}
            </span>
            <span className="sub-heading">{subtitle}</span>
            <div className="pageStyle">
              {
                switchDisplayStyle
              && (
                <>
                  <button type="button"
                    onClick={() => preferTable && switchDisplayStyle()}
                    className={preferTable ? 'inactive' : 'active'}
                    title="Grid View">
                    <i className="glyphicon glyphicon-th-large" />
                    <div className="hover-text">Grid</div>
                  </button>
                  <button type="button"
                    onClick={() => !preferTable && switchDisplayStyle()}
                    className={preferTable ? 'active' : 'inactive'}
                    title="Table View">
                    <i className="glyphicon glyphicon-th-list" />
                    <div className="hover-text">Table</div>
                  </button>
                </>
              )
              }

              {filters && switchFilterVisibility
              && (
                <button type="button"
                  onClick={this.handleFilterVisibilityChange}
                  className={(filters && filters.isVisible) ? 'active' : 'inactive'}
                  title="Filter">
                  <i className="glyphicon glyphicon-filter" />
                  <div className="hover-text">Filter</div>
                </button>
              ) }

              {
                requestExport
                && (
                  <button type="button"
                    onClick={() => requestExport()}
                    className="inactive"
                    title="Filter">
                    <i className="glyphicon glyphicon-download-alt" />
                    <div className="hover-text">Export</div>
                  </button>
                )
              }

            </div>

            <div className="sort">
              {tableColumns.filter(column => column.sortable && column.id).map(column => (
                <button type="button"
                  key={`sort${column.id}`}
                  onClick={() => this.handleSortChange(column.id, sortBy === column.id ? !sortDescending : false)}
                  className={`glyphbutton ${sortBy === column.id ? 'active' : ''}`}
                  title="Sort">
                  <i className={`glyphicon glyphicon-sort-by-attributes${sortBy === column.id && sortDescending ? '-alt' : ''}`} />
                  {column.Header}
                </button>
              ))}
            </div>
          </div>
        </div>
        <hr className="line" />

        <div className="row m-n p-l-md">
          <ListFilter filters={filters} handleFilterChange={this.handleFilterChange} />
          <div id="scroller" className="m-b-lg"
            style={{ height: window.innerHeight - 0.25 * window.innerHeight - (filters && filters.isVisible ? 90 : 0) }}>
            <AnimatePresence>
              {preferTable ? (
                <animate.listItem key="list-data-table" custom={{ index: 0, length: 1 }}>
                  <ReactTable data={tableData}
                    columns={tableColumns}
                    manual
                    resizable={false}
                    multiSort={false}
                    defaultSorted={[
                      {
                        id: sortBy,
                        desc: sortDescending,
                      },
                    ]}
                    onSortedChange={sort => this.handleSortChange(sort[0].id, sort[0].desc)}
                    pageSize={pageSize}
                    noDataText="No records"
                    minRows={4}
                    showPagination={false}
                    SubComponent={subComponent}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.original && rowInfo.original.isDisabled != null && rowInfo.original.isDisabled) {
                        return {
                          style: {
                            background: 'rgba(178, 5, 5, 0.3)',
                          },
                        };
                      }
                      return {};
                    }} />
                </animate.listItem>
              ) : (
                children.map((child, index) => (
                  <animate.listItem key={child.key} custom={{ index, length: tableData.length }}
                    className="p-t-sm element-item col-xs-12 col-sm-12 col-md-6 col-lg-4">
                    {child}
                  </animate.listItem>
                ))
              )}
            </AnimatePresence>
          </div>
          {totalPages !== 0 && (
            <div id="pager">
              <ReactPaginate nextLabel="»"
                previousLabel="«"
                forcePage={pageIndex}
                pageCount={totalPages}
                marginPagesDisplayed={5}
                pageRangeDisplayed={1}
                onPageChange={this.handlePageChange}
                containerClassName="pagination"
                activeClassName="active" />
            </div>
          )}
        </div>
        {newLink && (
          <LinkContainer to={newLink} exact
            title="Add">
            <div className="floating-button">
              <i className="glyphicon glyphicon-plus" />
              <div className="hover-text">Add</div>
            </div>
          </LinkContainer>
        )}
      </div>
    );
  }
}

List.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  totalPages: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  totalItems: PropTypes.number,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  requestList: PropTypes.func,
  requestExport: PropTypes.func,
  tableColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  newLink: PropTypes.string,
  preferTable: PropTypes.bool.isRequired,
  switchDisplayStyle: PropTypes.func,
  filters: PropTypes.shape(),
  subComponent: PropTypes.func,
  switchFilterVisibility: PropTypes.func,
};

List.defaultProps = {
  newLink: null,
  requestList: null,
  requestExport: null,
  totalPages: 0,
  pageIndex: 0,
  pageSize: 9,
  totalItems: 0,
  sortBy: 'name',
  sortDescending: false,
  subtitle: null,
  subComponent: null,
  switchFilterVisibility: null,
  filters: null,
  switchDisplayStyle: null,
  children: [],
};

export default connect((state, ownProps) => (
  {
    pageSize: selectors.getSettingAsNumber(state, SettingType.PageSize) || ownProps.pageSize,
  }))(List);

export const ListPropTypes = PropTypes.shape({
  data: PropTypes.arrayOf(PropTypes.shape({})),
  paging: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDescending: PropTypes.bool,
  }).isRequired,
  preferTable: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    isVisible: PropTypes.bool.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      column: PropTypes.string.isRequired,
      type: PropTypes.number.isRequired,
      operator: PropTypes.string.isRequired,
    })),
  }),
});
