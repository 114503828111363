import React from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import Layout from './Layout';
import { selectors } from '../store/modules/User';
import UserList from '../components/user/List';
import UserEdit from '../components/user/Edit';
import UserProfile from '../components/user/Profile';
import TenantList from '../components/tenant/List';
import TenantEdit from '../components/tenant/Edit';
import TenantMaintain from '../components/tenant/Maintain';
import TenantProfile from '../components/tenant/Profile';
import PlayerList from '../components/player/List';
import PlayerEdit from '../components/player/Edit';
import GameList from '../components/game/List';
import GameEdit from '../components/game/Edit';
import RuleList from '../components/rule/List';
import RuleEdit from '../components/rule/Edit';
import FilterList from '../components/filter/List';
import FilterEdit from '../components/filter/Edit';
import RewardList from '../components/reward/List';
import RewardEdit from '../components/reward/Edit';
import ParticipantList from '../components/participant/List';
import ParticipantEdit from '../components/participant/Edit';
import GroupList from '../components/group/List';
import GroupEdit from '../components/group/Edit';
import AttributeList from '../components/attribute/List';
import AttributeEdit from '../components/attribute/Edit';
import LevelList from '../components/level/List';
import LevelEdit from '../components/level/Edit';
import ActionTypeList from '../components/actionType/List';
import ActionTypeEdit from '../components/actionType/Edit';
import ActionList from '../components/action/List';
import ActionEdit from '../components/action/Edit';
import LogList from '../components/log/List';
import TransactionList from '../components/transaction/List';
import NotificationList from '../components/notification/List';
import NotificationEdit from '../components/notification/Edit';
import NotificationTypeList from '../components/notificationType/List';
import NotificationTypeEdit from '../components/notificationType/Edit';
import Dashboard from '../components/dashboard/Dashboard';
import Login from '../components/user/Login';
import Background from './Background';
import Alert from './Alert';
import animate from '../utils/animate';
import Search from './Search';

const App = props => {
  const { getIsUserLoaded, location } = props;

  return (
    <>
      {getIsUserLoaded ? (
        <>
          <Layout>
            <AnimatePresence>
              <animate.router key={`route-container-${location.pathname}`}>
                <Switch location={location} key={location.key}>
                  <Route exact path="/"
                    component={Dashboard} />
                  <Route exact path="/user"
                    component={UserList} />
                  <Route exact path="/user/:id"
                    component={UserEdit} />
                  <Route exact path="/profile"
                    component={UserProfile} />
                  <Route exact path="/tenant"
                    component={TenantList} />
                  <Route exact path="/tenant/profile"
                    component={TenantProfile} />
                  <Route exact path="/tenant/:id"
                    component={TenantEdit} />
                  <Route exact path="/tenant/:id/maintain"
                    component={TenantMaintain} />
                  <Route exact path="/player"
                    component={PlayerList} />
                  <Route exact path="/player/:id"
                    component={PlayerEdit} />
                  <Route exact path="/game/game"
                    component={GameList} />
                  <Route exact path="/game/game/:id"
                    component={GameEdit} />
                  <Route exact path="/game/game/:id/copy"
                    component={GameEdit} />
                  <Route exact path="/game/leaderboard"
                    component={GameList} />
                  <Route exact path="/game/leaderboard/:id"
                    component={GameEdit} />
                  <Route exact path="/game/leaderboard/:id/copy"
                    component={GameEdit} />
                  <Route exact path="/rule/game"
                    component={RuleList} />
                  <Route exact path="/rule/game/:id"
                    component={RuleEdit} />
                  <Route exact path="/rule/game/:id/copy"
                    component={RuleEdit} />
                  <Route exact path="/rule/leaderboard"
                    component={RuleList} />
                  <Route exact path="/rule/leaderboard/:id"
                    component={RuleEdit} />
                  <Route exact path="/rule/leaderboard/:id/copy"
                    component={RuleEdit} />
                  <Route exact path="/filter"
                    component={FilterList} />
                  <Route exact path="/filter/:id"
                    component={FilterEdit} />
                  <Route exact path="/filter/:id/copy"
                    component={FilterEdit} />
                  <Route exact path="/reward"
                    component={RewardList} />
                  <Route exact path="/reward/:id"
                    component={RewardEdit} />
                  <Route exact path="/reward/:id/copy"
                    component={RewardEdit} />
                  <Route exact path="/participant"
                    component={ParticipantList} />
                  <Route exact path="/participant/:id"
                    component={ParticipantEdit} />
                  <Route exact path="/participant/:id/copy"
                    component={ParticipantEdit} />
                  <Route exact path="/group"
                    component={GroupList} />
                  <Route exact path="/group/:id"
                    component={GroupEdit} />
                  <Route exact path="/attribute/player"
                    component={AttributeList} />
                  <Route exact path="/attribute/player/:id"
                    component={AttributeEdit} />
                  <Route exact path="/attribute/game"
                    component={AttributeList} />
                  <Route exact path="/attribute/game/:id"
                    component={AttributeEdit} />
                  <Route exact path="/level"
                    component={LevelList} />
                  <Route exact path="/level/:id"
                    component={LevelEdit} />
                  <Route exact path="/actionType"
                    component={ActionTypeList} />
                  <Route exact path="/actionType/:id"
                    component={ActionTypeEdit} />
                  <Route exact path="/action"
                    component={ActionList} />
                  <Route exact path="/action/:id"
                    component={ActionEdit} />
                  <Route exact path="/log"
                    component={LogList} />
                  <Route exact path="/transaction"
                    component={TransactionList} />
                  <Route exact path="/notification"
                    component={NotificationList} />
                  <Route exact path="/notification/:id"
                    component={NotificationEdit} />
                  <Route exact path="/notificationType"
                    component={NotificationTypeList} />
                  <Route exact path="/notificationType/:id"
                    component={NotificationTypeEdit} />
                </Switch>
              </animate.router>
            </AnimatePresence>
          </Layout>
          <Search />
        </>
      ) : (
        <Login />
      )}
      <Background />
      <Alert />
    </>
  );
};

App.propTypes = {
  getIsUserLoaded: PropTypes.bool.isRequired,
  location: PropTypes.shape({ key: PropTypes.string, pathname: PropTypes.string }).isRequired,
};

export default connect(state => ({
  getIsUserLoaded: selectors.getIsUserLoaded(state),
  location: state.router.location,
}))(App);
