const helper = {
  constants: {
    fake: 'asd',
  },
  formControlClassName: (id, errors, touched) => {
    if (errors[id]) {
      if (touched[id]) {
        return 'form-control error';
      }
      return 'form-control warning';
    }
    return 'form-control';
  },
  simulationStatusText: simulation => {
    if (simulation && simulation.completed) {
      return 'completed';
    }
    if (simulation && simulation.violated) {
      return 'violated';
    }
    if (simulation && simulation.started) {
      return 'started';
    }
    return '';
  },
  getIdFromProps: props => {
    let { id } = props;
    if (!id) {
      id = !props.match || !props.match.params || props.match.params.id === 'new' ? null : Number(props.match.params.id);
    }
    return id;
  },
  isPlayerAttributeMode: props => props.match && props.match.path && props.match.path.indexOf('player') > 0,
  getNewAttributeLink: props => (props.match && props.match.path && props.match.path.indexOf('player') > 0 ? '/attribute/player' : '/attribute/game'),
  attributeResolver: (props, player, game) => (props.match && props.match.path && props.match.path.indexOf('player') > 0 ? player : game),
  getNewRuleLink: props => (props.match && props.match.path && props.match.path.indexOf('leaderboard') > 0 ? '/rule/leaderboard' : '/rule/game'),
  ruleResolver: (props, rule, ranking) => (props.match && props.match.path && props.match.path.indexOf('leaderboard') > 0 ? ranking : rule),
  getNewGameLink: props => (props.match && props.match.path && props.match.path.indexOf('leaderboard') > 0 ? '/game/leaderboard' : '/game/game'),
  gameResolver: (props, game, leaderboard) => (props.match && props.match.path && props.match.path.indexOf('leaderboard') > 0 ? leaderboard : game),
  getCopyFromProps: props => {
    let { copy } = props;
    if (!copy) {
      copy = props.match && props.match.path.indexOf('copy') > -1;
    }
    return copy;
  },
  getEntityById: (array, props) => {
    const copy = helper.getCopyFromProps(props);
    const entity = array[helper.getIdFromProps(props)];
    if (entity && copy) return {...entity, name: `Copy of ${entity.name}`};
    return entity;
  },
};

export default helper;
