import React from 'react';
import PropTypes from 'prop-types';

const DashboardTile = props => {
  const {
    onClose, children, title, subtitle,
  } = props;

  return (
    <div className="dashboard-tile">
      <div className="tile-title">{title}</div>
      <div className="tile-subtitle">{subtitle}</div>
      <div className="tile-content">{children}</div>
      <button type="button" className="hide-button"
        onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

DashboardTile.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default DashboardTile;
