import { DataType } from '../../utils/enum';
import Base from './Base';

const base = new Base('action', 'Action');

const initialState = {
  list: {
    data: [],
    paging: { sortBy: 'createdOn', sortDescending: true },
    preferTable: false,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Action Type',
        column: 'ActionTypeId',
        type: DataType.Number,
        operator: '=',
        value: null,
        dataSourceKey: 'actionType',
      },
      {
        name: 'Player',
        column: 'Player.Name',
        type: DataType.Text,
        operator: 'Contains',
        value: null,
      }],
    },
  },
  details: {},
};

export const actionCreators = {
  ...base.actionCreators,
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  return base.reducer(newState, action);
};
