import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Formik, Form, Field,
} from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { actionCreators } from '../../store/modules/NotificationType';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import DeleteButton from '../../controls/DeleteButton';
import SelectField from '../../controls/SelectField';
import {
  ConvertToOptions, NotificationType, NotificationHours, GameElement,
} from '../../utils/enum';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import helper from '../../utils/helper';
import Image, { GetImageSrc } from '../../controls/Image';
import GameElementSelector from '../game/ElementSelector';

class NotificationTypeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {
        type: 4,
        name: '',
        title: '',
        description: '',
        maxOccurrences: 1,
        period: '',
        hourFrom: '',
        hourTo: '',
        imageId: '',
        videoId: '',
        isDisabled: false,
        participants: [],
      },
      participantSelectionIsOpen: false,
      activeParticipantId: null,
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { id, requestGet } = this.props;

    if (id) {
      requestGet(id);
    }
  }

  handleSave(values) {
    const { id, requestPost, requestPut } = this.props;

    if (id) {
      requestPut(id, values);
    } else {
      requestPost(values);
    }
  }

  handleStatus(enabled) {
    const { id, requestEnable, requestDisable } = this.props;

    if (enabled) {
      requestEnable(id);
    } else {
      requestDisable(id);
    }
  }

  handleAddProfile = (array, element) => {
    array.push({ participant: element });
    this.setState({ participantSelectionIsOpen: false, activeParticipantId: element.id });
  }

  handleRemoveProfile = (array, element) => {
    const { activeParticipantId } = this.state;

    array.splice(array.indexOf(element), 1);

    if (activeParticipantId === element.participant.id && array.length > 0) {
      this.setState({ activeParticipantId: array[0].participant.id });
    } else {
      this.setState({ activeParticipantId: null });
    }
  }

  getElementName = (participants, property) => {
    const { activeParticipantId } = this.state;

    if (activeParticipantId) {
      const element = participants.find(x => x.participant.id === activeParticipantId);
      const index = participants.indexOf(element);
      return `participants.${index}.${property}`;
    }
    return property;
  }

  hasElementError = (participants, errors, property) => {
    const { activeParticipantId } = this.state;

    if (activeParticipantId) {
      const element = participants.find(x => x.participant.id === activeParticipantId);
      const index = participants.indexOf(element);
      return !!errors.participants && !!errors.participants[index][property];
    }
    return !!errors[property];
  }

  getElementValue = (values, property) => {
    const { activeParticipantId } = this.state;

    if (activeParticipantId) {
      const element = values.participants.find(x => x.participant.id === activeParticipantId);
      const index = values.participants.indexOf(element);
      return values.participants[index][property];
    }
    return values[property];
  }

  handleFileChanged = (values, property, value) => {
    const { activeParticipantId } = this.state;

    if (activeParticipantId) {
      const element = values.participants.find(x => x.participant.id === activeParticipantId);
      const index = values.participants.indexOf(element);
      values.participants[index][property] = value;
    } else {
      values[property] = value;
    }
    this.forceUpdate();
  }

  testTimingTo(hourTo) {
    return (!this.parent.hourFrom && !hourTo) || (this.parent.hourFrom && hourTo && this.parent.hourFrom !== hourTo);
  }

  render() {
    const { id, notificationType, requestDelete } = this.props;
    const { template, participantSelectionIsOpen, activeParticipantId } = this.state;

    const maxOccurrencesTimes = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
    ];

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          type: Yup.number().required(),
          name: Yup.string()
            .max(50)
            .required(),
          title: Yup.string()
            .max(50)
            .required(),
          description: Yup.string().required(),
          hourFrom: Yup.number().nullable(),
          hourTo: Yup.number().nullable().test('hourTo', 'Timing To must be set if Timing From set', this.testTimingTo),
          maxOccurrences: Yup.number().min(1).required(),
          imageId: Yup.string().nullable(),
          videoId: Yup.string().nullable(),
          participants: Yup.array().of(
            Yup.object().shape({
              participant: Yup.object().shape({
                id: Yup.number()
                  .integer()
                  .required(),
                name: Yup.string().nullable(),
              }),
              title: Yup.string()
                .max(50)
                .required(),
              description: Yup.string().required(),
              imageId: Yup.string().nullable(),
              videoId: Yup.string().nullable(),
            }),
          ).required(),
        })}
        initialValues={notificationType || template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">{id ? 'Edit Notification Type' : 'New Notification Type'}</span>
                  <span className="sub-heading hex-img">
                    Notification Type is a definition of player notification that can be generated or imported into GZone
                  </span>
                  <div className="pageStyle">
                    <BackButton />
                    {id && <DeleteButton onClick={() => requestDelete(id)} />}
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[this.getElementValue(values, 'imageId')]} videoIds={[this.getElementValue(values, 'videoId')]}
                        onImageChange={imageId => this.handleFileChanged(values, 'imageId', imageId)}
                        onVideoChange={videoId => this.handleFileChanged(values, 'videoId', videoId)}
                        single allowVideo />
                    </div>
                    <div className="panel-heading" />
                    <div className="profile-selector" style={{ marginLeft: '90px' }}>
                      {
                        values.participants.length > 0
                            && (
                              <div className={activeParticipantId ? '' : 'active'}>
                                <button type="button" title="Player Profile"
                                  className="btn-profile"
                                  onClick={() => this.setState({ activeParticipantId: null })}>
                                  Default
                                </button>
                              </div>
                            )
                      }
                      { values.participants.map(p => (
                        <React.Fragment key={p.participant.id}>
                          <i className="glyphicon glyphicon-arrow-left" />
                          <div className={p.participant.id === activeParticipantId ? 'active' : ''}>
                            <button type="button" title="Player Profile"
                              className="btn-profile"
                              onClick={() => this.setState({ activeParticipantId: p.participant.id })}>
                              <Image src={GetImageSrc(p.participant.imageId, 40)} alt="participant" />
                              {p.participant.name}
                            </button>
                            <button type="button" className="btn-profile-remove"
                              title="Remove Player Profile" onClick={() => this.handleRemoveProfile(values.participants, p)}>
                              <i className="glyphicon glyphicon-remove" />
                            </button>
                          </div>
                        </React.Fragment>
                      ))}
                      <div>
                        <button type="button" className="btn-profile"
                          title="Add Player Profile" onClick={() => this.setState({ participantSelectionIsOpen: true })}>
                          <i className="glyphicon glyphicon-plus" />
                        </button>
                      </div>
                    </div>
                    <GameElementSelector isOpen={participantSelectionIsOpen}
                      mode={GameElement.Participants}
                      existing={values.participants.map(p => p.participant)}
                      multiple={false}
                      onClose={() => this.setState({ participantSelectionIsOpen: false })}
                      onSelect={participant => this.handleAddProfile(values.participants, participant)} />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        {
                          !activeParticipantId
                          && (
                            <div className="row">
                              <div className="col-xs-12">
                                <div className="form-group form-group-lg">
                                  <label htmlFor="name" className="control-label font-bold">
                                    Name
                                    <Field id="name"
                                      name="name"
                                      autoFocus
                                      className={errors.name ? 'form-control error' : 'form-control'} />
                                  </label>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor={this.getElementName(values.participants, 'title')} className="control-label font-bold">
                                Title
                                <Field id={this.getElementName(values.participants, 'title')}
                                  name={this.getElementName(values.participants, 'title')}
                                  value={this.getElementValue(values, 'title') || ''}
                                  className={this.hasElementError(values.participants, errors, 'title') ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor={this.getElementName(values.participants, 'description')} className="control-label font-bold">
                                Body
                                <Field id={this.getElementName(values.participants, 'description')}
                                  type="textarea"
                                  name={this.getElementName(values.participants, 'description')}
                                  value={this.getElementValue(values, 'description') || ''}
                                  component="textarea"
                                  rows="4"
                                  className={this.hasElementError(values.participants, errors, 'description') ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        {
                          !activeParticipantId
                          && (
                            <div className="row">
                              <div className="col-md-4 col-xs-12">
                                <div className="form-group form-group-lg">
                                  <label htmlFor="hourFrom" className="control-label font-bold">
                                    Timing From
                                    <Field id="hourFrom"
                                      name="hourFrom"
                                      component={SelectField}
                                      options={ConvertToOptions(NotificationHours)}
                                      className={errors.hourFrom ? 'form-control error' : 'form-control'} />
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-4 col-xs-12">
                                <div className="form-group form-group-lg">
                                  <label htmlFor="hourTo" className="control-label font-bold">
                                    Timing To
                                    <Field id="hourTo"
                                      name="hourTo"
                                      component={SelectField}
                                      options={ConvertToOptions(NotificationHours)}
                                      className={errors.hourTo ? 'form-control error' : 'form-control'} />
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-4 col-xs-12">
                                <div className="form-group form-group-lg">
                                  <label htmlFor="maxOccurrences" className="control-label font-bold">
                                    Max Occurrences
                                    <Field id="maxOccurrences"
                                      name="maxOccurrences"
                                      component={SelectField}
                                      options={maxOccurrencesTimes}
                                      onChange={(form, newValue) => {
                                        if (newValue === 1) { form.setFieldValue('period', ''); }
                                      }}
                                      className={errors.maxOccurrences ? 'form-control error' : 'form-control'} />
                                  </label>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                      {id
                        && notificationType && (
                        <div className="col-md-3 form-additional-info">
                          <label>
                            Created
                            <div>{date.long(notificationType.createdOn)}</div>
                          </label>
                          <label>
                            Type
                            <div>{NotificationType[notificationType.type]}</div>
                          </label>
                          <label htmlFor="status-switch">
                            Disabled
                            <div>
                              <Switch id="status-switch"
                                onChange={() => this.handleStatus(notificationType.isDisabled)}
                                checked={notificationType.isDisabled}
                                checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

NotificationTypeEdit.propTypes = {
  id: PropTypes.number,
  notificationType: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isDisabled: PropTypes.bool,
    type: PropTypes.number,
    createdOn: PropTypes.string,
  }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
};

NotificationTypeEdit.defaultProps = {
  id: null,
  notificationType: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    notificationType: state.notificationType.details[helper.getIdFromProps(ownProps)],
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(NotificationTypeEdit);
