import React from 'react';
import PropTypes from 'prop-types';

const toDatetimeLocal = date => {
  const ten = i => (i < 10 ? '0' : '') + i;
  const YYYY = date.getFullYear();
  const MM = ten(date.getMonth() + 1);
  const DD = ten(date.getDate());
  return `${YYYY}-${MM}-${DD}`;
};

const DateField = ({
  className, field, form, minDate, maxDate, readOnly, onChange,
}) => {
  const { value } = field;
  let selected = '';

  if (value) {
    selected = toDatetimeLocal(new Date(value));
  }

  return (
    <input type="date"
      name={field.name}
      value={selected}
      readOnly={readOnly}
      onChange={e => {
        if (e.target.value && Number.isInteger(Date.parse(e.target.value))) {
          const selectedDate = new Date(`${e.target.value} 00:00:00`).toISOString();
          form.setFieldValue(field.name, selectedDate);
          if (onChange) onChange(form, selectedDate);
        } else {
          form.setFieldValue(field.name, null);
          if (onChange) onChange(form, null);
        }
      }}
      className={className}
      min={minDate}
      max={maxDate}
      onBlur={field.onBlur} />
  );
};

DateField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  maxDate: PropTypes.shape(),
  minDate: PropTypes.shape(),
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

DateField.defaultProps = {
  maxDate: undefined,
  minDate: undefined,
  className: null,
  readOnly: false,
  onChange: null,
};

export default DateField;
