import { push, replace } from 'connected-react-router';
import { actionCreators as Alert } from './Alert';
import restHttp from '../../utils/restHttp';
import { DataType } from '../../utils/enum';
import Base from './Base';

const base = new Base('game/leaderboard', 'Leaderboard');

const initialState = {
  list: {
    data: [],
    paging: {},
    preferTable: false,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Disabled',
        column: 'IsDisabled',
        type: DataType.Boolean,
        operator: '=',
        value: null,
      }, {
        name: 'Reward',
        column: 'GameRewards.RewardId',
        type: DataType.Number,
        operator: 'Any',
        value: null,
        dataSourceKey: 'reward',
      }, {
        name: 'Published',
        column: 'PublishedOn',
        type: DataType.Boolean,
        operator: 'NotNull',
        value: null,
      },
      ],
    },
  },
  roundList: {
    data: [],
    paging: { sortBy: 'createdOn', sortDescending: true },
    preferTable: true,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Player',
        column: 'Player.Name',
        type: DataType.Text,
        operator: 'Contains',
        value: null,
      }, {
        name: 'Completed',
        column: 'CompletedOn',
        type: DataType.Boolean,
        operator: 'NotNull',
        value: null,
      },{
        name: 'Started',
        column: 'StartedOn',
        type: DataType.DateOnly,
        operator: 'DayIs',
        value: null,
      },],
    },
  },
  all: [],
  details: {},
};

export const actionCreators = {
  ...base.actionCreators,
  requestPost: (entity, publish) => dispatch => {
    dispatch({ type: base.requestPostType });

    restHttp
      .post(`${base.name}?publish=${publish}`, entity)
      .then(response => {
        dispatch({
          type: base.receivePostType,
          current: response.data,
        });
        dispatch(Alert.addSuccess('Leaderboard created!'));
        dispatch(replace('/game/leaderboard'));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestPut: (id, entity, publish) => dispatch => {
    dispatch({ type: base.requestPutType });

    restHttp
      .put(`${base.name}/${id}?publish=${publish}`, entity)
      .then(response => {
        dispatch({
          type: base.receivePutType,
          current: response.data,
        });
        dispatch(Alert.addSuccess('Leaderboard updated!'));
        dispatch(push('/game/leaderboard'));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestRoundList: (id, pageIndex, pageSize, sortBy, sortDescending, filters) => dispatch => {
    dispatch({ type: base.customRequestType('round/list') });

    restHttp
      .get(`${base.name}/${id}/round`, restHttp.pageOptions(pageIndex, pageSize, sortBy, sortDescending, filters && filters.isVisible ? filters.fields : []))
      .then(response => {
        dispatch({
          type: base.customReceiveType('round/list'),
          data: response.data,
          paging: restHttp.pagingHeader(response),
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  switchRoundDisplayStyle: () => dispatch => {
    dispatch({ type: base.customType('round/display/switch') });
  },
  switchRoundFilterVisibility: () => dispatch => {
    dispatch({ type: base.customType('round/filter/switch') });
  },
  requestUnpublish: id => dispatch => {
    dispatch({ type: base.requestPutType });

    restHttp
      .put(`${base.name}/${id}/unpublish`)
      .then(response => {
        dispatch({
          type: base.receivePutType,
          current: response.data,
        });
        dispatch(Alert.addSuccess(`${base.friendlyName} unpublished!`));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  hideChildFilters: () => dispatch => {
    dispatch({ type: base.customType('child/filter/hide') });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (
    action.type === base.receiveGetType
    || action.type === base.receivePostType
    || action.type === base.receivePutType
    || action.type === base.receiveEnableType
    || action.type === base.receiveDisableType
  ) {
    return {
      ...newState,
      details: {
        ...newState.details,
        [action.current.id]: action.current,
      },
      simulation: null,
    };
  }

  if (action.type === base.customReceiveType('round/list')) {
    return {
      ...newState,
      roundList: {
        ...newState.roundList,
        data: action.data,
        paging: action.paging,
        filters: {
          ...newState.roundList.filters,
        },
      },
    };
  }

  if (action.type === base.customType('round/display/switch')) {
    return {
      ...newState,
      roundList: {
        ...newState.roundList,
        preferTable: !newState.roundList.preferTable,
      },
    };
  }

  if (action.type === base.customType('round/filter/switch')) {
    return {
      ...newState,
      roundList: {
        ...newState.roundList,
        filters: {
          ...newState.roundList.filters,
          isVisible: !newState.roundList.filters.isVisible,
        },
      },
    };
  }

  if (action.type === base.customType('child/filter/hide')) {
    return {
      ...newState,
      roundList: {
        ...initialState.roundList,
      },
    };
  }

  return base.reducer(newState, action);
};
