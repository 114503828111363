import Base from './Base';

const base = new Base('log', 'Log');

const initialState = {
  list: {
    data: [],
    paging: { sortBy: 'createdOn', sortDescending: true },
    preferTable: true,
  },
};

export const actionCreators = {
  ...base.actionCreators,
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  return base.reducer(newState, action);
};
