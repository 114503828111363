import { push, replace } from 'connected-react-router';
import { actionCreators as Alert } from './Alert';
import { actionCreators as Setting } from './Setting';
import restHttp from '../../utils/restHttp';
import store from '../../index';
import { DataType } from '../../utils/enum';
import Base from './Base';

const base = new Base('user', 'User');

export const requestLogoutType = base.customRequestType('logout');
export const selectTenant = base.customType('tenant/select');

const initialState = {
  auth: null,
  list: {
    data: [],
    paging: {},
    preferTable: false,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Disabled',
        column: 'IsDisabled',
        type: DataType.Boolean,
        operator: '=',
        value: null,
      },
      ],
    },
  },
  tenantId: null,
  details: {},
};

export const actionCreators = {
  ...base.actionCreators,
  requestLogin: (username, password) => dispatch => {
    dispatch({ type: base.customRequestType('login') });

    restHttp
      .post('connect/token/', `grant_type=password&username=${username}&password=${password}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then(response => {
        dispatch({ type: base.customReceiveType('login'), auth: response.data });
        dispatch(Setting.requestUser());
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestLogout: () => async dispatch => {
    dispatch({ type: base.customRequestType('logout') });
  },
  requestGet: id => dispatch => {
    dispatch({ type: base.requestGetType });

    restHttp
      .get(id ? `user/${id}` : 'user/profile')
      .then(response => {
        dispatch({
          type: base.receiveGetType,
          current: response.data,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestPut: (id, entity) => dispatch => {
    dispatch({ type: base.requestPutType });

    restHttp
      .put(id ? `user/${id}` : 'user/profile', entity)
      .then(response => {
        dispatch({
          type: base.receivePutType,
          current: response.data,
        });
        dispatch(Alert.addSuccess('User updated!'));
        if (id) dispatch(push('/user'));
        else dispatch(Setting.requestUser());
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  selectTenant: (id, redirect) => dispatch => {
    dispatch({
      type: base.customType('tenant/select'),
      tenantId: id,
    });
    dispatch(Setting.requestUser());
    // if switch from/to admin navigate to home, otherwise nav to same section but without params
    if (redirect) {
      dispatch(push('/'));
    } else {
      const path = store.getState().router.location.pathname;
      const split = path.split('/').filter(str => !!str && !parseInt(str));

      if (split.length > 0) {
        dispatch(replace(`/${split.join('/')}`));
      } else {
        dispatch(replace());
      }
    }
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (action.type === base.customRequestType('login') || action.type === base.customRequestType('logout')) {
    return {
      ...newState,
      auth: null,
    };
  }

  if (action.type === base.customReceiveType('login')) {
    return {
      ...newState,
      auth: action.auth,
    };
  }

  if (action.type === base.customType('tenant/select')) {
    return {
      ...newState,
      tenantId: action.tenantId,
    };
  }

  if (
    action.type === base.receiveGetType
    || action.type === base.receivePostType
    || action.type === base.receivePutType
    || action.type === base.receiveEnableType
    || action.type === base.receiveDisableType
  ) {
    return {
      ...newState,
      details: {
        ...newState.details,
        [action.current.id]: action.current,
      },
      auth:
        action.current.id === Number(newState.auth.id) && action.type === base.receivePutType
          ? {
            ...newState.auth,
            name: action.current.name,
            username: action.current.username,
            imageId: action.current.imageId,
          }
          : newState.auth,
    };
  }

  return base.reducer(newState, action);
};

export const selectors = {
  getIsAdmin: state => state.user.auth && !state.user.auth.tenantId,
  getIsAuthenticated: state => state.user.auth !== null,
  getIsUserLoaded: state => state.user.auth !== null && !!state.setting.user[1],
  getCurrentTenantId: state => {
    // if overriden
    if (state.user.auth && state.user.tenantId) return Number(state.user.tenantId);
    if (state.user.auth && state.user.auth.tenantId) return Number(state.user.auth.tenantId);
    return null;
  },
};
