import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goBack } from 'connected-react-router';

const BackButton = props => {
  const { navigateBack, onClick } = props;

  return (
    <button type="button" className="back-button"
      onClick={() => (onClick ? onClick() : navigateBack())}>
      <i className="glyphicon glyphicon-arrow-left" />
      <div className="hover-text">Back</div>
    </button>
  );
};

BackButton.propTypes = {
  navigateBack: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  onClick: null,
};

export default connect(
  null,
  dispatch => bindActionCreators(
    {
      navigateBack: goBack,
    },
    dispatch,
  ),
)(BackButton);
