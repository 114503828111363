import { push, replace } from 'connected-react-router';
import { actionCreators as Alert } from './Alert';
import restHttp from '../../utils/restHttp';

export default class Base {
  constructor(name, friendlyName) {
    this.name = name;
    this.friendlyName = friendlyName;
  }

  get requestListType() {
    return `gzone/${this.name}/list/request`;
  }

  get receiveListType() {
    return `gzone/${this.name}/list/receive`;
  }

  get requestAllType() {
    return `gzone/${this.name}/all/request`;
  }

  get receiveAllType() {
    return `gzone/${this.name}/all/receive`;
  }

  get requestGetType() {
    return `gzone/${this.name}/get/request`;
  }

  get receiveGetType() {
    return `gzone/${this.name}/get/receive`;
  }

  get requestPostType() {
    return `gzone/${this.name}/post/request`;
  }

  get receivePostType() {
    return `gzone/${this.name}/post/receive`;
  }

  get requestPutType() {
    return `gzone/${this.name}/put/request`;
  }

  get receivePutType() {
    return `gzone/${this.name}/put/receive`;
  }

  get requestEnableType() {
    return `gzone/${this.name}/enable/request`;
  }

  get receiveEnableType() {
    return `gzone/${this.name}/enable/receive`;
  }

  get requestDisableType() {
    return `gzone/${this.name}/disable/request`;
  }

  get receiveDisableType() {
    return `gzone/${this.name}/disable/receive`;
  }

  get requestDeleteType() {
    return `gzone/${this.name}/delete/request`;
  }

  get receiveDeleteType() {
    return `gzone/${this.name}/delete/receive`;
  }

  get switchDisplayStyleType() {
    return `gzone/${this.name}/display/switch`;
  }

  get switchFilterVisibilityType() {
    return `gzone/${this.name}/filter/switch`;
  }

  customType = name => `gzone/${this.name}/custom/${name}`

  customRequestType = name => `gzone/${this.name}/custom/${name}/request`

  customReceiveType = name => `gzone/${this.name}/custom/${name}/response`

  get actionCreators() {
    return {
      requestList: (pageIndex, pageSize, sortBy, sortDescending, filters) => dispatch => {
        dispatch({ type: this.requestListType });

        restHttp
          .get(this.name, restHttp.pageOptions(pageIndex, pageSize, sortBy, sortDescending, filters && filters.isVisible ? filters.fields : []))
          .then(response => {
            dispatch({
              type: this.receiveListType,
              data: response.data,
              paging: restHttp.pagingHeader(response),
            });
          })
          .catch(error => {
            dispatch(Alert.addError(error));
          });
      },
      requestAll: () => dispatch => {
        dispatch({ type: this.requestAllType });

        restHttp
          .get(`${this.name}/all`)
          .then(response => {
            dispatch({
              type: this.receiveAllType,
              all: response.data,
            });
          })
          .catch(error => {
            dispatch(Alert.addError(error));
          });
      },
      requestGet: id => dispatch => {
        dispatch({ type: this.requestGetType });

        restHttp
          .get(`${this.name}/${id}`)
          .then(response => {
            dispatch({
              type: this.receiveGetType,
              current: response.data,
            });
          })
          .catch(error => {
            dispatch(Alert.addError(error));
          });
      },
      requestPost: (entity, redirect) => dispatch => {
        dispatch({ type: this.requestPostType });

        restHttp
          .post(this.name, entity)
          .then(response => {
            dispatch({
              type: this.receivePostType,
              current: response.data,
            });
            dispatch(Alert.addSuccess(`${this.friendlyName} created!`));
            if (redirect === undefined || redirect === true) { dispatch(replace(`/${this.name}`)); }
          })
          .catch(error => {
            dispatch(Alert.addError(error));
          });
      },
      requestPut: (id, entity, redirect) => dispatch => {
        dispatch({ type: this.requestPutType });

        restHttp
          .put(`${this.name}/${id}`, entity)
          .then(response => {
            dispatch({
              type: this.receivePutType,
              current: response.data,
            });
            dispatch(Alert.addSuccess(`${this.friendlyName} updated!`));
            if (redirect === undefined || redirect === true) dispatch(push(`/${this.name}`));
          })
          .catch(error => {
            dispatch(Alert.addError(error));
          });
      },
      requestDisable: id => dispatch => {
        dispatch({ type: this.requestDisableType });

        restHttp
          .put(`${this.name}/${id}/disable`)
          .then(response => {
            dispatch({
              type: this.receiveDisableType,
              current: response.data,
            });
            dispatch(Alert.addSuccess(`${this.friendlyName} disabled!`));
          })
          .catch(error => {
            dispatch(Alert.addError(error));
          });
      },
      requestEnable: id => dispatch => {
        dispatch({ type: this.requestEnableType });

        restHttp
          .put(`${this.name}/${id}/enable`)
          .then(response => {
            dispatch({
              type: this.receiveEnableType,
              current: response.data,
            });
            dispatch(Alert.addSuccess(`${this.friendlyName} enabled!`));
          })
          .catch(error => {
            dispatch(Alert.addError(error));
          });
      },
      requestDelete: id => dispatch => {
        dispatch({ type: this.requestDeleteType });

        restHttp
          .delete(`${this.name}/${id}`)
          .then(() => {
            dispatch({
              type: this.receiveDeleteType,
            });
            dispatch(Alert.addSuccess(`${this.friendlyName} deleted!`));
            dispatch(push(`/${this.name}`));
          })
          .catch(error => {
            dispatch(Alert.addError(error));
          });
      },
      switchDisplayStyle: () => dispatch => {
        dispatch({ type: this.switchDisplayStyleType });
      },
      switchFilterVisibility: () => dispatch => {
        dispatch({ type: this.switchFilterVisibilityType });
      },
    };
  }

  reducer = (state, action) => {
    if (action.type === this.receiveListType) {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.data,
          paging: action.paging,
          filters: {
            ...state.list.filters,
          },
        },
      };
    }

    if (action.type === this.receiveAllType) {
      return {
        ...state,
        all: action.all,
      };
    }

    if (
      action.type === this.receiveGetType
        || action.type === this.receivePostType
        || action.type === this.receivePutType
        || action.type === this.receiveEnableType
        || action.type === this.receiveDisableType
    ) {
      return {
        ...state,
        details: {
          ...state.details,
          [action.current.id]: action.current,
        },
        // exception when game editor has 3 child editors
        currentInGame: ['rule/game', 'reward', 'participant', 'rule/leaderboard'].indexOf(this.name) > -1
          && [this.receivePostType, this.receivePutType].indexOf(action.type) > -1 ? action.current : null,
      };
    }

    if (action.type === this.switchDisplayStyleType) {
      return {
        ...state,
        list: {
          ...state.list,
          preferTable: !state.list.preferTable,
        },
      };
    }

    if (action.type === this.switchFilterVisibilityType) {
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...state.list.filters,
            isVisible: !state.list.filters.isVisible,
          },
        },
      };
    }

    return state;
  }

  overwriteFilters = (oldFilters, newFilters) => newFilters

  dataURItoBlob = (dataURI) => {
		// convert base64/URLEncoded data component to raw binary data held in a string
		let byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);
	
		// separate out the mime component
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	
		// write the bytes of the string to a typed array
		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
	
		return new Blob([ia], {type:mimeString});
	};
}
