const defaultOptions = {
  shortFormat: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  longFormat: {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
};

function convert(value, format) {
  return new Date(value).toLocaleDateString('en-US', format);
}

const date = {
  short: (value, format = {}) => convert(value, { ...defaultOptions.shortFormat, ...format }),
  long: (value, format = {}) => convert(value, { ...defaultOptions.longFormat, ...format }),
};

export default date;
