import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import Popup from 'reactjs-popup';
import { GameElement, Conjunction } from '../../utils/enum';
import Image, { GetImageSrc } from '../../controls/Image';
import GameElementSelector from './ElementSelector';
import GameUnionActions from './UnionActions';
import ConjunctionIndicator from '../../controls/ConjunctionIndicator';
import helper from '../../utils/helper';

class GameRuleUnion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleSelectionIsOpen: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.union && nextProps.union.isGameMode === undefined) {
      nextProps.union.isGameMode = nextProps.union.games.length > 0;
    }
  }

  render() {
    const {
      union, onChange, onUnionRemove, advancedMode, canEdit, onAdd, onClick, onCopy, onNotificationClick, simulation, notifications,
    } = this.props;
    const { ruleSelectionIsOpen } = this.state;

    const addRule = rule => {
      union.rules.push({ ...rule, unionEntityId: new Date().getMilliseconds() });
      onChange();
      this.setState({ ruleSelectionIsOpen: false });
    };

    const addGame = game => {
      union.games.push({ ...game, unionEntityId: new Date().getMilliseconds() });
      onChange();
      this.setState({ ruleSelectionIsOpen: false });
    };

    const removeFromGame = (array, element) => {
      const index = array.indexOf(element);
      if (index !== -1) {
        array.splice(index, 1);
        onChange();
      }
    };

    const onRuleAddClick = conjunction => {
      union.childrenConjunction = conjunction;
      this.setState({ ruleSelectionIsOpen: true });
    };

    const onModeSwitch = isGameMode => {
      if (union.isGameMode !== isGameMode) {
        union.isGameMode = isGameMode;
        if (isGameMode) {
          union.rules = [];
        } else {
          union.games = [];
        }
        onChange();
      }
    };

    const data = union.isGameMode ? union.games : union.rules;

    const anyUnionNotifications = data.find(x => notifications.find(o => o.conditions.find(c => c.unionRuleId === x.unionEntityId)));

    return (
      <div className={advancedMode ? 'union-advanced-mode' : 'union-simple-mode'}>
        <div className={advancedMode ? 'row' : ''}>
          <div className={advancedMode ? 'rule-group-rules' : ''}>
            <div className="union-title gameplay-switch">
              <button type="button"
                className={union.isGameMode ? '' : 'active'}
                disabled={!canEdit}
                onClick={() => onModeSwitch(false)}>
                <i className="glyphicon glyphicon-flash" />
                Rules
              </button>
              <button type="button"
                className={union.isGameMode ? 'active' : ''}
                disabled={!canEdit}
                onClick={() => onModeSwitch(true)}>
                <i className="glyphicon glyphicon-fire" />
                Games
              </button>
            </div>
            <ul className="tree">
              {data.map((rule, index) => {
                const ruleNotifications = notifications.filter(x => x.conditions.find(o => o.unionRuleId === rule.unionEntityId));
                return (
                  <li key={`union-${uid(union)}-rule-${uid(rule)}`}>
                    {index > 0 && (
                      <ConjunctionIndicator conjunction={union.childrenConjunction}
                        onChange={
                          canEdit
                            ? conjunction => {
                              union.childrenConjunction = conjunction;
                              onChange();
                            }
                            : null
                        } />
                    )}
                    <button type="button"
                      onClick={() => onClick(rule, union.isGameMode ? GameElement.Games : GameElement.Rules)}
                      className={`${helper.simulationStatusText(
                        simulation ? (union.isGameMode
                          ? simulation.rules.find(r => r.gameId === rule.id)
                          : simulation.rules.find(r => r.ruleId === rule.id && r.ruleUnionId === union.id)) : null,
                      )} rule`}
                      title={
                        simulation
                          ? helper.simulationStatusText(
                            simulation ? (union.isGameMode
                              ? simulation.rules.find(r => r.gameId === rule.id)
                              : simulation.rules.find(r => r.ruleId === rule.id && r.ruleUnionId === union.id)) : null,
                          )
                          : rule.name
                      }>
                      <Image src={GetImageSrc(rule.imageId, 40)} alt="rule" />
                      {rule.name}
                    </button>
                    <Popup trigger={(
                      <button type="button"
                        className={`btn-notification ${ruleNotifications.length > 0 ? 'active' : ''}`}>
                        <i className="glyphicon glyphicon-bell" />
                        <span>{ruleNotifications.length}</span>
                      </button>
                    )}
                    on={['click']}
                    position="right center"
                    closeOnDocumentClick
                    className="reward notifications">
                      <div className="union-title">
                        <i className="glyphicon glyphicon-bell" />
                        Rule Notifications
                      </div>
                      <ul className="tree">
                        {
                          ruleNotifications.map(notification => (
                            <li key={notification.name}>
                              <button onClick={() => onNotificationClick(notification)} type="button"
                                className="rule">
                                <Image src={GetImageSrc(notification.imageId, 40)} alt="notification" />
                                {notification.name}
                              </button>
                              <button type="button"
                                className="btn-remove"
                                title="Delete"
                                onClick={() => removeFromGame(notifications, notification)}>
                                <i className="glyphicon glyphicon-remove" />
                              </button>
                            </li>
                          ))
                        }
                      </ul>
                      <GameUnionActions data={ruleNotifications} conjunction={Conjunction.And}
                        then={false} or={false}
                        onSelect={() => onNotificationClick(null, 3)} />
                    </Popup>
                    {canEdit && (
                      <>
                        <button type="button"
                          className="btn-remove"
                          title="Delete"
                          disabled={ruleNotifications.length > 0}
                          onClick={() => removeFromGame(data, rule)}>
                          <i className="glyphicon glyphicon-remove" />
                        </button>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
            {canEdit && (
              <GameUnionActions data={data}
                conjunction={union.childrenConjunction}
                onCopy={onCopy}
                onSelect={conjunction => onRuleAddClick(conjunction)} />
            )}
          </div>
        </div>

        {advancedMode
          && canEdit && (
          <button type="button" className="btn-remove"
            title="Delete Group"
            disabled={anyUnionNotifications}
            onClick={() => onUnionRemove(union) && onChange()}>
            <i className="glyphicon glyphicon-remove" />
          </button>
        )}
        {union.isGameMode ? (
          <GameElementSelector isOpen={ruleSelectionIsOpen}
            mode={GameElement.Games}
            existing={union.childrenConjunction === Conjunction.Then ? [] : union.games}
            onClose={() => this.setState({ ruleSelectionIsOpen: false })}
            onSelect={game => addGame(game)} />
        ) : (
          <GameElementSelector isOpen={ruleSelectionIsOpen}
            mode={GameElement.Rules}
            existing={union.childrenConjunction === Conjunction.Then ? [] : union.rules}
            onClose={() => this.setState({ ruleSelectionIsOpen: false })}
            onSelect={rule => addRule(rule)}
            onAdd={copy => onAdd(union.rules, GameElement.Rules, copy)} />
        )}
      </div>
    );
  }
}

GameRuleUnion.propTypes = {
  union: PropTypes.shape({
    id: PropTypes.number,
    isGameMode: PropTypes.bool,
    games: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    rules: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    childrenConjunction: PropTypes.number,
  }).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  onUnionRemove: PropTypes.func.isRequired,
  advancedMode: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  simulation: PropTypes.shape({}),
  onCopy: PropTypes.func,
};

GameRuleUnion.defaultProps = {
  simulation: null,
  onCopy: null,
};

export default GameRuleUnion;
