import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/modules/Log';
import List from '../../containers/List';
import date from '../../utils/date';

// NOTE: THIS IS TYPESCRIPT EXAMPLE

type LogModel = { message: string, url: string, remoteIp: string, exception: string };
type LogListProps = { list: { paging: {}, data: any[], preferTable: boolean}, requestList: Function }

const LogList = (props : LogListProps) => {
  const { list, requestList } = props;

  const columns = [
    {
      id: 'level',
      Header: 'Level',
      accessor: 'level',
      sortable: false,
      maxWidth: 120,
    },
    {
      id: 'logger',
      Header: 'Logger',
      accessor: 'logger',
      sortable: false,
      maxWidth: 165,
    },
    {
      id: 'message',
      Header: 'Message',
      accessor: 'message',
      sortable: false,
    },
    {
      id: 'user',
      Header: 'User',
      accessor: 'user',
      Cell: (cell: any) => (cell.value ? cell.value.name : ''),
      sortable: false,
      maxWidth: 165,
    },
    {
      id: 'createdOn',
      Header: 'Created',
      accessor: 'createdOn',
      Cell: (cell: any) => date.long(cell.value),
      sortable: true,
      maxWidth: 165,
    },
  ];

  function renderRule(log: LogModel) {
    return (
      <div className="log-details">
        <div className="row m-b-sm">
          <div className="col-md-3">Message</div>
          <div className="col-md-9">{log.message}</div>
        </div>
        {log.url && (
          <div className="row m-b-sm">
            <div className="col-md-3">URL</div>
            <div className="col-md-9">{log.url}</div>
          </div>
        )}
        {log.remoteIp && (
          <div className="row m-b-sm">
            <div className="col-md-3">Remote IP</div>
            <div className="col-md-9">{log.remoteIp}</div>
          </div>
        )}
        {log.exception && (
          <div className="row m-b-sm">
            <div className="col-md-3">Exception</div>
            <div className="col-md-9">{log.exception}</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <List title="Logs"
      subtitle="Log is a GZone system event e.g. error"
      requestList={requestList}
      {...list.paging}
      preferTable
      tableData={list.data}
      tableColumns={columns}
      subComponent={(row: {original: LogModel}) => renderRule(row.original)} />
  );
};

LogList.propTypes = {
  list: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string,
      url: PropTypes.string,
      remoteIp: PropTypes.string,
      exception: PropTypes.string,
    })).isRequired,
    paging: PropTypes.shape({
      sortBy: PropTypes.string,
      sortDescending: PropTypes.bool,
    }).isRequired,
    preferTable: PropTypes.bool.isRequired,
  }).isRequired,
  requestList: PropTypes.func.isRequired,
};

export default connect(
  (state: { log: LogListProps }) => ({
    list: state.log.list,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(LogList);
