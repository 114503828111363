import { actionCreators as Alert } from './Alert';
import restHttp from '../../utils/restHttp';
import Base from './Base';

const base = new Base('file', 'File');

const initialState = {
  openedImages: [],
  openedImageIndex: 0,
};

export const actionCreators = {
  requestPost: (file, callback) => dispatch => {
    dispatch({ type: base.requestPostType });

    const data = new FormData();
    data.append('file', file);

    restHttp
      .post('file', data)
      .then(response => {
        dispatch({
          type: base.receivePostType,
        });
        if (callback) callback(response.data);
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  openImages: (items, index) => dispatch => {
    dispatch({
      type: base.customType('open'),
      openedImages: items || [],
      openedImageIndex: index || 0,
    });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (action.type === base.customType('open')) {
    return {
      ...newState,
      openedImages: action.openedImages,
      openedImageIndex: action.openedImageIndex,
    };
  }

  return newState;
};
