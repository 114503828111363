import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import Image, { GetImageSrc } from './Image';

export const SingleValue = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      {data.imageId !== undefined && (
        <Image className="avatar xs" src={GetImageSrc(data.imageId, 24)}
          alt="icon" />
      )}
      {children}
    </components.SingleValue>
  );
};

export const MultiValueContainer = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.MultiValueContainer {...props}>
      {data.imageId !== undefined && (
        <Image className="avatar xs" src={GetImageSrc(data.imageId, 24)}
          alt="icon" />
      )}
      {children}
    </components.MultiValueContainer>
  );
};

export const Option = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      {data.imageId !== undefined && (
        <Image className="avatar xs" src={GetImageSrc(data.imageId, 30)}
          alt="icon" />
      )}
      {children}
      {data.isDisabled && <span style={{ fontSize: 'xx-small', float: 'right', margin: '-5px' }}>Disabled</span>}
    </components.Option>
  );
};

const SelectField = ({
  options, field, form, isMulti, className, onChange, isDisabled,
}) => {
  let value = isMulti ? [] : '';

  if (isMulti) {
    value = options.filter(option => field.value.find(x => x.id === option.value));
  } else {
    value = options.find(option => option.value === field.value);
  }

  if (value === undefined) value = null;

  return (
    <Select options={options}
      name={field.name}
      placeholder=""
      isMulti={isMulti}
      menuPortalTarget={document.body}
      className={`react-select-container ${className}`}
      classNamePrefix="react-select"
      value={value}
      isClearable
      isOptionDisabled={option => option.isDisabled}
      isDisabled={isDisabled}
      onChange={selectedOptions => {
        if (isMulti) {
          const newValue = selectedOptions ? selectedOptions.map(option => ({
            id: option.value,
          })) : [];
          form.setFieldValue(field.name, newValue);
          if (onChange) onChange(form, newValue);
        } else {
          const newValue = selectedOptions ? selectedOptions.value : null;
          form.setFieldValue(field.name, newValue);
          if (value !== selectedOptions && onChange) onChange(form, newValue);
        }
      }}
      onBlur={field.onBlur}
      components={{ SingleValue, MultiValueContainer, Option }} />
  );
};

SelectField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  isMulti: PropTypes.bool,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isDisabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
};

SelectField.defaultProps = {
  isMulti: false,
  onChange: null,
  isDisabled: false,
};

export default SelectField;

export const Convert = (records, removeDisabled) => {
  let data = records;

  if (removeDisabled) data = data.filter(x => !x.isDisabled);

  return data.map(record => ({
    value: record.id,
    label: record.name,
    imageId: record.imageId === undefined ? undefined : record.imageId,
    isDisabled: record.isDisabled,
  }));
};
