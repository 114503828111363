import { actionCreators as Alert } from './Alert';
import restHttp from '../../utils/restHttp';

const requestAppType = 'gzone/setting/app/request';
export const receiveAppType = 'gzone/setting/app/receive';
const requestUserType = 'gzone/setting/user/request';
export const receiveUserType = 'gzone/setting/user/receive';
export const afterReceiveUserType = 'gzone/setting/user/receive/after';

const initialState = {
  app: {},
  user: {},
};

export const actionCreators = {
  requestApp: () => dispatch => {
    dispatch({ type: requestAppType });

    restHttp
      .get('setting/app')
      .then(response => {
        dispatch({
          type: receiveAppType,
          app: response.data,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestUser: () => dispatch => {
    dispatch({ type: requestUserType });

    restHttp
      .get('setting/user')
      .then(response => {
        dispatch({
          type: receiveUserType,
          user: response.data,
        });
        dispatch({
          type: afterReceiveUserType,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (action.type === receiveAppType) {
    return {
      ...newState,
      app: action.app,
    };
  }

  if (action.type === receiveUserType) {
    return {
      ...newState,
      user: action.user,
    };
  }

  return newState;
};

export const selectors = {
  getSetting: (state, type) => {
    // if logged in return from user settings else from app/global
    if (state.user.auth) {
      const setting = state.setting.user.find(item => item.settingType === type);

      if (setting) {
        return setting.value;
      }
    } else if (state.setting.app[type]) { return state.setting.app[type]; }
    return null;
  },
  getSettingAsNumber: (state, type) => {
    const val = selectors.getSetting(state, type);
    if (val === null || val === undefined) return null;
    return Number(val);
  },
  getSettingAsBoolean: (state, type) => {
    const val = selectors.getSetting(state, type);
    if (val === null || val === undefined) return null;
    return Boolean(val);
  },
};
