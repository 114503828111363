import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { actionCreators } from '../../store/modules/User';
import ImageUpload from '../../controls/ImageUpload';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import date from '../../utils/date';
import SettingsTemplate, { SettingsValidationRules } from '../../templates/Settings';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {
        name: '',
        username: '',
        email: '',
        password: '',
        passwordConfirm: '',
        imageId: '',
        settings: [],
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { requestGet } = this.props;

    requestGet();
  }

  handleSave(values) {
    const { requestPut } = this.props;

    requestPut(null, values);
  }

  testPassword(value) {
    return this.parent.password === value;
  }

  render() {
    const { user } = this.props;
    const { template } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          username: Yup.string()
            .max(50)
            .required(),
          password: Yup.string()
            .min(6)
            .max(50)
            .nullable(),
          passwordConfirm: Yup.string().test('passwords-match', 'Passwords must match', this.testPassword),
          name: Yup.string()
            .max(50)
            .required(),
          email: Yup.string()
            .email()
            .max(50)
            .required(),
          imageId: Yup.string().nullable(),
          settings: SettingsValidationRules(user ? user.settings : null),
        })}
        initialValues={user || template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">User Profile</span>
                  <span className="sub-heading hex-img">
                    A user profile is a collection of settings and information associated with a user.
                  </span>
                  <div className="pageStyle">
                    <BackButton />
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} form={this.form}
                        single />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="username" className="control-label font-bold">
                                Username
                                <Field id="username"
                                  name="username"
                                  autoFocus
                                  autoComplete="off"
                                  className={errors.username ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="password" className="control-label font-bold">
                                Password
                                <Field id="password"
                                  value={values.password || ''}
                                  name="password"
                                  type="password"
                                  autoComplete="new-password"
                                  placeholder="Leave empty to skip"
                                  className={errors.password ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="passwordConfirm" className="control-label font-bold">
                                Confirm Password
                                <Field id="passwordConfirm"
                                  value={values.passwordConfirm || ''}
                                  name="passwordConfirm"
                                  type="password"
                                  autoComplete="new-password"
                                  placeholder="Leave empty to skip"
                                  className={errors.passwordConfirm ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="name" className="control-label font-bold">
                                Name
                                <Field id="name" name="name"
                                  className={errors.name ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="email" className="control-label font-bold">
                                Email
                                <Field id="email"
                                  name="email"
                                  type="email"
                                  className={errors.email ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-subsection">
                          <h4>Settings</h4>
                          <div className="row">
                            <SettingsTemplate settings={values.settings} errors={errors} />
                          </div>
                        </div>
                      </div>
                      {user && (
                        <div className="col-md-3 form-additional-info">
                          <label>
                            Created
                            <div>{date.long(user.createdOn)}</div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

UserProfile.propTypes = {
  user: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  requestGet: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
};

UserProfile.defaultProps = {
  user: null,
};

export default connect(
  state => ({
    user: state.user.auth ? state.user.details[Number(state.user.auth.id)] : null,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(UserProfile);
