import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { GameElement, Conjunction } from '../../utils/enum';
import Image, { GetImageSrc } from '../../controls/Image';
import GameElementSelector from './ElementSelector';
import ConjunctionIndicator from '../../controls/ConjunctionIndicator';
import GameUnionActions from './UnionActions';
import GameRewardsRank from './RewardsRank';

class GameRewardUnion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rewardSelectionIsOpen: false,
    };
    this.currency = { id: 'currency', name: 'Currency' };
    this.experience = { id: 'experience', name: 'Experience' };
  }

  addReward = (reward, experience, currency) => {
    const { union, onChange } = this.props;

    if (reward) union.rewards.push(reward);
    else if (experience) union.experience = experience;
    else if (currency) union.currency = currency;
    onChange();
    this.setState({ rewardSelectionIsOpen: false });
  };

  renderNumbers = (min, max, step, onSelect) => {
    const numbers = [];
    for (let index = min; index <= max; index += step) {
      numbers.push(index);
    }
    return (
      <>
        {numbers.map(number => (
          <button type="button" title="Select"
            key={`n-${number}`} onClick={() => onSelect(number)}>
            {number}
          </button>
        ))}
      </>
    );
  };

  removeFromGame = (array, element) => {
    const { onChange } = this.props;

    const index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
      onChange();
    }
  };

  validNumber = text => {
    if (text) {
      if (Number(text) > 0 && Number.isInteger(Number(text))) {
        return true;
      }
    }
    return false;
  };

  onExperienceChange = experience => {
    const { union, onChange } = this.props;

    if (this.validNumber(experience) || !experience) {
      union.experience = experience;
      onChange();
    }
  };

  onCurrencyChange = currency => {
    const { union, onChange } = this.props;

    if (this.validNumber(currency) || !currency) {
      union.currency = currency;
      onChange();
    }
  };

  onAddClick = () => {
    const { union } = this.props;

    union.childrenConjunction = Conjunction.And;
    this.setState({ rewardSelectionIsOpen: true });
  };

  render() {
    const {
      union, onChange, onUnionRemove, advancedMode, canEdit, onAdd, onClick,
    } = this.props;
    const { rewardSelectionIsOpen } = this.state;

    let rewards = [];

    if (union.currency > 0) {
      rewards.push(this.currency);
    }
    if (union.experience > 0) {
      rewards.push(this.experience);
    }
    rewards = [...rewards, ...union.rewards];

    return (
      <div className={advancedMode ? 'union-advanced-mode' : 'union-simple-mode'}>
        {advancedMode && (
          <div className="union-title">
            <i className="glyphicon glyphicon-gift" />
            Rewards
            <GameRewardsRank rewardUnion={union} canEdit={canEdit} onChange={onChange} />
          </div>
        )}
        <ul className="tree">
          {rewards.map((reward, index) => (
            <li key={`reward-${reward.id}`}>
              {index > 0 && <ConjunctionIndicator conjunction={Conjunction.And} />}
              {reward === this.currency || reward === this.experience ? (
                <div className="reward reward-fixed" title={reward.name}>
                  <Image src={GetImageSrc(reward.imageId, 40)} alt="reward" />
                  {reward.name}
                  {canEdit ? (
                    <Popup trigger={(
                      <input type="number"
                        min="1"
                        className="reward-value"
                        readOnly={!canEdit}
                        value={reward === this.currency ? union.currency : union.experience}
                        onChange={e => (reward === this.currency
                          ? this.onCurrencyChange(e.target.value)
                          : this.onExperienceChange(e.target.value))} />
                    )}
                      on="hover"
                      position="top left"
                      mouseLeaveDelay={300}
                      closeOnDocumentClick
                      className="reward">
                      {reward === this.currency ? (
                        <div className="currencies">{this.renderNumbers(5, 100, 5, n => this.onCurrencyChange(n))}</div>
                      ) : (
                        <div className="experiences">{this.renderNumbers(10, 200, 10, n => this.onExperienceChange(n))}</div>
                      )}
                    </Popup>
                  ) : (
                    <>
                      {(reward === this.currency || reward === this.experience) && (
                        <span className="reward-value">{reward === this.currency ? union.currency : union.experience}</span>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <button type="button" 
                  onClick={() => onClick(reward, GameElement.Rewards)}
                  className="reward" 
                  title={reward.name}>
                  <Image src={GetImageSrc(reward.imageId, 40)} alt="reward" />
                  {reward.name}
                </button>
              )}
              {canEdit && (
                <button type="button"
                  className="btn-remove"
                  title="Delete"
                  onClick={() => {
                    if (reward === this.currency) {
                      this.onCurrencyChange(null);
                    } else if (reward === this.experience) {
                      this.onExperienceChange(null);
                    } else {
                      this.removeFromGame(union.rewards, reward);
                    }
                  }}>
                  <i className="glyphicon glyphicon-remove" />
                </button>
              )}
            </li>
          ))}
        </ul>

        {canEdit && (
          <GameUnionActions data={union.rewards}
            conjunction={Conjunction.And}
            then={false}
            or={false}
            onSelect={() => this.onAddClick()} />
        )}

        {advancedMode
          && canEdit && (
            <button type="button" className="btn-remove"
              title="Delete Group" onClick={() => onUnionRemove(union) && onChange()}>
              <i className="glyphicon glyphicon-remove" />
            </button>
        )}
        <GameElementSelector isOpen={rewardSelectionIsOpen}
          mode={GameElement.Rewards}
          existing={[union.currency > 0 ? this.currency : {}, union.experience > 0 ? this.experience : {}, ...union.rewards]}
          onClose={() => this.setState({ rewardSelectionIsOpen: false })}
          onSelect={this.addReward}
          onAdd={copy => onAdd(union.rewards, GameElement.Rewards, copy)} />
      </div>
    );
  }
}

GameRewardUnion.propTypes = {
  union: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onChange: PropTypes.func.isRequired,
  onUnionRemove: PropTypes.func.isRequired,
  advancedMode: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GameRewardUnion;
