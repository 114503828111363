import { actionCreators as Alert } from './Alert';
import restHttp from '../../utils/restHttp';

const requestListType = 'gzone/search/list/request';
const receiveListType = 'gzone/search/list/receive';
const clearListType = 'gzone/search/list/clear';

const initialState = {
  list: {
    data: [],
  },
};

export const actionCreators = {
  requestList: text => dispatch => {
    dispatch({ type: requestListType });

    restHttp
      .get(`search?text=${encodeURIComponent(text)}`)
      .then(response => {
        dispatch({
          type: receiveListType,
          data: response.data,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  clearList: () => dispatch => {
    dispatch({ type: clearListType, data: [] });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (action.type === receiveListType || action.type === clearListType) {
    return {
      ...newState,
      list: {
        ...newState.list,
        data: action.data,
      },
    };
  }

  return newState;
};
