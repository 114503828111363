import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import animate from '../../utils/animate';
import { actionCreators } from '../../store/modules/User';
import './Login.css';
import Logo from '../../images/logo.png';
import User from '../../images/User.png';
import Password from '../../images/Password.png';

const Login = props => {
  const { requestLogin } = props;

  const state = {
    username: '',
    password: '',
  };

  const handleInputChange = event => {
    const { target } = event;
    const { name, value } = target;

    state[name] = value;
  };

  const handleSubmit = () => {
    requestLogin(state.username, state.password);
  };

  const handleKeyPress = event => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <animate.login id="login-box">
      <img className="logo" src={Logo}
        alt="logo" />
      <div className="username">
        <img src={User} alt="User" />
        <input autoFocus
          name="username"
          type="text"
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder="Username"
          autoComplete="new-username" />
      </div>
      <div className="password">
        <img src={Password} alt="Password" />
        <input name="password"
          type="password"
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder="Password"
          autoComplete="new-password" />
      </div>{' '}
      <svg version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 496.6 495.5"
        style={{ enableBackground: 'new 0 0 496.6 495.5' }}
        xmlSpace="preserve">
        <g id="Layer_1" className="st0">
          <polygon id="XMLID_1_"
            className="st1"
            points="419.6,423 244.2,494.5 69.6,421 -1.9,245.6 71.6,71 247,-0.5 421.6,73 493.1,248.4" />
        </g>
        <g id="Layer_2">
          <path id="XMLID_10_"
            className="st2"
            d="M419.2,423L245.3,495L71.7,423.3L0,250.1L72.3,76.7L246.2,4.7l173.6,71.7l71.7,173.3L419.2,423z" />
          <g id="button" onClick={handleSubmit}>
            <g id="XMLID_7_">
              <polygon id="XMLID_4_" className="st3"
                points="432.2,392.3 418.7,424.4 244.3,495.5 70.7,422.4 62,401.2" />
            </g>
            <g id="XMLID_8_">
              <polygon id="XMLID_9_" className="st4"
                points="62,401.2 432.2,392.3 418.7,424.4 244.3,495.5 70.7,422.4" />
            </g>
          </g>
          <text id="XMLID_2_" onClick={handleSubmit}
            transform="matrix(1 0 0 1 222.5952 442.585)" className="st5 st6 st7">
            Sign In
          </text>
        </g>
      </svg>
    </animate.login>
  );
};

Login.propTypes = {
  requestLogin: PropTypes.func,
};

Login.defaultProps = {
  requestLogin: null,
};

export default connect(
  state => state.user,
  dispatch => bindActionCreators(actionCreators, dispatch),
)(Login);
