import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { actionCreators } from '../../store/modules/Tenant';
import ImageUpload from '../../controls/ImageUpload';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import date from '../../utils/date';
import { selectors } from '../../store/modules/User';
import SettingsTemplate, { SettingsValidationRules } from '../../templates/Settings';

class TenantProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {
        name: '',
        imageId: '',
        settings: [],
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { requestGet } = this.props;

    requestGet();
  }

  handleSave(values) {
    const { requestPut } = this.props;

    requestPut(null, values);
  }

  render() {
    const { id, tenant } = this.props;
    const { template } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(50)
            .required(),
          imageId: Yup.string().nullable(),
          settings: SettingsValidationRules(id && tenant && tenant.id === id ? tenant.settings : null),
        })}
        initialValues={id && tenant && tenant.id === id ? tenant : template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">Tenant Profile</span>
                  <span className="sub-heading hex-img">
                    A tenant profile is a collection of settings and information associated with a tenant.
                  </span>
                  <div className="pageStyle">
                    <BackButton />
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} form={this.form}
                        single />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="name" className="control-label font-bold">
                                Name
                                <Field id="name"
                                  name="name"
                                  autoFocus
                                  className={errors.name ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-subsection">
                          <h4>Settings</h4>
                          <div className="row">
                            <SettingsTemplate settings={values.settings} errors={errors} />
                          </div>
                        </div>
                      </div>
                      {tenant && (
                        <div className="col-md-3 form-additional-info">
                          <label>
                            Created
                            <div>{date.long(tenant.createdOn)}</div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

TenantProfile.propTypes = {
  tenant: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  requestGet: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

TenantProfile.defaultProps = {
  tenant: null,
};

export default connect(
  state => ({
    id: selectors.getCurrentTenantId(state),
    tenant: state.tenant.details[selectors.getCurrentTenantId(state)],
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(TenantProfile);
