import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { actionCreators as playerAttributeActionCreators } from '../../store/modules/PlayerAttribute';
import { actionCreators as gameAttributeActionCreators } from '../../store/modules/GameAttribute';
import { actionCreators as actionTypeActionCreators } from '../../store/modules/ActionType';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import SelectField, { Convert } from '../../controls/SelectField';
import {
  DataType, ConvertToOptions, Aggregate, AttributePeriod,
} from '../../utils/enum';
import DeleteButton from '../../controls/DeleteButton';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import helper from '../../utils/helper';

// NOTE: THIS IS SAMPLE OF STATELESS IMPLEMENTATION OF COMPONENT USING useEffect and useState

const AttributeEdit = props => {
  const {
    id, attribute, requestGet, requestDelete, requestPost, requestPut, requestEnable, requestDisable, requestAllActionTypes, actionTypes,
  } = props;
  const [isAutomatic, setIsAutomatic] = useState(false);
  const template = {
    name: '',
    dataType: '',
    description: '',
    imageId: '',
  };
  const form = useRef();

  useEffect(() => {
    if (id) {
      requestGet(id);
    } else {
      requestAllActionTypes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = values => {
    if (id) {
      requestPut(id, values);
    } else {
      requestPost(values);
    }
  };

  const handleStatus = enabled => {
    if (enabled) {
      requestEnable(id);
    } else {
      requestDisable(id);
    }
  };

  const getActionTypeFields = actionTypeId => {
    const actionType = actionTypes.find(type => type.id === actionTypeId);

    let actionTypeFields = actionTypeId ? actionType.fields : [];

    actionTypeFields = actionTypeFields.filter(field => field.dataType === DataType.Number);

    return actionTypeFields;
  };

  return (
    <Formik onSubmit={values => handleSave(values)}
      innerRef={form}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(50)
          .required(),
        dataType: Yup.number()
          .integer()
          .required(),
        actionTypeId: !id && isAutomatic
          ? Yup.number()
            .integer()
            .required()
          : Yup.number()
            .integer()
            .nullable(),
        actionTypeFieldId: !id && isAutomatic
          ? Yup.number()
            .integer()
            .required()
          : Yup.number()
            .integer()
            .nullable(),
        aggregate: !id && isAutomatic
          ? Yup.number()
            .integer()
            .required()
          : Yup.number()
            .integer()
            .nullable(),
        period: Yup.number()
          .integer()
          .nullable(),
        description: Yup.string().nullable(),
        imageId: Yup.string().nullable(),
      })}
      initialValues={attribute || template}>
      {({ values, errors }) => (
        <Form>
          <div id="dataForm">
            <div className="row m-b-sm">
              <div className="col-md-12">
                <span className="page-title hex-img">{id ? 'Edit Attribute' : 'New Attribute'}</span>
                <span className="sub-heading hex-img">
                  Attribute is a quality or characteristic that {helper.attributeResolver(props, 'player', 'game or leaderboard')} can have
                </span>
                <div className="pageStyle">
                  <BackButton />
                  {id && <DeleteButton onClick={() => requestDelete(id)} />}
                  <SaveButton />
                </div>
              </div>
            </div>
            <div className="row p-l-md">
              <div className="col-md-12">
                <div className="panel panel-default hex">
                  <div className="octomask">
                    <ImageUpload imageIds={[values.imageId]} form={form}
                      single />
                  </div>
                  <div className="panel-heading" />
                  <div className="panel-body hex" id="scroller">
                    {
                      !id && helper.attributeResolver(props, true, false) && (
                        <label className="game-switch" htmlFor="mode-switch">
                          Automatic
                          <div style={{ textAlign: 'right' }}>
                            <Switch id="game-switch"
                              onChange={() => {
                                if (isAutomatic) { form.current.setFieldValue('dataType', null); } else { form.current.setFieldValue('dataType', DataType.Number); }
                                setIsAutomatic(!isAutomatic);
                              }}
                              checked={isAutomatic}
                              checkedChildren={<i className="glyphicon glyphicon-ok" />}
                              unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                          </div>
                        </label>
                      )
                    }
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="form-group form-group-lg">
                            <label htmlFor="name" className="control-label font-bold">
                              Name
                              <Field id="name"
                                name="name"
                                autoFocus
                                className={errors.name ? 'form-control error' : 'form-control'} />
                            </label>
                          </div>
                        </div>
                        {!id && !isAutomatic && (
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="dataType" className="control-label font-bold">
                                Data Type
                                <Field id="dataType"
                                  name="dataType"
                                  component={SelectField}
                                  options={ConvertToOptions(DataType)}
                                  className={errors.dataType ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      {!id && isAutomatic && (
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="actionTypeId" className="control-label font-bold">
                                Action
                                <Field id="actionTypeId"
                                  name="actionTypeId"
                                  component={SelectField}
                                  options={Convert(actionTypes)}
                                  onChange={frm => {
                                    frm.setFieldValue('actionTypeFieldId', null);
                                    frm.setFieldValue('aggregate', null);
                                    frm.setFieldValue('period', null);
                                  }}
                                  className={errors.actionTypeId ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="actionTypeFieldId" className="control-label font-bold">
                                Field
                                <Field id="actionTypeFieldId"
                                  name="actionTypeFieldId"
                                  component={SelectField}
                                  options={Convert(getActionTypeFields(values.actionTypeId))}
                                  onChange={frm => {
                                    frm.setFieldValue('aggregate', null);
                                    frm.setFieldValue('period', null);
                                  }}
                                  className={
                                    errors.actionTypeFieldId ? 'form-control error' : 'form-control'
                                  } />
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {
                        !id && isAutomatic && (
                          <div className="row">
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="aggregate" className="control-label font-bold">
                                  Aggregate
                                  <Field id="aggregate"
                                    name="aggregate"
                                    component={SelectField}
                                    options={ConvertToOptions(Aggregate)}
                                    onChange={frm => {
                                      frm.setFieldValue('period', null);
                                    }}
                                    className={
                                      errors.aggregate ? 'form-control error' : 'form-control'
                                    } />
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="period" className="control-label font-bold">
                                  Period
                                  <Field id="period"
                                    name="period"
                                    component={SelectField}
                                    options={ConvertToOptions(AttributePeriod)}
                                    className={
                                      errors.period ? 'form-control error' : 'form-control'
                                    } />
                                </label>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      <div className="row">
                        <div className="col-xs-12">
                          <div className="form-group form-group-lg">
                            <label htmlFor="description" className="control-label font-bold">
                              Description
                              <Field id="description"
                                name="description"
                                value={values.description || ''}
                                className={errors.description ? 'form-control error' : 'form-control'} />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {id
                      && attribute && (
                      <div className="col-md-3 form-additional-info">
                        <label>
                          Created
                          <div>{date.long(attribute.createdOn)}</div>
                        </label>
                        <label>
                          Data Type
                          <div>{DataType[attribute.dataType]}</div>
                        </label>
                        { attribute.actionType && (
                          <label>
                            Action
                            <div>{attribute.actionType.name}</div>
                          </label>
                        )}
                        { attribute.actionTypeField && (
                          <label>
                            Field
                            <div>{attribute.actionTypeField.name}</div>
                          </label>
                        )}
                        { attribute.aggregate && (
                          <label>
                            Aggregate
                            <div>{Aggregate[attribute.aggregate]}</div>
                          </label>
                        )}
                        { attribute.period && (
                          <label>
                            Period
                            <div>{AttributePeriod[attribute.period]}</div>
                          </label>
                        )}
                        <label htmlFor="status-switch">
                          Disabled
                          <div>
                            <Switch id="status-switch"
                              onChange={() => handleStatus(attribute.isDisabled)}
                              checked={attribute.isDisabled}
                              checkedChildren={<i className="glyphicon glyphicon-ok" />}
                              unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                          </div>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AttributeEdit.propTypes = {
  id: PropTypes.number,
  attribute: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    dataType: PropTypes.number,
    createdOn: PropTypes.string,
    isDisabled: PropTypes.bool,
  }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
  requestAllActionTypes: PropTypes.func.isRequired,
  actionTypes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
};

AttributeEdit.defaultProps = {
  id: null,
  attribute: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    attribute: helper.attributeResolver(ownProps, state.playerAttribute.details[helper.getIdFromProps(ownProps)], state.gameAttribute.details[helper.getIdFromProps(ownProps)]),
    actionTypes: state.actionType.all,
  }),
  (dispatch, ownProps) => bindActionCreators(
    {
      ...helper.attributeResolver(ownProps, playerAttributeActionCreators, gameAttributeActionCreators),
      requestAllActionTypes: actionTypeActionCreators.requestAll,
    },
    dispatch,
  ),
)(AttributeEdit);
