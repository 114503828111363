import React from 'react';
import { Glyphicon, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavMenu.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { actionCreators, selectors } from '../store/modules/User';

const NavMenu = props => {
  const { requestLogout, isAdmin, tenantId } = props;

  return (
    <div id="gaas-sidebar-menu">
      <LinkContainer to="/" exact
        className="link">
        <NavItem>
          <Glyphicon glyph="dashboard" className="menu-icon" /> Dashboard
        </NavItem>
      </LinkContainer>
      {isAdmin
        && !tenantId && (
        <>
          <LinkContainer to="/tenant" className="link">
            <NavItem>
              <Glyphicon glyph="briefcase" className="menu-icon" /> Tenants
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/user" className="link">
            <NavItem>
              <Glyphicon glyph="sunglasses" className="menu-icon" /> Administrators
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/log" className="link">
            <NavItem>
              <Glyphicon glyph="alert" className="menu-icon" /> Logs
            </NavItem>
          </LinkContainer>
        </>
      )}
      {((isAdmin && tenantId) || !isAdmin) && (
        <>
          <LinkContainer to="/user" className="link">
            <NavItem>
              <Glyphicon glyph="sunglasses" className="menu-icon" /> Users
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/player" className="link">
            <NavItem>
              <Glyphicon glyph="tower" className="menu-icon" /> Players
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/level" className="link sublink">
            <NavItem>
              <Glyphicon glyph="signal" className="menu-icon" /> Levels
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/group" className="link sublink">
            <NavItem>
              <Glyphicon glyph="link" className="menu-icon" /> Groups
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/attribute/player" className="link sublink">
            <NavItem>
              <Glyphicon glyph="wrench" className="menu-icon" /> Attributes
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/participant" className="link sublink">
            <NavItem>
              <Glyphicon glyph="screenshot" className="menu-icon" /> Profiles
            </NavItem>
          </LinkContainer>
          {
            (isAdmin && tenantId)
            && (
              <LinkContainer to="/transaction" className="link sublink">
                <NavItem>
                  <Glyphicon glyph="usd" className="menu-icon" /> Transactions
                </NavItem>
              </LinkContainer>
            )
          }
          <LinkContainer to="/action" className="link">
            <NavItem>
              <Glyphicon glyph="plane" className="menu-icon" /> Actions
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/actionType" className="link sublink">
            <NavItem>
              <Glyphicon glyph="cog" className="menu-icon" /> Types
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/notification" className="link">
            <NavItem>
              <Glyphicon glyph="envelope" className="menu-icon" /> Notifications
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/notificationType" className="link sublink">
            <NavItem>
              <Glyphicon glyph="cog" className="menu-icon" /> Types
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/game/game" className="link">
            <NavItem>
              <Glyphicon glyph="fire" className="menu-icon" /> Games
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/filter" className="link sublink">
            <NavItem>
              <Glyphicon glyph="filter" className="menu-icon" /> Filters
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/rule/game" className="link sublink">
            <NavItem>
              <Glyphicon glyph="flash" className="menu-icon" /> Rules
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/reward" className="link sublink">
            <NavItem>
              <Glyphicon glyph="gift" className="menu-icon" /> Rewards
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/attribute/game" className="link sublink">
            <NavItem>
              <Glyphicon glyph="wrench" className="menu-icon" /> Attributes
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/game/leaderboard" className="link sublink">
            <NavItem>
              <Glyphicon glyph="list" className="menu-icon" /> Leaderboards
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/rule/leaderboard" className="link sublink">
            <NavItem>
              <Glyphicon glyph="stats" className="menu-icon" /> Rankings
            </NavItem>
          </LinkContainer>
        </>
      )}
      <LinkContainer to="/#" exact
        onClick={() => requestLogout()} className="link">
        <NavItem>
          <Glyphicon glyph="log-out" className="menu-icon" /> Logout
        </NavItem>
      </LinkContainer>
    </div>
  );
};

NavMenu.propTypes = {
  requestLogout: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  tenantId: PropTypes.number,
};

NavMenu.defaultProps = {
  tenantId: null,
};

export default connect(
  state => ({
    router: state.router,
    isAdmin: selectors.getIsAdmin(state),
    tenantId: state.user.tenantId,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(NavMenu);
