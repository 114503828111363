import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators } from '../../store/modules/Action';
import List, { ListPropTypes } from '../../containers/List';
import date from '../../utils/date';
import ListImage from '../../containers/ListImage';

const ActionList = props => {
  const {
    list, requestList, switchDisplayStyle, switchFilterVisibility,
  } = props;

  const columns = [
    {
      id: 'imageId',
      Header: '',
      Cell: row => (
        <ListImage imageSize={32}
          className="table-image"
          items={list.data.map(a => a.player)}
          currentItem={row.original.player} />
      ),
      sortable: false,
      maxWidth: 40,
    },
    {
      id: 'player',
      Header: 'Player',
      accessor: 'player.name',
      sortable: true,
    },
    {
      id: 'actionType',
      Header: 'Action',
      accessor: 'actionType.name',
      sortable: true,
    },
    {
      id: 'createdOn',
      Header: 'Created',
      accessor: 'createdOn',
      Cell: cell => date.long(cell.value),
      sortable: true,
      maxWidth: 165,
    },
    {
      id: 'processedOn',
      Header: 'Processed',
      accessor: 'processedOn',
      Cell: cell => (cell.value ? date.long(cell.value) : ''),
      sortable: true,
      maxWidth: 165,
    },
    {
      accessor: 'id',
      Cell: cell => (
        <LinkContainer to={`/action/${cell.value}`} exact>
          <div className="gaas-tile-btn">See More</div>
        </LinkContainer>
      ),
      maxWidth: 150,
    },
  ];
  return (
    <List title="Actions"
      subtitle="Action is a player activity that impacts games"
      requestList={requestList}
      {...list.paging}
      preferTable={list.preferTable}
      filters={list.filters}
      tableData={list.data}
      tableColumns={columns}
      switchDisplayStyle={switchDisplayStyle}
      switchFilterVisibility={switchFilterVisibility}
      newLink="/action/new">
      {list.data.map(action => (
        <div key={action.id}>
          <div className="gaas-tile">
            <div className="gaas-tile-image">
              <ListImage items={list.data.map(a => a.player)} currentItem={action.player} />
            </div>
            <div className="gaas-tile-heading h-img">
              <span className="text-ellipsis">{action.player.name}</span>
            </div>
            <div className="gaas-tile-body">
              <div className="m-t-md m-b-md padder">
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Type</span>
                    {' : '}
                    <span>{action.actionType.name}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Created</span>
                    {' : '}
                    <span>{date.long(action.createdOn)}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Processed</span>
                    {' : '}
                    <span>{action.processedOn ? date.long(action.processedOn) : '-'}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="gaas-tile-footer m-t-sm" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
              <LinkContainer to={`/action/${action.id}`} exact>
                <div className="gaas-tile-btn">See More</div>
              </LinkContainer>
            </div>
            <div className="clear" />
          </div>
        </div>
      ))}
    </List>
  );
};

ActionList.propTypes = {
  list: ListPropTypes.isRequired,
  requestList: PropTypes.func.isRequired,
  switchDisplayStyle: PropTypes.func.isRequired,
  switchFilterVisibility: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    list: state.action.list,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(ActionList);
