import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Weekday, Month, ConvertToOptions } from '../utils/enum';

export const Mode = {
  Weekday: 1,
  Month: 2,
  Day: 3,
  Hour: 4,
  Year: 5,
};

const DateSelectField = ({
  field, form, className, mode, isDisabled, onChange,
}) => {
  const { value } = field;

  let selected = '';

  if (value) {
    if (new Date(value).getTime() === new Date(Date.UTC(1000, 0, 1, 0, 0, 0, 0)).getTime()) {
      selected = { value: -1, label: 'Current' };
    } else if (mode === Mode.Weekday) {
      let day = new Date(value).getUTCDay();
      // sunday fix
      if (day === 0) {
        day = 7;
      }
      selected = { value: day, label: Weekday[day] };
    } else if (mode === Mode.Month) {
      // month fix
      const month = new Date(value).getUTCMonth() + 1;
      selected = { value: month, label: Month[month] };
    } else if (mode === Mode.Day) {
      const day = new Date(value).getUTCDate();
      selected = { value: day, label: day };
    } else if (mode === Mode.Hour) {
      const hour = new Date(value).getUTCHours();
      selected = { value: hour, label: hour };
    } else if (mode === Mode.Year) {
      const year = new Date(value).getUTCFullYear();
      selected = { value: year, label: year };
    }
  }

  let options = [];
  if (mode === Mode.Weekday) {
    options = ConvertToOptions(Weekday);
  } else if (mode === Mode.Month) {
    options = ConvertToOptions(Month);
  } else if (mode === Mode.Day) {
    for (let i = 1; i <= 31; i += 1) { options.push({ value: i, label: i }); }
  } else if (mode === Mode.Hour) {
    for (let i = 0; i <= 23; i += 1) { options.push({ value: i, label: i }); }
  } else if (mode === Mode.Year) {
    for (let i = 1900; i <= 2040; i += 1) { options.push({ value: i, label: i }); }
  }

  options.push({ value: -1, label: 'Current' });

  return (
    <Select options={options}
      name={field.name}
      placeholder=""
      menuPortalTarget={document.body}
      className={`react-select-container ${className}`}
      classNamePrefix="react-select"
      value={selected}
      isClearable
      isDisabled={isDisabled}
      onChange={selectedOption => {
        let newValue = selectedOption ? selectedOption.value : null;
        if (newValue || (mode === Mode.Hour && newValue === 0)) {
          // 2007 starts with 1 January on Monday
          if (newValue === -1) {
            newValue = new Date(Date.UTC(1000, 0, 1, 0, 0, 0, 0));
          } else if (mode === Mode.Weekday) {
            newValue = new Date(Date.UTC(2007, 0, newValue, 12));
          } else if (mode === Mode.Month) {
            newValue = new Date(Date.UTC(2007, newValue - 1, 1, 12));
          } else if (mode === Mode.Day) {
            newValue = new Date(Date.UTC(2007, 0, newValue, 12));
          } else if (mode === Mode.Hour) {
            newValue = new Date(Date.UTC(2007, 0, 1, newValue));
          } else if (mode === Mode.Year) {
            newValue = new Date(Date.UTC(newValue, 0, 1, 12));
          }
          form.setFieldValue(field.name, newValue.toISOString());
          if (onChange) onChange(form, newValue.toISOString());
        } else {
          form.setFieldValue(field.name, null);
          if (onChange) onChange(form, null);
        }
      }}
      onBlur={field.onBlur} />
  );
};

DateSelectField.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  className: PropTypes.string.isRequired,
  mode: PropTypes.number.isRequired,
  isDisabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
};

DateSelectField.defaultProps = {
  isDisabled: false,
  onChange: null,
};

export default DateSelectField;
