import React from 'react';
import { motion } from 'framer-motion';

const animate = {
  login: props => (
    <motion.div {...props}
      initial={{
        y: -1000,
        transition: { duration: 0.3 },
      }}
      animate={{
        y: 0,
        transition: { duration: 0.30, ease: 'easeOut', delay: 1 },
      }} />
  ),
  filter: props => (
    <motion.div {...props}
      initial={{
        y: 50,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
      exit={{
        y: -20,
        opacity: 0,
      }} />
  ),
  search: props => (
    <motion.div {...props}
      initial={{
        opacity: 0,
        padding: 0,
      }}
      animate={{
        opacity: 1,
        padding: 20,
        transition: { duration: 0.3 },
      }}
      exit={{
        opacity: 0,
        padding: 0,
        transition: { duration: 0.3 },
      }} />
  ),
  listItem: props => (
    <motion.div {...props} initial="start"
      animate="end" exit="out"
      variants={{
        start: {
          y: 50,
          opacity: 0,
        },
        end: custom => ({
          y: 0,
          opacity: 1,
          transition: { delay: (0.02 * custom.index) + ((custom.length - 1) * 0.01 + 0.25) },
        }),
        out: custom => ({
          y: 50,
          opacity: 0,
          transition: { delay: 0.01 * (custom.length - 1 - custom.index) },
        }),
      }}
      transition={{
        duration: 0.25,
        ease: 'easeInOut',
      }} />
  ),
  router: props => (
    <motion.div {...props}
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.11, when: 'beforeChildren' } }}
      exit={{ opacity: 0, transition: { duration: 0 } }} />
  ),
  game: props => (
    <motion.div {...props}
      initial={{
        y: -100,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 0.1, delay: 0.16 },
      }}
      exit={{
        y: 100,
        opacity: 0,
        transition: { duration: 0 },
      }} />
  ),
  gameEditor: props => (
    <motion.div {...props}
      initial={{
        y: -400,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 0.3 },
      }}
      exit={{
        y: 400,
        opacity: 0,
        transition: { duration: 0.15 },
      }} />
  ),
};

export default animate;
