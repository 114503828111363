import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/modules/Transaction';
import { selectors } from '../../store/modules/Setting';
import List, { ListPropTypes } from '../../containers/List';
import date from '../../utils/date';
import ListImage from '../../containers/ListImage';
import { SettingType, BlockchainStatus } from '../../utils/enum';

const TransactionList = props => {
  const {
    list, requestList, switchDisplayStyle, switchFilterVisibility, useBlockchain,
  } = props;

  const columns = [
    {
      id: 'imageId',
      Header: '',
      Cell: row => (
        <ListImage imageSize={32}
          className="table-image"
          items={list.data.map(a => a.player)}
          currentItem={row.original.player} />
      ),
      sortable: false,
      maxWidth: 40,
    },
    {
      id: 'player',
      Header: 'Player',
      accessor: 'player.name',
      sortable: true,
    },
    {
      id: 'currency',
      Header: 'Currency',
      accessor: 'currency',
      sortable: true,
      maxWidth: 100,
    },
    {
      id: 'experience',
      Header: 'Experience',
      accessor: 'experience',
      sortable: true,
      maxWidth: 100,
    },
    {
      id: 'game',
      Header: 'Source',
      accessor: 'game',
      Cell: cell => (cell.value ? cell.value.name : ''),
      sortable: false,
    },
    {
      id: 'createdOn',
      Header: 'Created',
      accessor: 'createdOn',
      Cell: cell => date.long(cell.value),
      sortable: true,
      maxWidth: 165,
    },
  ];

  if (useBlockchain) {
    columns.push({
      id: 'blockchainStatus',
      Header: 'Blockchain',
      accessor: 'blockchainStatus',
      Cell: cell => BlockchainStatus[cell.value] ?? '',
      sortable: false,
      maxWidth: 200,
    });
  }

  function renderTransaction(transaction) {
    return (
      <div className="log-details">
        <div className="row m-b-sm">
          <div className="col-md-3">Rewards</div>
          <div className="col-md-9">{transaction.rewards.length > 0 ? transaction.rewards.map(reward => reward.name).join(',') : '-'}</div>
        </div>
        {
          useBlockchain
          && (
            <>
              <div className="row m-b-sm">
                <div className="col-md-3">Blockchain Status</div>
                <div className="col-md-9">{BlockchainStatus[transaction.blockchainStatus] ?? '-'}</div>
              </div>
              <div className="row m-b-sm">
                <div className="col-md-3">Blockchain Attempt</div>
                <div className="col-md-9">{transaction.blockchainAttempt ? transaction.blockchainAttempt : '-'}</div>
              </div>
              <div className="row m-b-sm">
                <div className="col-md-3">Blockchain Attempted</div>
                <div className="col-md-9">{transaction.blockchainAttemptOn ? date.long(transaction.blockchainAttemptOn) : '-'}</div>
              </div>
            </>
          )
        }
      </div>
    );
  }

  return (
    <List title="Transactions"
      subtitle="Transaction is an instance of player receiving or spending currency and experience points"
      requestList={requestList}
      {...list.paging}
      preferTable={list.preferTable}
      filters={list.filters}
      tableData={list.data}
      tableColumns={columns}
      switchDisplayStyle={switchDisplayStyle}
      switchFilterVisibility={switchFilterVisibility}
      subComponent={row => renderTransaction(row.original)}>
      {list.data.map(transaction => (
        <div key={transaction.id}>
          <div className="gaas-tile">
            <div className="gaas-tile-image">
              <ListImage items={list.data.map(a => a.player)} currentItem={transaction.player}
                className={transaction.player.isDisabled ? 'avatar lg disabled' : undefined} />
            </div>
            <div className="gaas-tile-heading h-img">
              <span className="text-ellipsis">{transaction.player.name}</span>
            </div>
            <div className="gaas-tile-body">
              <div className="m-t-md padder">
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-gift m-r-sm" />
                    <span className="text-underline">Currency</span>
                    {' : '}
                    <span>{transaction.currency}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-gift m-r-sm" />
                    <span className="text-underline">Experience</span>
                    {' : '}
                    <span>{transaction.experience}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Source</span>
                    {' : '}
                    <span>{transaction.game ? transaction.game.name : '-'}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Created</span>
                    {' : '}
                    <span>{date.long(transaction.createdOn)}</span>
                    <br />
                  </div>
                </div>
                {
                  useBlockchain
                  && (
                    <div className="row">
                      <div className="col-xs-12">
                        <i className="glyphicon glyphicon-bitcoin m-r-sm" />
                        <span className="text-underline">Blockchain</span>
                        {' : '}
                        <span>{BlockchainStatus[transaction.blockchainStatus] ?? '-'}</span>
                        <br />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
      ))}
    </List>
  );
};

TransactionList.propTypes = {
  list: ListPropTypes.isRequired,
  requestList: PropTypes.func.isRequired,
  switchDisplayStyle: PropTypes.func.isRequired,
  switchFilterVisibility: PropTypes.func.isRequired,
  useBlockchain: PropTypes.bool,
};

TransactionList.defaultProps = {
  useBlockchain: false,
};

export default connect(
  state => ({
    list: state.transaction.list,
    useBlockchain: selectors.getSettingAsBoolean(state, SettingType.UseBlockchain),
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(TransactionList);
