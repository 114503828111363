import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Conjunction } from '../../utils/enum';
import GameParticipantUnion from './ParticipantUnion';
import GameUnionActions from './UnionActions';
import ConjunctionIndicator from '../../controls/ConjunctionIndicator';

class GameParticipants extends Component {
  removeFromGame = (array, element) => {
    const { onChange } = this.props;

    const index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
      onChange();
    }
  };

  render() {
    const {
      game, onChange, advancedMode, onAdd, onClick,
    } = this.props;

    const onUnionAddClick = (conjunction) => {
      game.participantUnions.push({
        id: new Date().getMilliseconds(),
        participants: [],
        childrenConjunction: Conjunction.And,
      });
      game.participantUnions.forEach((element, index) => {
        game.participantUnions[index].conjunction = conjunction;
      });
      onChange();
    };

    const hasAnyParticipant = () => {
      let result = false;
      game.participantUnions.forEach((union) => {
        if (union.participants.length > 0) result = true;
      });
      return result;
    };

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

    return (
      <React.Fragment>
        <div className={!hasAnyParticipant() || activeParticipant ? 'union-title warning' : 'union-title valid'}>
          <i className="glyphicon glyphicon-screenshot" />
          {advancedMode ? 'Participant Groups' : 'Participants'}{' '}
          <span className="warning">{activeParticipant ? 'Default player profile used!' : (hasAnyParticipant() ? '' : 'All players will participate!')}</span>
        </div>
        <ul className={advancedMode ? 'tree' : 'no-tree'}>
          {!activeParticipant && game.participantUnions.map((union, index) => (
            <li key={`union-${union.id}`}>
              {index > 0 && (
                <ConjunctionIndicator conjunction={union.conjunction}
                  then={false}
                  onChange={
                    !activeParticipant
                      ? (conjunction) => {
                        game.participantUnions[index].conjunction = conjunction;
                        onChange();
                      }
                      : null
                  } />
              )}
              <GameParticipantUnion union={union}
                onChange={onChange}
                onUnionRemove={() => this.removeFromGame(game.participantUnions, union)}
                advancedMode={advancedMode}
                canEdit={!activeParticipant}
                onAdd={onAdd}
                onClick={onClick} />
            </li>
          ))}
        </ul>

        {advancedMode && !activeParticipant && (
            <GameUnionActions data={game.participantUnions}
              conjunction={
                game.participantUnions.length > 0
                  ? game.participantUnions[game.participantUnions.length - 1].conjunction
                  : Conjunction.And
              }
              groupMode
              then={false}
              onSelect={conjunction => onUnionAddClick(conjunction)} />
        )}
      </React.Fragment>
    );
  }
}

GameParticipants.propTypes = {
  game: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onChange: PropTypes.func.isRequired,
  advancedMode: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GameParticipants;
