import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import { actionCreators } from '../../store/modules/Game';
import DataTypeField from '../../controls/DataTypeField';
import { DataType } from '../../utils/enum';
import BackButton from '../../controls/BackButton';
import ImageUpload from '../../controls/ImageUpload';
import ActionEdit from '../action/Edit';
import animate from '../../utils/animate';
import { AnimatePresence } from 'framer-motion';

class GameSimulation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddActionState: false,
      actions: [],
    };

    this.form = React.createRef();
  }

  addAction(action) {
    this.setState({
      actions: [...this.state.actions, action],
    });

    this.setState({ isAddActionState: false });
  }

  removeAction(action) {
    const array = [...this.state.actions];
    const index = array.indexOf(action);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ actions: array });
    }
  }

  render() {
    const {
      onBackClick, onSaveClick, game,
    } = this.props;

    return (
      <React.Fragment>
        <AnimatePresence>
            {!this.state.isAddActionState
              && (
                <animate.game key="main-sim-pose">
                <Formik key="sim-pose" onSubmit={values => onSaveClick(values)}
                  innerRef={this.form}
                  enableReinitialize
                  initialValues={this.state.actions}>
                    {({ values, errors }) => (
                    <Form>
                      <div id="dataForm">
                        <div className="row m-b-sm">
                          <div className="col-md-12">
                            <span className="page-title hex-img">Simulate Game</span>
                            <span className="sub-heading hex-img">Create virtual actions and test the saved game</span>
                            <div className="pageStyle">
                              <BackButton onClick={onBackClick} />
                              <button type="submit" className="active">
                <i className="glyphicon glyphicon-plane" />
                <div className="hover-text">Simulate</div>
              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row p-l-md">
                          <div className="col-md-12">
                            <div className="panel panel-default hex">
                              <div className="octomask">
                <ImageUpload imageIds={[game.imageId]} form={this.form}
                                  single readOnly />
              </div>
                              <div className="panel-heading" />
                              <div className="panel-body hex" id="scroller">
                <div className="col-xs-1" />
                <div className="col-xs-10">
                                  <FieldArray name="actions"
                                    render={() => (
                                      <div className="form-subsection">
                                        <h4>Actions</h4>
                                        <button type="button"
                                          className="glyphbutton"
                                          title="Add"
                                          onClick={
                                            () => this.setState({ isAddActionState: true })
                                          }>
                                          <span className="btn-sm btn-success pull-right link glyphicon glyphicon-plus" />
                                        </button>

                                        {values && values.map((action, index) => (
                                          <div className="row attribute" key={index}
                                            style={{ marginTop: '45px' }}>
                                            <React.Fragment>
                                              <button type="button"
                                                className="glyphbutton"
                                                title="Delete Action"
                                                onClick={() => this.removeAction(action)}
                                                style={{ marginTop: '20px' }}>
                                                <span className="btn-sm btn-danger link glyphicon glyphicon-minus pull-right" />
                                              </button>

                                              <label htmlFor={`actionType.${index}.${action.actionType.name}`}
                                                className="control-label font-bold col-xs-3 blue">
                                                    Action type
                                                <Field id={`actionType.${index}`}
                                                  name={`actionType.${index}.${action.actionType.name}`}
                                                  datatype={DataType.Text}
                                                  value={action.actionType.name}
                                                  className="form-control text-success" />
                                              </label>

                                              <div className="form-group">
                                                {action.fields.map((field, indx) => (
                                                  (field.value
                                                    && (
                                                      <div key={`fieldCtrl.${indx}`}>
                                                        <label htmlFor={`fields.${indx}.${field.name}`}
                                                          className="control-label font-bold col-xs-3">
                                                          {field.name}
                                                          <Field id={`fields.${index}.${field.name}`}
                                                            name={`fields.${index}.${field.name}`}
                                                            component={DataTypeField}
                                                            dataType={field.dataType}
                                                            field={field}
                                                            readOnly
                                                            className="form-control" />
                                                        </label>
                                                      </div>
                                                    )
                                                  )
                                                ))}
                                              </div>
                                            </React.Fragment>
                                          </div>
                                        ))}
                                      </div>
                                    )} />
                                </div>
              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                  </Formik>
                  </animate.game>
              )
            }
          
            {
              this.state.isAddActionState && (
                <animate.gameEditor key="edit-sim-pose">
                <ActionEdit isSimulation
                  onBackClick={() => this.setState({ isAddActionState: false })}
                  onSaveClick={val => this.addAction(val)} />
                            </animate.gameEditor>
              )
            }
        </AnimatePresence>
      </React.Fragment>
    );
  }
}

GameSimulation.propTypes = {
  onBackClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  game: PropTypes.shape({ id: PropTypes.number }).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

GameSimulation.defaultProps = {
  onBackClick: null,
  onSaveClick: null,
  fields: [],
};

export default connect(
  state => ({
    fields: state.game.fields,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(GameSimulation);
