import { DataType } from '../../utils/enum';
import Base from './Base';
import restHttp from '../../utils/restHttp';
import { actionCreators as Alert } from './Alert';

const base = new Base('participant', 'Participant');

const initialState = {
  list: {
    data: [],
    paging: {},
    preferTable: false,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Disabled',
        column: 'IsDisabled',
        type: DataType.Boolean,
        operator: '=',
        value: null,
      },
      ],
    },
  },
  all: [],
  details: {},
  linkableParticipants: []
};

export const actionCreators = {
  ...base.actionCreators,
  requestAllLinkableParticipants: id => dispatch => {
    const reqTypeName = 'linkable'
    dispatch({ type: base.customRequestType(reqTypeName) });

    restHttp
      .get(id ? `${base.name}/${id}/linkable` : `${base.name}/linkable`)
      .then(response => {
        dispatch({
          type: base.customReceiveType(reqTypeName),
          participants: response.data,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestPlayerCount: (entity, callback) => dispatch => {
    const reqTypeName = 'playerCount'
    dispatch({ type: base.customRequestType(reqTypeName) });

    restHttp
      .put(`${base.name}/player/count`, entity)
      .then(response => {
        dispatch({
          type: base.customReceiveType(reqTypeName),
          playerCount: response.data,
        });
        if (callback) callback(response.data);
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (action.type === base.customReceiveType('linkable')) {
    return {
      ...newState,
      linkableParticipants: action.participants,
    };
  }

  return base.reducer(newState, action);
};
