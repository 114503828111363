import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators } from '../../store/modules/Player';
import List, { ListPropTypes } from '../../containers/List';
import ListImage from '../../containers/ListImage';

const PlayerPlayerList = props => {
  const {
    id, list, requestPlayerList, switchPlayerDisplayStyle, switchPlayerFilterVisibility,
  } = props;

  const columns = [
    {
      id: 'imageId',
      Header: '',
      Cell: row => (
        <ListImage imageSize={32} className="table-image"
          items={list.data.map(pg => pg.player)} currentItem={row.original.player} />
      ),
      sortable: false,
      maxWidth: 40,
    },
    {
      id: 'player.name',
      Header: 'Player',
      accessor: 'player.name',
      sortable: true,
    },
    {
      id: 'player.description',
      Header: 'Description',
      accessor: 'player.description',
      sortable: true,
    },
    {
      id: 'group.name',
      Header: 'Group',
      accessor: 'group.name',
      sortable: true,
    },
    {
      accessor: 'player.id',
      Cell: cell => (
        <LinkContainer to={`/player/${cell.value}`} exact>
          <div className="gaas-tile-btn">See More</div>
        </LinkContainer>
      ),
      maxWidth: 150,
    },
  ];
  return (
    <List title="Connections"
      subtitle="Connection is another player who has been put in one of player's private groups"
      requestList={(pageIndex, pageSize, sortBy, sortDescending, filters) => requestPlayerList(id, pageIndex, pageSize, sortBy, sortDescending, filters)}
      {...list.paging}
      preferTable={list.preferTable}
      filters={list.filters}
      tableData={list.data}
      tableColumns={columns}
      switchDisplayStyle={switchPlayerDisplayStyle}
      switchFilterVisibility={switchPlayerFilterVisibility}>
      {list.data.map(pg => (
        <div key={`${pg.player.id} ${pg.group.id}`}>
          <div className="gaas-tile">
            <div className="gaas-tile-image">
              <ListImage items={list.data.map(r => r.player)} currentItem={pg.player}
                className={pg.player.isDisabled ? 'avatar lg disabled' : undefined} />
            </div>
            <div className="gaas-tile-heading h-img">
              <span className="text-ellipsis">{pg.player.name}</span>
            </div>
            <div className="gaas-tile-body">
              <div className="m-t-md m-b-md padder">
                <div className="row">
                  <div className="col-xs-12">
                    <span className="text-underline">Source</span>
                    {' : '}
                    <span>{pg.group.name}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="gaas-tile-footer m-t-sm" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
              <LinkContainer to={`/player/${pg.player.id}`} exact>
                <div className="gaas-tile-btn">See More</div>
              </LinkContainer>
            </div>
            <div className="clear" />
          </div>
        </div>
      ))}
    </List>
  );
};

PlayerPlayerList.propTypes = {
  id: PropTypes.number.isRequired,
  list: ListPropTypes.isRequired,
  requestPlayerList: PropTypes.func.isRequired,
  switchPlayerDisplayStyle: PropTypes.func.isRequired,
  switchPlayerFilterVisibility: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    list: state.player.playerList,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(PlayerPlayerList);
