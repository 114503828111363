import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators } from '../../store/modules/Tenant';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import SettingsTemplate, { SettingsValidationRules } from '../../templates/Settings';
import { PlayerSource, ConvertToOptions, YesNo } from '../../utils/enum';
import SelectField, { Convert } from '../../controls/SelectField';
import helper from '../../utils/helper';

class TenantEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {
        name: '',
        apiKey: '',
        playerSource: '',
        timeZone: 'UTC',
        imageId: '',
        settings: [],
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { id, requestGet, requestTimezoneList } = this.props;

    if (id) {
      requestGet(id);
    } else {
      requestTimezoneList();
    }
  }

  handleSave(values) {
    const { id, requestPost, requestPut } = this.props;

    if (id) {
      requestPut(id, values);
    } else {
      requestPost(values);
    }
  }

  handleStatus(enabled) {
    const { id, requestEnable, requestDisable } = this.props;

    if (enabled) {
      requestEnable(id);
    } else {
      requestDisable(id);
    }
  }

  render() {
    const { id, tenant, timezones } = this.props;
    const { template } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(50)
            .required(),
          playerSource: Yup.number().nullable(),
          timeZone: Yup.string().required(),
          imageId: Yup.string().nullable(),
          settings: SettingsValidationRules(id && tenant && tenant.id === id ? tenant.settings : null),
          blockchainEmail: Yup.string()
            .email()
            .max(50)
            .nullable(),
        })}
        initialValues={tenant || template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">{id ? 'Edit Tenant' : 'New Tenant'}</span>
                  <span className="sub-heading hex-img">Tenant is a GZone client</span>
                  <div className="pageStyle">
                    <BackButton />
                    {id && tenant && (
                      <LinkContainer to={`/tenant/${tenant.id}/maintain`} exact>
                        <button type="button" className="delete-btn">
                          <i className="glyphicon glyphicon-wrench" />
                          <div className="hover-text">Maintain</div>
                        </button>
                      </LinkContainer>
                    )}
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} form={this.form}
                        single />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="name" className="control-label font-bold">
                                Name
                                <Field id="name"
                                  name="name"
                                  autoFocus
                                  className={errors.name ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          {!id && (
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="playerSource" className="control-label font-bold">
                                  Player Source
                                  <Field id="playerSource"
                                    name="playerSource"
                                    component={SelectField}
                                    options={ConvertToOptions(PlayerSource)}
                                    isClearable
                                    className={errors.playerSource ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                        {!id && (
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="timeZone" className="control-label font-bold">
                                  Timezone
                                  <Field id="timeZone"
                                    name="timeZone"
                                    component={SelectField}
                                    options={Convert(timezones)}
                                    className={errors.timeZone ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {id && (
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="apiKey" className="control-label font-bold">
                                  API Key
                                  <Field id="apiKey"
                                    name="apiKey"
                                    readOnly
                                    className={errors.apiKey ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="form-subsection">
                          <h4>Blockchain</h4>
                          <div className="row">
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="chainNetwork" className="control-label font-bold">
                                  Network URL
                                  <Field id="blockchainNetwork"
                                    name="blockchainNetwork"
                                    value={values.blockchainNetwork || ''}
                                    className={errors.blockchainNetwork ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="blockchainContractAddress" className="control-label font-bold">
                                  Smart Contract Address
                                  <Field id="blockchainContractAddress"
                                    name="blockchainContractAddress"
                                    value={values.blockchainContractAddress || ''}
                                    className={errors.blockchainContractAddress ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-8 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="marketPlaceOwnerKey" className="control-label font-bold">
                                  Marketplace Owner Private Key
                                  <Field id="marketPlaceOwnerKey"
                                    name="marketPlaceOwnerKey"
                                    value={values.marketPlaceOwnerKey || ''}
                                    placeholder={id && tenant && tenant.blockchainNetwork && tenant.blockchainNetwork === values.blockchainNetwork && tenant.blockchainContractAddress === values.blockchainContractAddress ? 'Leave empty to skip' : ''}
                                    className={errors.marketPlaceOwnerKey ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                            <div className="col-md-4 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="blockchainEmail" className="control-label font-bold">
                                  Administrative Email
                                  <Field id="blockchainEmail"
                                    name="blockchainEmail"
                                    value={values.blockchainEmail || ''}
                                    className={errors.blockchainEmail ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {id && (
                          <div className="form-subsection">
                            <h4>Settings</h4>
                            <div className="row">
                              <SettingsTemplate settings={values.settings} errors={errors} />
                            </div>
                          </div>
                        )}
                      </div>
                      {id
                        && tenant && (
                        <div className="col-md-3 form-additional-info">
                          <label>
                            Created
                            <div>{date.long(tenant.createdOn)}</div>
                          </label>
                          <label>
                            Player Source
                            <div>{tenant.playerSource ? PlayerSource[tenant.playerSource] : 'None'}</div>
                          </label>
                          <label>
                            Timezone
                            <div>{tenant.timeZone}</div>
                          </label>
                          {
                            tenant.blockchainMarketplaceOwnerAddress && (
                              <label>
                                Blockchain owner address
                                <div>{tenant.blockchainMarketplaceOwnerAddress}</div>
                              </label>
                            )
                          }
                          {
                            tenant.blockchainMarketplaceOwnerAddress && (
                              <label>
                                Blockchain owner balance
                                <div>{tenant.blockchainMarketplaceOwnerBalance}</div>
                              </label>
                            )
                          }
                          <label htmlFor="status-switch">
                            Disabled
                            <div>
                              <Switch id="status-switch"
                                onChange={() => this.handleStatus(tenant.isDisabled)}
                                checked={tenant.isDisabled}
                                checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                            </div>
                          </label>
                          <label>
                            Is Processing
                            <div>{YesNo[tenant.isProcessing]}</div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

TenantEdit.propTypes = {
  id: PropTypes.number,
  tenant: PropTypes.shape({
    id: PropTypes.number, name: PropTypes.string, isDisabled: PropTypes.bool, isProcessing: PropTypes.bool,
  }),
  timezones: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })).isRequired,
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  requestTimezoneList: PropTypes.func.isRequired,
};

TenantEdit.defaultProps = {
  id: null,
  tenant: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    tenant: state.tenant.details[helper.getIdFromProps(ownProps)],
    timezones: state.tenant.timezones,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(TenantEdit);
