import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { actionCreators } from '../../store/modules/Participant';
import { actionCreators as groupActionCreators } from '../../store/modules/Group';
import { actionCreators as gameActionCreators } from '../../store/modules/Game';
import { actionCreators as levelActionCreators } from '../../store/modules/Level';
import { actionCreators as attributeActionCreators } from '../../store/modules/PlayerAttribute';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import DeleteButton from '../../controls/DeleteButton';
import SelectField, { Convert } from '../../controls/SelectField';
import DataTypeField from '../../controls/DataTypeField';
import OperatorField from '../../controls/OperatorField';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import CopyButton from '../../controls/CopyButton';
import helper from '../../utils/helper';

class ParticipantEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: 'attributes',
      playerCount: null,
      template: {
        name: '',
        description: '',
        groups: [],
        games: [],
        levels: [],
        attributes: [],
        imageId: '',
        participants: [],
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const {
      id, requestGet, requestAllGroups, requestAllGames, requestAllLevels, requestAllAttributes, requestAllLinkableParticipants
    } = this.props;

    if (id) {
      requestGet(id);
    }
    requestAllGroups();
    requestAllGames();
    requestAllLevels();
    requestAllAttributes();
    requestAllLinkableParticipants(id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { id, participant } = this.props;

    if (id && nextProps.participant && nextProps.participant !== participant) {
      if (nextProps.participant.groups.length > 0)
        this.setState({section: 'groups'});
      else if (nextProps.participant.levels.length > 0)
        this.setState({section: 'levels'});
      else if (nextProps.participant.games.length > 0)
        this.setState({section: 'games'});
      else if (nextProps.participant.participants.length > 0)
        this.setState({section: 'participants'});
    }
  }

  handleSave(values) {
    const {
      id, copy, gameMode, requestPost, requestPut,
    } = this.props;

    if (id && !copy) {
      requestPut(id, values, !gameMode);
    } else {
      requestPost(values, !gameMode);
    }
  }

  handleStatus(enabled) {
    const { id, requestEnable, requestDisable } = this.props;

    if (enabled) {
      requestEnable(id);
    } else {
      requestDisable(id);
    }
  }

  refreshPlayerCount(values) {
    const { requestPlayerCount } = this.props;

    requestPlayerCount({...values, name: 'tmp'}, playerCount => this.setState({ playerCount }));
  }

  renderAttribute = (values, errors, index, arrayHelpers) => {
    const { attributes } = this.props;

    const attribute = attributes.find(attr => attr.id === values.attributes[index].attributeId);

    return (
      <div className="row attribute" key={index}>
        <button type="button" className="glyphbutton"
          title="Delete Filter" onClick={() => arrayHelpers.remove(index)}>
          <span className="btn-sm btn-danger link glyphicon glyphicon-minus pull-right" />
        </button>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`attributes.${index}.attributeId`} className="control-label font-bold">
              Attribute
              <Field id={`attributes.${index}.attributeId`}
                name={`attributes.${index}.attributeId`}
                component={SelectField}
                options={Convert(attributes)}
                onChange={form => {
                  form.setFieldValue(`attributes.${index}.operator`, null);
                  form.setFieldValue(`attributes.${index}.value`, '');
                }}
                className={
                  errors.attributes && errors.attributes[index] && errors.attributes[index].attributeId
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`attributes.${index}.operator`} className="control-label font-bold">
              Operator
              <Field id={`attributes.${index}.operator`}
                name={`attributes.${index}.operator`}
                component={OperatorField}
                dataType={attribute ? attribute.dataType : null}
                className={
                  errors.attributes && errors.attributes[index] && errors.attributes[index].operator
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`attributes.${index}.value`} className="control-label font-bold">
              Value
              <Field id={`attributes.${index}.value`}
                name={`attributes.${index}.value`}
                component={DataTypeField}
                dataType={attribute ? attribute.dataType : null}
                operator={values.attributes[index].operator}
                className={
                  errors.attributes && errors.attributes[index] && errors.attributes[index].value
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      id, copy, gameMode, participant, requestDelete, groups, participants, games, levels, onBackClick,
    } = this.props;
    const { template, section, playerCount } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(50)
            .required(),
          description: Yup.string().nullable(),
          groups: Yup.array().of(
            Yup.object().shape({
              id: Yup.number()
                .integer()
                .required(),
            }),
          ),
          participants: Yup.array().of(
            Yup.object().shape({
              id: Yup.number()
                .integer()
                .required(),
            }),
          ),
          games: Yup.array().of(
            Yup.object().shape({
              id: Yup.number()
                .integer()
                .required(),
            }),
          ),
          levels: Yup.array().of(
            Yup.object().shape({
              id: Yup.number()
                .integer()
                .required(),
            }),
          ),
          attributes: Yup.array().of(
            Yup.object().shape({
              attributeId: Yup.number()
                .integer()
                .required(),
              operator: Yup.number()
                .integer()
                .required(),
              value: Yup.string().required(),
            }),
          ),
          imageId: Yup.string().nullable(),
        })}
        initialValues={participant || template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">
                    {id && !copy && 'Edit Profile'}
                    {id && copy && 'Copy Profile'}
                    {!id && 'New Profile'}
                  </span>{' '}
                  <span className="sub-heading hex-img">
                    Profile is a filtered group of players
                  </span>
                  <div className="pageStyle">
                    <BackButton onClick={onBackClick} />
                    {id && !copy && !gameMode && <CopyButton to={`${id}/copy`} />}
                    {id && !copy && !gameMode && <DeleteButton onClick={() => requestDelete(id)} />}
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} form={this.form}
                        single />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="name" className="control-label font-bold">
                                Name
                                <Field id="name"
                                  name="name"
                                  autoFocus
                                  className={errors.name ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="description" className="control-label font-bold">
                                Description
                                <Field id="description"
                                  name="description"
                                  value={values.description || ''}
                                  className={errors.description ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="profile-selector">
                          <div className={section === 'all' ? 'active' : ''}>
                              <button type="button" title="All"
                                className="btn-profile"
                                onClick={() => this.setState({section: 'all'})}>
                                All {values.attributes.length + values.groups.length + values.levels.length + values.games.length + values.participants.length}
                              </button>
                          </div>
                          <div className={section === 'attributes' ? 'active' : ''}>
                              <button type="button" title="Player Attributes"
                                className="btn-profile"
                                onClick={() => this.setState({section: 'attributes'})}>
                                Attributes {values.attributes.length}
                              </button>
                          </div>
                          <div className={section === 'groups' ? 'active' : ''}>
                              <button type="button" title="Player Groups"
                                className="btn-profile"
                                onClick={() => this.setState({section: 'groups'})}>
                                Groups {values.groups.length}
                              </button>
                          </div>
                          <div className={section === 'levels' ? 'active' : ''}>
                              <button type="button" title="Player Levels"
                                className="btn-profile"
                                onClick={() => this.setState({section: 'levels'})}>
                                Levels {values.levels.length}
                              </button>
                          </div>
                          <div className={section === 'games' ? 'active' : ''}>
                              <button type="button" title="Player Games"
                                className="btn-profile"
                                onClick={() => this.setState({section: 'games'})}>
                                Games {values.games.length}
                              </button>
                          </div>
                          <div className={section === 'participants' ? 'active' : ''}>
                              <button type="button" title="Player Profiles"
                                className="btn-profile"
                                onClick={() => this.setState({section: 'participants'})}>
                                Profiles {values.participants.length}
                              </button>
                          </div>
                        </div>
                        {
                          (section === 'participants' || section === 'all') &&
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group form-group-lg">
                                <h4>Filter by Player Profiles</h4>
                                <label htmlFor="participants" className="control-label font-bold">
                                  Player must be a member of ALL profiles to qualify
                                  <Field id="participants"
                                    name="participants"
                                    component={SelectField}
                                    options={Convert(participants)}
                                    isMulti
                                    className={errors.participants ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          (section === 'groups' || section === 'all') &&
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group form-group-lg">
                                <h4>Filter by Player Groups</h4>
                                <label htmlFor="groups" className="control-label font-bold">
                                  Player must be a member of ALL groups to qualify
                                  <Field id="groups"
                                    name="groups"
                                    component={SelectField}
                                    options={Convert(groups)}
                                    isMulti
                                    className={errors.groups ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          (section === 'levels' || section === 'all') &&
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group form-group-lg">
                                <h4>Filter by Player Levels</h4>
                                <label htmlFor="levels" className="control-label font-bold">
                                  Player must have reached ANY level to qualify
                                  <Field id="levels"
                                    name="levels"
                                    component={SelectField}
                                    options={Convert(levels)}
                                    isMulti
                                    className={errors.levels ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          (section === 'games' || section === 'all') &&
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group form-group-lg">
                                <h4>Filter by Player Games</h4>
                                <label htmlFor="games" className="control-label font-bold">
                                  Player must have completed ALL games to qualify
                                  <Field id="games"
                                    name="games"
                                    component={SelectField}
                                    options={Convert(games)}
                                    isMulti
                                    className={errors.games ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          (section === 'attributes' || section === 'all') &&
                          <FieldArray name="attributes"
                            render={arrayHelpers => (
                              <div className="form-subsection">
                                <h4>Filter by Player Attributes</h4>
                                <button type="button"
                                  className="glyphbutton"
                                  title="Add"
                                  onClick={() => arrayHelpers.push({ attributeId: '', operator: null, value: '' })}>
                                  <span className="btn-sm btn-success  pull-right link glyphicon glyphicon-plus" />
                                </button>
                                <div className="form-group form-group-lg">
                                  <label htmlFor="participants" className="control-label font-bold">
                                    Player must match all attribute rules to qualify
                                  </label>
                                </div>
                                {values.attributes
                                  && values.attributes.map((obj, index) => this.renderAttribute(values, errors, index, arrayHelpers))}
                              </div>
                            )} />
                        }
                      </div>
                      <div className="col-md-3 form-additional-info">
                            <label>
                              Players matched
                              <button type="button"
                                className="refresh-button"
                                onClick={() => this.refreshPlayerCount(values)}>
                                <i className="glyphicon glyphicon-refresh" />
                              </button>
                              <div>{playerCount ?? '-'}</div>
                            </label>
                            {
                              !copy && participant &&                             
                              <label>
                                Created
                                <div>{date.long(participant.createdOn)}</div>
                              </label>
                            }
                            {
                              !copy && participant && 
                              <label htmlFor="status-switch">
                                Disabled
                                <div>
                                  <Switch id="status-switch"
                                    onChange={() => this.handleStatus(participant.isDisabled)}
                                    checked={participant.isDisabled}
                                    checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                    unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                                </div>
                              </label>
                            }
                          </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

ParticipantEdit.propTypes = {
  id: PropTypes.number,
  copy: PropTypes.bool,
  gameMode: PropTypes.bool,
  participant: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
  requestAllGroups: PropTypes.func.isRequired,
  requestAllAttributes: PropTypes.func.isRequired,
  requestAllLinkableParticipants: PropTypes.func.isRequired,
  requestPlayerCount: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  onBackClick: PropTypes.func,
};

ParticipantEdit.defaultProps = {
  id: null,
  copy: false,
  gameMode: false,
  participant: null,
  onBackClick: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    copy: helper.getCopyFromProps(ownProps),
    participant: helper.getEntityById(state.participant.details, ownProps),
    groups: state.group.all,
    participants: state.participant.linkableParticipants,
    games: state.game.all.filter(game => game.publishedOn),
    levels: state.level.all,
    attributes: state.playerAttribute.all,
  }),
  dispatch => bindActionCreators(
    {
      ...actionCreators,
      requestAllGroups: groupActionCreators.requestAll,
      requestAllGames: gameActionCreators.requestAll,
      requestAllLevels: levelActionCreators.requestAll,
      requestAllAttributes: attributeActionCreators.requestAll
    },
    dispatch,
  ),
)(ParticipantEdit);
