import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { actionCreators } from '../../store/modules/Tenant';
import { actionCreators as playerActionCreators } from '../../store/modules/Player';
import ImageUpload from '../../controls/ImageUpload';
import BackButton from '../../controls/BackButton';
import confirm, { NoCancel } from '../../utils/confirm';
import SelectField from '../../controls/SelectField';
import helper from '../../utils/helper';

class TenantMaintain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {
        mode: '',
        playerNames: '',
      },
      results: [],
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { id, requestGet } = this.props;

    if (id) {
      requestGet(id);
    }
  }

  handleDeletePlayers(playerNames) {
    const { id, requestDeletePlayers } = this.props;

    const callback = results => this.setState({ results });

    confirm('Do you want to completely delete specified players? All data related to players will be deleted.').then(() => {
      if (id) {
        requestDeletePlayers(id, playerNames.split('\n').filter(x => x), callback);
      }
    }, NoCancel);
  }

  handleResetPlayers(playerNames) {
    const { id, requestResetPlayers } = this.props;

    const callback = results => this.setState({ results });

    confirm('Do you want to reset player progress for specified players? Rounds, experience, currency, actions and notifications will be deleted.').then(() => {
      if (id) {
        requestResetPlayers(id, playerNames.split('\n').filter(x => x), callback);
      }
    }, NoCancel);
  }

  handleReprocessActions() {
    const { id, requestReprocess } = this.props;

    confirm('Do you want to delete game progress related data and reprocess all actions? Games with periods will result in only current period rounds being created.').then(() => {
      if (id) {
        requestReprocess(id);
      }
    }, NoCancel);
  }

  handleReprocessBlockchain() {
    const { id, requestReprocessBlockchain } = this.props;

    const finalStep = addNonBlockchainTransaction => {
      confirm('Are you sure to continue with blockchain reprocess?').then(
        () => requestReprocessBlockchain(id, addNonBlockchainTransaction),
        NoCancel,
      );
    };

    const secondStep = () => {
      confirm('Do you want to migrate non blockchain transactions to blockchain if any? That will add blockchain currency to player wallets for progress before blockchain was set up.', { confirmLabel: 'Yes', cancelLabel: 'No' }).then(
        () => finalStep(true),
        () => finalStep(false),
      );
    };

    confirm(
      'Do you want to retry failed blockchain transactions? That will try to add blockchain currency to player wallets again.',
      { confirmLabel: 'Yes', cancelLabel: 'No' },
    ).then(() => secondStep(), NoCancel);
  }

  handleDelete() {
    const { id, requestDelete, tenant } = this.props;

    if (!tenant.isDisabled) {

    }

    const finalStep = (deletePlayers, deletePlayerSetup, deleteGameSetup, deleteTenant) => {
      confirm('Are you sure to continue with tenant cleanup?').then(
        () => requestDelete(id, true, deletePlayers, deletePlayerSetup, deleteGameSetup, deleteTenant),
        NoCancel,
      );
    };

    const fifthStep = (deletePlayers, deletePlayerSetup, deleteGameSetup) => {
      if (deletePlayerSetup && deleteGameSetup) {
        confirm('Do you want to delete tenant itself?', { confirmLabel: 'Yes', cancelLabel: 'No' }).then(
          () => finalStep(deletePlayers, deletePlayerSetup, deleteGameSetup, true),
          () => finalStep(deletePlayers, deletePlayerSetup, deleteGameSetup, false),
        );
      } else {
        finalStep(deletePlayers, deletePlayerSetup, deleteGameSetup, false);
      }
    };

    const forthStep = (deletePlayers, deletePlayerSetup) => {
      if (deletePlayerSetup) {
        confirm('Do you want to delete game setup related data? That includes games, filters, rules, profiles, rewards.', {
          confirmLabel: 'Yes',
          cancelLabel: 'No',
        }).then(
          () => fifthStep(deletePlayers, deletePlayerSetup, true),
          () => fifthStep(deletePlayers, deletePlayerSetup, false),
        );
      } else {
        fifthStep(deletePlayers, deletePlayerSetup, false);
      }
    };

    const thirdStep = deletePlayers => {
      if (deletePlayers) {
        confirm('Do you want to delete player setup related data? That includes levels, attributes, groups.', {
          confirmLabel: 'Yes',
          cancelLabel: 'No',
        }).then(() => forthStep(deletePlayers, true), () => forthStep(deletePlayers, false));
      } else {
        forthStep(deletePlayers, false);
      }
    };

    const secondStep = () => {
      confirm('Do you want to delete players?', { confirmLabel: 'Yes', cancelLabel: 'No' }).then(
        () => thirdStep(true),
        () => thirdStep(false),
      );
    };

    const firstStep = () => {
      confirm(
        'Do you want to delete game progress related data? That includes game rounds, actions, player experience and currency.',
        { confirmLabel: 'Yes', cancelLabel: 'No' },
      ).then(() => secondStep(), NoCancel);
    };

    firstStep();
  }

  handleSave(values) {
    this.setState({ results: [] });

    if (values.mode === 'general') {
      this.handleDelete();
    } else if (values.mode === 'action-reprocess') {
      this.handleReprocessActions();
    } else if (values.mode === 'player-reset') {
      this.handleResetPlayers(values.playerNames);
    } else if (values.mode === 'player-delete') {
      this.handleDeletePlayers(values.playerNames);
    } else if (values.mode === 'blockchain-reprocess') {
      this.handleReprocessBlockchain();
    }
  }

  testPlayerNames(playerNames) {
    return ((this.parent.mode === 'player-reset' || this.parent.mode === 'player-delete') && playerNames)
      || (this.parent.mode !== 'player-reset' && this.parent.mode !== 'player-delete');
  }

  render() {
    const { id, tenant } = this.props;
    const { template, results } = this.state;

    const modes = [
      { value: 'general', label: 'General tenant cleanup...' },
      { value: 'action-reprocess', label: 'Reprocess tenant actions...', isDisabled: true },
      { value: 'player-reset', label: 'Reset tenant players...' },
      { value: 'player-delete', label: 'Delete tenant players...' },
    ];

    if (tenant && tenant.blockchainMarketplaceOwnerAddress) {
      modes.push({ value: 'blockchain-reprocess', label: 'Reprocess failed blockchain transactions' });
    }

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          mode: Yup.string()
            .max(50)
            .required(),
          playerNames: Yup.string().nullable().test('playerNames', 'Player names required for this mode', this.testPlayerNames),
        })}
        initialValues={template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">Tenant Maintenance - {tenant.name}</span>
                  <span className="sub-heading hex-img">Remove some or all data from tenant</span>
                  <div className="pageStyle">
                    <BackButton />
                    {id && tenant && (
                      <button type="submit" className="delete-btn">
                        <i className="glyphicon glyphicon-floppy-remove" />
                        <div className="hover-text">Delete</div>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[tenant.imageId]} readOnly
                        single />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="mode" className="control-label font-bold">
                                Action
                                <Field id="mode"
                                  name="mode"
                                  component={SelectField}
                                  options={modes}
                                  onChange={() => {
                                    this.setState({ results: [] });
                                  }}
                                  className={errors.mode ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        {
                          (values.mode === 'player-reset' || values.mode === 'player-delete')
                          && (
                            <div className="row">
                              <div className="col-md-6 col-xs-12">
                                <div className="form-group form-group-lg">
                                  <label htmlFor="playerNames" className="control-label font-bold">
                                    Player Names
                                    <Field id="playerNames"
                                      type="textarea"
                                      name="playerNames"
                                      component="textarea"
                                      rows="4"
                                      className={errors.playerNames ? 'form-control error' : 'form-control'} />
                                  </label>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        {results.length > 0 && (
                          <div className="form-subsection">
                            <h4>Errors</h4>
                            <br />
                            <div className="row">
                              <div className="col-xs-12">
                                <table className="table table-sm">
                                  <thead>
                                    <tr>
                                      <th>Player Name</th>
                                      <th scope="col">Error</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      results.map(x => <tr key={x.name}><td>{x.name}</td><td>{x.error}</td></tr>)
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

TenantMaintain.propTypes = {
  id: PropTypes.number,
  tenant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isDisabled: PropTypes.bool,
    imageId: PropTypes.string,
    blockchainMarketplaceOwnerAddress: PropTypes.string,
  }),
  requestGet: PropTypes.func.isRequired,
  requestReprocess: PropTypes.func.isRequired,
  requestReprocessBlockchain: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
  requestResetPlayers: PropTypes.func.isRequired,
  requestDeletePlayers: PropTypes.func.isRequired,
};

TenantMaintain.defaultProps = {
  id: null,
  tenant: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    tenant: state.tenant.details[helper.getIdFromProps(ownProps)],
    timezones: state.tenant.timezones,
  }),
  dispatch => bindActionCreators(
    {
      ...actionCreators,
      requestResetPlayers: playerActionCreators.requestResetMany,
      requestDeletePlayers: playerActionCreators.requestDeleteMany,
    },
    dispatch,
  ),
)(TenantMaintain);
