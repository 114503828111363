import React from 'react';
import PropTypes from 'prop-types';
import { Conjunction } from '../../utils/enum';

const GameUnionActions = props => {
  const {
    data, onSelect, conjunction, groupMode, then, or, onCopy,
  } = props;

  const dataCount = data.length;

  return (
    <ul className={`tree subtree ${groupMode ? 'subgroup' : ''}`}>
      {dataCount === 0 && (
        <li>
          <button type="button" className="rule-new"
            onClick={() => onSelect(Conjunction.And)}>
            {groupMode ? 'Add Group...' : 'Add...'}
          </button>
        </li>
      )}
      {dataCount === 0 && onCopy && (
        <li>
          <button type="button" className="rule-new"
            onClick={() => onCopy()}>
            {groupMode ? 'Copy Group...' : 'Copy...'}
          </button>
        </li>
      )}
      {(dataCount === 1 || (dataCount > 1 && conjunction === Conjunction.And)) && (
        <li>
          <button type="button" className="rule-new"
            onClick={() => onSelect(Conjunction.And)}>
            {groupMode ? 'And Group...' : 'And...'}
          </button>
        </li>
      )}
      {or
        && (dataCount === 1 || (dataCount > 1 && conjunction === Conjunction.Or)) && (
        <li>
          <button type="button" className="rule-new"
            onClick={() => onSelect(Conjunction.Or)}>
            {groupMode ? 'Or Group...' : 'Or...'}
          </button>
        </li>
      )}
      {then
        && (dataCount === 1 || (dataCount > 1 && conjunction === Conjunction.Then)) && (
        <li>
          <button type="button" className="rule-new"
            onClick={() => onSelect(Conjunction.Then)}>
            {groupMode ? 'Then Group...' : 'Then...'}
          </button>
        </li>
      )}
    </ul>
  );
};

GameUnionActions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  onSelect: PropTypes.func.isRequired,
  conjunction: PropTypes.number.isRequired,
  groupMode: PropTypes.bool,
  then: PropTypes.bool,
  or: PropTypes.bool,
  onCopy: PropTypes.func,
};

GameUnionActions.defaultProps = {
  groupMode: false,
  then: true,
  or: true,
  onCopy: null,
};

export default GameUnionActions;
