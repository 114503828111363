import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { actionCreators as ruleActionCreators } from '../../store/modules/Rule';
import ImageUpload from '../../controls/ImageUpload';
import SelectField from '../../controls/SelectField';
import DeleteButton from '../../controls/DeleteButton';
import DataTypeField from '../../controls/DataTypeField';
import OperatorField from '../../controls/OperatorField';
import {
  Aggregate, ConvertToOptions, DataType, NotificationPeriod, NotificationHours, NotificationType,
} from '../../utils/enum';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';

class GameRuleNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      template: {
        type: props.type,
        name: '',
        title: '',
        description: '',
        maxOccurrences: 1,
        period: '',
        hourFrom: '',
        hourTo: '',
        conditions: [],
        imageId: '',
        videoId: '',
        isDisabled: false,
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const {
      game, requestGetRule, notification, type,
    } = this.props;

    if (type === 3 || (notification && notification.type === 3)) {
      const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

      const ruleIds = [];
      game.ruleUnions.filter(x => (!activeParticipant && !x.participantId) || (activeParticipant && x.participantId === activeParticipant.participant.id)).forEach(ruleUnion => {
        ruleUnion.rules.forEach(x => {
          if (ruleIds.indexOf(x.id) < 0) { ruleIds.push(x.id); }
        });
      });

      ruleIds.forEach(id => requestGetRule(id));
    }
  }

  onSave = values => {
    const { notification, game, onSaveClick } = this.props;

    if (notification) {
      const index = game.gameNotifications.indexOf(notification);
      if (index !== -1) {
        game.gameNotifications[index] = values;
      }
    } else {
      const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;
      values.participantId = activeParticipant ? activeParticipant.participant.id : null;
      game.gameNotifications.push(values);
    }
    onSaveClick();
  }

  onDelete = () => {
    const { notification, game, onDeleteClick } = this.props;

    const index = game.gameNotifications.indexOf(notification);
    if (index !== -1) {
      game.gameNotifications.splice(index, 1);
    }
    onDeleteClick();
  }

  renderRule = (values, errors, index, arrayHelpers) => {
    const { ruleDetails, game } = this.props;

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

    const unionRules = [];
    game.ruleUnions.filter(x => (!activeParticipant && !x.participantId) || (activeParticipant && x.participantId === activeParticipant.participant.id)).forEach(ruleUnion => {
      ruleUnion.rules.forEach(x => unionRules.push({ label: x.name, value: x.unionEntityId, ruleId: x.id }));
    });

    const ruleConditions = [];
    if (values.conditions[index].unionRuleId) {
      game.ruleUnions.filter(x => (!activeParticipant && !x.participantId) || (activeParticipant && x.participantId === activeParticipant.participant.id)).forEach(ruleUnion => {
        ruleUnion.rules.forEach(x => {
          if (x.unionEntityId === values.conditions[index].unionRuleId) {
            const rule = ruleDetails[x.id];
            if (rule) {
              rule.conditions.forEach(ruleCondition => {
                ruleConditions.push({ label: `${Aggregate[ruleCondition.aggregate]} of ${ruleCondition.actionTypeFieldName}`, value: ruleCondition.id });
              });
            }
          }
        });
      });
    }

    return (
      <div className="row attribute" key={index}>
        {index > 0 && <span className="and-label">and</span>}
        <button type="button" className="glyphbutton"
          title="Delete Condition" onClick={() => arrayHelpers.remove(index)}>
          <span className="btn-sm btn-danger link glyphicon glyphicon-minus pull-right" />
        </button>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.unionRuleId`} className="control-label font-bold">
              Rule
              <Field id={`conditions.${index}.unionRuleId`}
                name={`conditions.${index}.unionRuleId`}
                component={SelectField}
                options={unionRules}
                onChange={form => {
                  form.setFieldValue(`conditions.${index}.ruleActionTypeFieldId`, '');
                }}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].unionRuleId
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.ruleActionTypeFieldId`} className="control-label font-bold">
              Rule Condition
              <Field id={`conditions.${index}.ruleActionTypeFieldId`}
                name={`conditions.${index}.ruleActionTypeFieldId`}
                component={SelectField}
                options={ruleConditions}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].ruleActionTypeFieldId
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.operator`} className="control-label font-bold">
              Operator
              <Field id={`conditions.${index}.operator`}
                name={`conditions.${index}.operator`}
                component={OperatorField}
                dataType={DataType.Number}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].operator
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.value`} className="control-label font-bold">
              Value
              <Field id={`conditions.${index}.value`}
                name={`conditions.${index}.value`}
                component={DataTypeField}
                dataType={DataType.Number}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].value
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
      </div>
    );
  };

  testPeriod(period) {
    return this.parent.maxOccurrences && ((this.parent.maxOccurrences > 1 && period) || (this.parent.maxOccurrences <= 1 && !period));
  }

  testTimingTo(hourTo) {
    return (!this.parent.hourFrom && !hourTo) || (this.parent.hourFrom && hourTo && this.parent.hourFrom !== hourTo);
  }

  render() {
    const {
      notification, onBackClick, type,
    } = this.props;
    const { template } = this.state;

    const maxOccurrencesTimes = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
    ];

    return (
      <Formik onSubmit={values => this.onSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          type: Yup.number().required(),
          name: Yup.string()
            .max(50)
            .required(),
          title: Yup.string()
            .max(50)
            .required(),
          description: Yup.string().required(),
          hourFrom: Yup.number().nullable(),
          hourTo: Yup.number().nullable().test('hourTo', 'Timing To must be set if Timing From set', this.testTimingTo),
          maxOccurrences: Yup.number().min(1).required(),
          period: Yup.number().nullable().test('period', 'Period must be set too if maxOccurrences set', this.testPeriod),
          conditions: (type === 3 || (notification && notification.type === 3)) ? Yup.array().of(
            Yup.object().shape({
              unionRuleId: Yup.number()
                .integer()
                .required(),
              ruleActionTypeFieldId: Yup.number()
                .integer()
                .required(),
              operator: Yup.number()
                .integer()
                .required(),
              value: Yup.number().required(),
            }),
          ).required().min(1) : Yup.array(),
          imageId: Yup.string().nullable(),
          videoId: Yup.string().nullable(),
        })}
        initialValues={notification || template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">
                    {notification && 'Edit Rule Notification'}
                    {!notification && 'New Rule Notification'}
                  </span>
                  <span className="sub-heading hex-img">
                    Send notifiations based on user progress
                  </span>
                  <div className="pageStyle">
                    <BackButton onClick={() => onBackClick()} />
                    {notification && <DeleteButton onClick={this.onDelete} />}
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} videoIds={[values.videoId]}
                        form={this.form}
                        single allowVideo />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="name" className="control-label font-bold">
                                Name
                                <Field id="name"
                                  name="name"
                                  autoFocus
                                  className={errors.name ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="title" className="control-label font-bold">
                                Title
                                <Field id="title"
                                  name="title"
                                  className={errors.title ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="description" className="control-label font-bold">
                                Body
                                <Field id="description"
                                  name="description"
                                  value={values.description || ''}
                                  className={errors.description ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="hourFrom" className="control-label font-bold">
                                Timing From
                                <Field id="hourFrom"
                                  name="hourFrom"
                                  component={SelectField}
                                  options={ConvertToOptions(NotificationHours)}
                                  className={errors.hourFrom ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          <div className="col-md-3 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="hourTo" className="control-label font-bold">
                                Timing To
                                <Field id="hourTo"
                                  name="hourTo"
                                  component={SelectField}
                                  options={ConvertToOptions(NotificationHours)}
                                  className={errors.hourTo ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          {
                            (type === 3 || (notification && notification.type === 3))
                            && (
                              <div className="col-md-3 col-xs-12">
                                <div className="form-group form-group-lg">
                                  <label htmlFor="maxOccurrences" className="control-label font-bold">
                                    Max Occurrences
                                    <Field id="maxOccurrences"
                                      name="maxOccurrences"
                                      component={SelectField}
                                      options={maxOccurrencesTimes}
                                      onChange={(form, newValue) => {
                                        if (newValue === 1) { form.setFieldValue('period', ''); }
                                      }}
                                      className={errors.maxOccurrences ? 'form-control error' : 'form-control'} />
                                  </label>
                                </div>
                              </div>
                            )
                          }
                          {
                            values.maxOccurrences > 1
                            && (
                              <div className="col-md-3 col-xs-12">
                                <div className="form-group form-group-lg">
                                  <label htmlFor="period" className="control-label font-bold">
                                    Period
                                    <Field id="period"
                                      name="period"
                                      component={SelectField}
                                      options={ConvertToOptions(NotificationPeriod)}
                                      className={errors.period ? 'form-control error' : 'form-control'} />
                                  </label>
                                </div>
                              </div>
                            )
                          }
                        </div>
                        {
                          (type === 3 || (notification && notification.type === 3))
                          && (
                            <FieldArray name="conditions"
                              render={arrayHelpers => (
                                <div className="form-subsection">
                                  <h4>Conditions</h4>
                                  <button type="button"
                                    className="glyphbutton"
                                    title="Add"
                                    onClick={() => arrayHelpers.push({
                                      unionRuleId: '',
                                      ruleActionTypeFieldId: '',
                                      aggregate: '',
                                      operator: null,
                                      value: '',
                                    })}>
                                    <span className={`btn-sm btn-success pull-right link glyphicon glyphicon-plus ${values.conditions && values.conditions.length === 0 ? 'btn-error' : ''}`} />
                                  </button>
                                  {values.conditions
                                && values.conditions.map((obj, index) => this.renderRule(values, errors, index, arrayHelpers))}
                                </div>
                              )} />
                          )
                        }
                      </div>
                      <div className="col-md-3 form-additional-info">
                        <label>
                          Type
                          <div>{notification ? NotificationType[notification.type] : NotificationType[type]}</div>
                        </label>
                        <label htmlFor="status-switch">
                          Disabled
                          <div>
                            <Switch id="status-switch"
                              onChange={() => this.form.current.setFieldValue('isDisabled', !values.isDisabled)}
                              checked={values.isDisabled}
                              checkedChildren={<i className="glyphicon glyphicon-ok" />}
                              unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

GameRuleNotification.propTypes = {
  notification: PropTypes.shape({ id: PropTypes.number, title: PropTypes.string, type: PropTypes.number }),
  game: PropTypes.shape({ ruleUnions: PropTypes.arrayOf(PropTypes.shape({})), gameNotifications: PropTypes.arrayOf(PropTypes.shape({})), gameParticipants: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
  ruleDetails: PropTypes.shape({}).isRequired,
  requestGetRule: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  type: PropTypes.number,
};

GameRuleNotification.defaultProps = {
  notification: null,
  type: null,
};

export default connect(
  state => ({
    ruleDetails: state.rule.details,
  }),
  dispatch => bindActionCreators(
    {
      requestGetRule: ruleActionCreators.requestGet,
    },
    dispatch,
  ),
)(GameRuleNotification);
