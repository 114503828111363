import React from 'react';
import PropTypes from 'prop-types';
import { GameElement } from '../../utils/enum';
import RuleEdit from '../rule/Edit';
import ParticipantEdit from '../participant/Edit';
import RewardEdit from '../reward/Edit';
import GameEdit from './Edit';

const GameElementEditors = props => {
  const {
    gameElement, onBackClick, id, copy, match,
  } = props;

  return (
    <>
      {gameElement === GameElement.Games && (
        <GameEdit id={id} onBackClick={onBackClick}
          gameMode />
      )}
      {(gameElement === GameElement.Rules || gameElement === GameElement.Rankings) && (
        <RuleEdit id={id} copy={copy}
          match={{ path: match.path }}
          onBackClick={onBackClick} gameMode />
      )}
      {gameElement === GameElement.Participants && (
        <ParticipantEdit id={id} copy={copy}
          onBackClick={onBackClick} gameMode />
      )}
      {gameElement === GameElement.Rewards && (
        <RewardEdit id={id} copy={copy}
          onBackClick={onBackClick} gameMode />
      )}
    </>
  );
};

GameElementEditors.propTypes = {
  gameElement: PropTypes.number.isRequired,
  onBackClick: PropTypes.func.isRequired,
  id: PropTypes.number,
  copy: PropTypes.bool.isRequired,
  match: PropTypes.shape({}).isRequired,
};

GameElementEditors.defaultProps = {
  id: null,
};

export default GameElementEditors;
