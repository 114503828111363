import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators as ruleActionCreators } from '../../store/modules/Rule';
import { actionCreators as rankingActionCreators } from '../../store/modules/Ranking';
import List, { ListPropTypes } from '../../containers/List';
import date from '../../utils/date';
import ListImage from '../../containers/ListImage';
import helper from '../../utils/helper';

const RuleList = props => {
  const {
    list, requestList, switchDisplayStyle, switchFilterVisibility, isRuleMode,
  } = props;

  const columns = [
    {
      id: 'imageId',
      Header: '',
      Cell: row => (
        <ListImage imageSize={32} className="table-image"
          items={list.data} currentItem={row.original} />
      ),
      sortable: false,
      maxWidth: 40,
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
      sortable: true,
    },
    {
      id: 'actionType',
      Header: 'Action',
      accessor: 'actionType.name',
      sortable: true,
    },
    {
      id: 'createdOn',
      Header: 'Created',
      accessor: 'createdOn',
      Cell: cell => date.short(cell.value),
      sortable: true,
      maxWidth: 120,
    },
    {
      accessor: 'id',
      Cell: cell => (
        <LinkContainer to={`${helper.getNewRuleLink(props)}/${cell.value}`} exact>
          <div className="gaas-tile-btn">See More</div>
        </LinkContainer>
      ),
      maxWidth: 150,
    },
  ];
  return (
    <List title={isRuleMode ? 'Rules' : 'Rankings'}
      subtitle={isRuleMode ? 'Rule is a set of conditions telling players what they should do in order to achieve success in a game'
        : 'Ranking is a condition telling players what they should do in order to get higher position in a leaderboard'}
      requestList={requestList}
      {...list.paging}
      preferTable={list.preferTable}
      filters={list.filters}
      tableData={list.data}
      tableColumns={columns}
      switchDisplayStyle={switchDisplayStyle}
      switchFilterVisibility={switchFilterVisibility}
      newLink={`${helper.getNewRuleLink(props)}/new`}>
      {list.data.map(rule => (
        <div key={rule.id}>
          <div className="gaas-tile">
            <div className="gaas-tile-image">
              <ListImage items={list.data} currentItem={rule}
                className={rule.isDisabled ? 'avatar lg disabled' : undefined} />
            </div>
            <div className="gaas-tile-heading h-img">
              <span className="text-ellipsis">{rule.name}</span>
            </div>
            <div className="gaas-tile-body">
              <div className="m-t-md m-b-md padder">
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Action</span>
                    {' : '}
                    <span>{rule.actionType.name}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Filters</span>
                    {' : '}
                    <span>{rule.filterCount}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="gaas-tile-footer m-t-sm" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
              <LinkContainer to={`${helper.getNewRuleLink(props)}/${rule.id}`} exact>
                <div className="gaas-tile-btn">See More</div>
              </LinkContainer>
            </div>
            <div className="clear" />
          </div>
        </div>
      ))}
    </List>
  );
};

RuleList.propTypes = {
  list: ListPropTypes.isRequired,
  isRuleMode: PropTypes.bool.isRequired,
  requestList: PropTypes.func.isRequired,
  switchDisplayStyle: PropTypes.func.isRequired,
  switchFilterVisibility: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => ({
    list: helper.ruleResolver(ownProps, state.rule.list, state.ranking.list),
    isRuleMode: helper.ruleResolver(ownProps, true, false),
  }),
  (dispatch, ownProps) => bindActionCreators(helper.ruleResolver(ownProps, ruleActionCreators, rankingActionCreators), dispatch),
)(RuleList);
