import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

const GameRewardsRank = props => {
  const {
    rewardUnion, canEdit, onChange,
  } = props;

  const [rankFrom, setRankFrom] = useState(rewardUnion.rankFrom);
  const [rankTo, setRankTo] = useState(rewardUnion.rankTo);

  const validNumber = text => {
    if (text) {
      if (Number(text) > 0 && Number.isInteger(Number(text))) {
        return true;
      }
    }
    return false;
  };

  const onClose = () => {
    if (validNumber(rankTo) && Number(rankTo) > 0 && validNumber(rankFrom)
      && Number(rankFrom) > 0 && Number(rankTo) >= Number(rankFrom)) {
      rewardUnion.rankTo = Number(rankTo);
      rewardUnion.rankFrom = Number(rankFrom);
      onChange();
    }
  };

  const onOpen = () => {
    setRankFrom(rewardUnion.rankFrom);
    setRankTo(rewardUnion.rankTo);
  };

  const rankTemplate = rewardUnion.rankFrom === rewardUnion.rankTo
    ? <span>Rank {rewardUnion.rankFrom}</span>
    : <span>Rank {rewardUnion.rankFrom} to {rewardUnion.rankTo}</span>;

  return (
    <div className="reward-rank">
      {canEdit ? (
        <Popup trigger={rankTemplate}
          on="hover"
          onOpen={onOpen}
          onClose={onClose}
          position="top left"
          mouseLeaveDelay={300}
          closeOnDocumentClick
          className="reward-rank">
          <div>
            <input type="number"
              min={1}
              max={9999}
              value={rankFrom}
              onChange={e => setRankFrom(e.target.value)} />
            <span>-</span>
            <input type="number"
              min={1}
              max={9999}
              value={rankTo}
              onChange={e => setRankTo(e.target.value)} />
          </div>
        </Popup>
      ) : (
        rankTemplate
      )}
    </div>
  );
};

GameRewardsRank.propTypes = {
  rewardUnion: PropTypes.shape({ id: PropTypes.number, rankFrom: PropTypes.number, rankTo: PropTypes.number }).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default GameRewardsRank;
