import React from 'react';
import PropTypes from 'prop-types';
import confirm, { NoCancel } from '../utils/confirm';

const DeleteButton = props => {
  const { onClick } = props;

  return (
    <button type="button" className="delete-btn"
      onClick={() => confirm().then(onClick, NoCancel)}>
      <i className="glyphicon glyphicon-floppy-remove" />
      <div className="hover-text">Delete</div>
    </button>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func,
};

DeleteButton.defaultProps = {
  onClick: null,
};

export default DeleteButton;
