import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import { actionCreators } from '../../store/modules/Notification';
import { actionCreators as notificationTypeActionCreators } from '../../store/modules/NotificationType';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import SelectField, { Convert } from '../../controls/SelectField';
import DataTypeField from '../../controls/DataTypeField';
import { DataType, NotificationType } from '../../utils/enum';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import helper from '../../utils/helper';

class NotificationEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {
        name: '',
        playerName: '',
        notificationType: { id: null, name: '' },
        parameters: [],
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { id, requestGet, requestAllNotificationTypes } = this.props;

    if (id) {
      requestGet(id);
    } else {
      requestAllNotificationTypes();
    }
  }

  handleSave(values) {
    const { id, requestPost } = this.props;

    if (!id) {
      requestPost(values);
    }
  }

  render() {
    const {
      id, notification, notificationTypes,
    } = this.props;
    const { template } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          notificationType: Yup.object().shape({
            id: Yup.number()
              .integer()
              .required(),
            name: Yup.string().nullable(),
          }),
          playerName: Yup.string().required(),
          parameters: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required(),
              value: Yup.string().required(),
            }),
          ),
        })}
        initialValues={notification || template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">{id ? 'View Notification' : 'New Notification'}</span>
                  <span className="sub-heading hex-img">Notification is a player related message that is created on player progress within a game</span>
                  <div className="pageStyle">
                    <BackButton />
                    {!id && <SaveButton />}
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={values.imageId ? [values.imageId] : []} videoIds={values.videoId ? [values.videoId] : []}
                        single readOnly
                        allowVideo />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        {!id && (
                          <div className="row">
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="notificationType.id" className="control-label font-bold">
                                  Type
                                  <Field id="notificationType.id"
                                    name="notificationType.id"
                                    component={SelectField}
                                    options={Convert(notificationTypes.filter(x => x.type === NotificationType.External))}
                                    onChange={(form, newValue) => {
                                      form.setFieldValue(
                                        'parameters',
                                        newValue ? notificationTypes.find(notificationType => notificationType.id === newValue).parameters.map(x => ({ name: x })) : [],
                                      );
                                      form.setFieldValue(
                                        'notificationType',
                                        newValue ? notificationTypes.find(notificationType => notificationType.id === newValue) : {},
                                      );
                                    }}
                                    className={errors.notificationType ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="playerName" className="control-label font-bold">
                                  Player
                                  <Field id="playerName"
                                    name="playerName"
                                    className={errors.playerName ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        { id && notification
                        && (
                          <>
                            <div className="row">
                              <div className="col-xs-12">
                                <div className="form-group form-group-lg">
                                  <label htmlFor="title" className="control-label font-bold">
                                    Title
                                    <Field id="title"
                                      name="title"
                                      value={values.title || 'None'}
                                      className="form-control" />
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-12">
                                <div className="form-group form-group-lg">
                                  <label htmlFor="body" className="control-label font-bold">
                                    Body
                                    <Field id="body"
                                      type="textarea"
                                      name="body"
                                      value={values.body || 'None'}
                                      component="textarea"
                                      rows="4"
                                      className="form-control" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {
                          !id && values.parameters.length > 0
                          && (
                            <div className="form-subsection">
                              <h4>Parameters</h4>
                            </div>
                          )
                        }
                        {
                          !id
                          && (
                            <div className="row">
                              <FieldArray name="parameters"
                                render={() => values.parameters.map((parameter, index) => (
                                  <div className="col-xs-6" key={parameter.name}>
                                    <div className="form-group form-group-lg">
                                      <label htmlFor={`parameters.${index}.value`} className="control-label font-bold">
                                        {parameter.name}
                                        <Field id={`parameters.${index}.value`}
                                          name={`parameters.${index}.value`}
                                          component={DataTypeField}
                                          dataType={DataType.Text}
                                          readOnly={!!id}
                                          className={
                                            errors.parameters && errors.parameters[index] && errors.parameters[index].value
                                              ? 'form-control error'
                                              : 'form-control'
                                          } />
                                      </label>
                                    </div>
                                  </div>
                                ))} />
                            </div>
                          )
                        }
                      </div>
                      {id
                        && notification && (
                        <div className="col-md-3 form-additional-info">
                          <label>
                            Created
                            <div>{date.long(notification.createdOn)}</div>
                          </label>
                          <label>
                            Happened
                            <div>{date.long(notification.generatedOn)}</div>
                          </label>
                          <label>
                            Type
                            <div>{NotificationType[notification.type]}</div>
                          </label>
                          {
                            notification.name
                            && (
                              <label>
                                Name
                                <div>{notification.name}</div>
                              </label>
                            )
                          }
                          {
                            notification.game
                            && (
                              <label>
                                Game
                                <div>{notification.game.name}</div>
                              </label>
                            )
                          }
                          {
                            notification.participant
                            && (
                              <label>
                                Profile
                                <div>{notification.participant.name}</div>
                              </label>
                            )
                          }
                          <label>
                            Player
                            <div>{notification.player.name}</div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

NotificationEdit.propTypes = {
  id: PropTypes.number,
  notification: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isDisabled: PropTypes.bool,
    type: PropTypes.number,
    createdOn: PropTypes.string,
    generatedOn: PropTypes.string,
    game: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
    player: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
    participant: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestAllNotificationTypes: PropTypes.func.isRequired,
  notificationTypes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
};

NotificationEdit.defaultProps = {
  id: null,
  notification: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    notification: state.notification.details[helper.getIdFromProps(ownProps)],
    notificationTypes: state.notificationType.all,
  }),
  dispatch => bindActionCreators({ ...actionCreators, requestAllNotificationTypes: notificationTypeActionCreators.requestAll }, dispatch),
)(NotificationEdit);
