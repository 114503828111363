import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GameElement, Conjunction } from '../../utils/enum';
import Image, { GetImageSrc } from '../../controls/Image';
import GameElementSelector from './ElementSelector';
import ConjunctionIndicator from '../../controls/ConjunctionIndicator';

class GameRankings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rankingSelectionIsOpen: false,
    };
  }

  addRanking = ranking => {
    const { game, onChange } = this.props;

    game.ruleUnions[0].rules.push({ ...ranking, unionEntityId: new Date().getMilliseconds() });
    this.setState({ rankingSelectionIsOpen: false });
    onChange();
  };

  removeFromGame = (array, element) => {
    const { onChange } = this.props;

    const index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
      onChange();
    }
  };

  render() {
    const { game, onAdd, onClick } = this.props;
    const { rankingSelectionIsOpen } = this.state;

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

    const rankings = game.ruleUnions.length > 0 ? [...game.ruleUnions[0].rules] : [];

    const hasAnyRanking = () => rankings.length > 0;

    return (
      <>
        <div className={activeParticipant ? 'union-title warning' : (hasAnyRanking() ? 'union-title valid' : 'union-title error')}>
          <i className="glyphicon glyphicon-stats" />
          Ranking
          <span className="error">{!hasAnyRanking() && !activeParticipant ? 'Game must contain a ranking!' : ''}</span>
          <span className="warning">{activeParticipant ? 'Default player profile used!' : ''}</span>
        </div>
        <ul className="tree">
          {!activeParticipant && rankings.map((ranking, index) => (
            <li key={`reward-${ranking.id}`}>
              {index > 0 && <ConjunctionIndicator conjunction={Conjunction.And} />}
              <button type="button" onClick={() => onClick(ranking, GameElement.Rankings)}
                className="reward" title={ranking.name}>
                <Image src={GetImageSrc(ranking.imageId, 40)} alt="ranking" />
                {ranking.name}
              </button>

              {!game.publishedOn && !activeParticipant && (
                <button type="button"
                  className="btn-remove"
                  title="Delete"
                  onClick={() => {
                    this.removeFromGame(game.ruleUnions[0].rules, ranking);
                  }}>
                  <i className="glyphicon glyphicon-remove" />
                </button>
              )}
            </li>
          ))}
        </ul>

        {!game.publishedOn && !activeParticipant && rankings.length === 0 && (
          <ul className="tree subtree">
            <li>
              <button type="button" className="reward-new"
                onClick={() => this.setState({ rankingSelectionIsOpen: true })}>
                Add...
              </button>
            </li>
          </ul>
        )}

        <GameElementSelector isOpen={rankingSelectionIsOpen}
          mode={GameElement.Rankings}
          existing={[]}
          multiple={false}
          onClose={() => this.setState({ rankingSelectionIsOpen: false })}
          onSelect={this.addRanking}
          onAdd={copy => onAdd(game.ruleUnions[0].rules, GameElement.Rankings, copy)} />
      </>
    );
  }
}

GameRankings.propTypes = {
  game: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GameRankings;
