import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/modules/Dashboard';

class DashboardTotal extends React.Component {
  constructor(props) {
    super(props);
    this.rootDiv = React.createRef();
  }

  componentDidMount() {
    const { requestTotal, period, measure } = this.props;

    if (measure !== 0) requestTotal(period, measure);
  }

  render() {
    const { total, period, measure } = this.props;

    const measureData = total[measure] ? total[measure] : {};
    const data = measureData[period] ? measureData[period] : 0;

    const height = this.rootDiv.current ? this.rootDiv.current.offsetHeight : 0;
    const width = this.rootDiv.current ? this.rootDiv.current.offsetWidth : 0;

    return (
      <div className="dashboard-total" ref={this.rootDiv}>
        <svg width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${width} ${height}`}>
          <text x="50%" y={measure === 0 ? "20%" : "50%"}
            style={{ fontSize: height * (measure === 0 ? 0.1 : 0.8) }} dy="30%"
            textAnchor="middle">
            {measure === 0 ? 'No data' : data}
          </text>
        </svg>
      </div>
    );
  }
}

DashboardTotal.propTypes = {
  period: PropTypes.number.isRequired,
  measure: PropTypes.number.isRequired,
  requestTotal: PropTypes.func.isRequired,
  total: PropTypes.shape({}).isRequired,
};

export default connect(
  state => ({
    total: state.dashboard.total,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(DashboardTotal);
