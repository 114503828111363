import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { actionCreators } from '../../store/modules/Player';
import { actionCreators as groupActionCreators } from '../../store/modules/Group';
import { actionCreators as attributeActionCreators } from '../../store/modules/PlayerAttribute';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import SelectField, { Convert } from '../../controls/SelectField';
import DataTypeField from '../../controls/DataTypeField';
import { DataType } from '../../utils/enum';
import DeleteButton from '../../controls/DeleteButton';
import BackButton from '../../controls/BackButton';
import PlayerRoundList from './RoundList';
import PlayerRewardList from './RewardList';
import PlayerPlayerList from './PlayerList';
import PlayerMappingList from './MappingList';
import SaveButton from '../../controls/SaveButton';
import helper from '../../utils/helper';
import confirm, { NoCancel } from '../../utils/confirm';
import { selectors } from '../../store/modules/User';

class PlayerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: 'general',
      template: {
        name: '',
        groups: [],
        rounds: [],
        attributes: {},
        description: '',
        imageId: '',
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const {
      id, requestGet, requestAllGroups, requestAllAttributes, hideChildFilters,
    } = this.props;

    if (id) {
      requestGet(id);
      hideChildFilters();
    }
    requestAllGroups();
    requestAllAttributes();
  }

  handleSave(values) {
    const { id, requestPost, requestPut } = this.props;

    if (id) {
      requestPut(id, values);
    } else {
      requestPost(values);
    }
  }

  handleStatus(enabled) {
    const { id, requestEnable, requestDisable } = this.props;

    if (enabled) {
      requestEnable(id);
    } else {
      requestDisable(id);
    }
  }

  render() {
    const {
      id, player, groups, attributes, requestDelete, requestReset, isAdmin,
    } = this.props;
    const { section, template } = this.state;

    const attributesFiltered = attributes.filter(a => !a.isSystem && !a.isAutomatic && ((player && player.attributes[a.id]) || !a.isDisabled));

    const attributesAutomaticFiltered = attributes.filter(a => !a.isSystem && a.isAutomatic && ((player && player.attributes[a.id]) || !a.isDisabled));

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(50)
            .required(),
          groups: Yup.array().of(
            Yup.object().shape({
              id: Yup.number()
                .integer()
                .required(),
            }),
          ),
          description: Yup.string().nullable(),
          imageId: Yup.string().nullable(),
          attributes: Yup.object().shape(
            attributes.reduce((obj, attribute) => {
              const result = obj;
              if (attribute.dataType === DataType.Number) {
                result[attribute.id] = Yup.number().nullable();
              } else if (attribute.dataType === DataType.Boolean) {
                result[attribute.id] = Yup.boolean().nullable();
              } else if (attribute.dataType === DataType.Date) {
                result[attribute.id] = Yup.date().nullable();
              } else {
                result[attribute.id] = Yup.string().nullable();
              }
              return result;
            }, {}),
          ),
        })}
        initialValues={player || template}>
          {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">{id ? 'Edit Player' : 'New Player'}</span>
                  <span className="sub-heading hex-img">Player is someone or something that can participate in a game</span>
                  <div className="pageStyle">
                    <BackButton />
                    {id && (
                      <button type="button"
                        className="delete-btn"
                        onClick={() => confirm().then(() => requestReset(id), NoCancel)}>
                        <i className="glyphicon glyphicon-refresh" />
                        <div className="hover-text">Reset</div>
                      </button>
                    )}
                    {id && <DeleteButton onClick={() => requestDelete(id)} />}
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} form={this.form}
                        single />
                    </div>
                    <div className="panel-heading" />
                    {id && (
                      <div className="panel-menu">
                        <div className={section === 'general' ? 'active' : ''}>
                          <button type="button"
                            onClick={() => this.setState({ section: 'general' })}
                            className="glyphbutton"
                            title="General">
                            <i className="glyphicon glyphicon-home" />
                          </button>
                        </div>
                        <div className={section === 'games' ? 'active' : ''}>
                          <button type="button"
                            onClick={() => this.setState({ section: 'games' })}
                            className="glyphbutton"
                            title="Games">
                            <i className="glyphicon glyphicon-fire" />
                          </button>
                        </div>
                        <div className={section === 'leaderboards' ? 'active' : ''}>
                          <button type="button"
                            onClick={() => this.setState({ section: 'leaderboards' })}
                            className="glyphbutton"
                            title="Leaderboards">
                            <i className="glyphicon glyphicon-stats" />
                          </button>
                        </div>
                        <div className={section === 'rewards' ? 'active' : ''}>
                          <button type="button"
                            onClick={() => this.setState({ section: 'rewards' })}
                            className="glyphbutton"
                            title="Rewards">
                            <i className="glyphicon glyphicon-gift" />
                          </button>
                        </div>
                        {
                          isAdmin && 
                          <div className={section === 'players' ? 'active' : ''}>
                            <button type="button"
                              onClick={() => this.setState({ section: 'players' })}
                              className="glyphbutton"
                              title="Connections">
                              <i className="glyphicon glyphicon-heart-empty" />
                            </button>
                          </div>
                        }
                        {
                          isAdmin &&
                          <div className={section === 'mappings' ? 'active' : ''}>
                            <button type="button"
                              onClick={() => this.setState({ section: 'mappings' })}
                              className="glyphbutton"
                              title="Mappings">
                              <i className="glyphicon glyphicon-link" />
                            </button>
                          </div>
                        }
                      </div>
                    )}
                    {section === 'general' && (
                      <div className="panel-body hex" id="scroller">
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="name" className="control-label font-bold">
                                  Name
                                  <Field id="name"
                                    name="name"
                                    autoFocus
                                    className={errors.name ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="description" className="control-label font-bold">
                                  Description
                                  <Field id="description"
                                    name="description"
                                    value={values.description || ''}
                                    className={errors.description ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="groups" className="control-label font-bold">
                                  Groups
                                  <Field id="groups"
                                    name="groups"
                                    component={SelectField}
                                    options={Convert(groups)}
                                    isMulti
                                    className={errors.groups ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                          {(attributesFiltered.length > 0 || attributesAutomaticFiltered.length > 0) && (
                            <div className="form-subsection">
                              <h4>Attributes</h4>
                              <div className="row">
                                {attributesFiltered.map(attribute => (
                                  <div className="col-xs-6" key={`attributes.${attribute.id}`}>
                                    <div className="form-group form-group-lg">
                                      <label htmlFor={`attributes.${attribute.id}`} className="control-label font-bold">
                                        {attribute.name}
                                        <Field id={`attributes.${attribute.id}`}
                                          name={`attributes.${attribute.id}`}
                                          component={DataTypeField}
                                          dataType={attribute.dataType}
                                          className={
                                            errors.attributes && errors.attributes[attribute.id]
                                              ? 'form-control error'
                                              : 'form-control'
                                          } />
                                      </label>
                                    </div>
                                  </div>
                                ))}
                                {id && attributesAutomaticFiltered.map(attribute => (
                                  <div className="col-xs-6" key={`attributes.${attribute.id}`}>
                                    <div className="form-group form-group-lg">
                                      <label htmlFor={`attributes.${attribute.id}`} className="control-label font-bold">
                                        {attribute.name} [Automatic]
                                        <Field id={`attributes.${attribute.id}`}
                                          name={`attributes.${attribute.id}`}
                                          component={DataTypeField}
                                          readOnly
                                          dataType={attribute.dataType}
                                          className={
                                            errors.attributes && errors.attributes[attribute.id]
                                              ? 'form-control error'
                                              : 'form-control'
                                          } />
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        {id
                          && player && (
                          <div className="col-md-3 form-additional-info">
                            <label>
                              Created
                              <div>{date.long(player.createdOn)}</div>
                            </label>
                            {player.level && (
                              <label>
                                Level
                                <div>{player.level.name}</div>
                              </label>
                            )}
                            <label>
                              Experience
                              <div>{player.experience}</div>
                            </label>
                            <label>
                              Currency
                              <div>{player.currency}</div>
                            </label>
                            {(player.blockchainAddress)
                                && (
                                  <label>
                                    Blockchain balance
                                    <div>{player.blockchainBalance}</div>
                                  </label>
                                )}
                            {(player.blockchainAddress)
                                && (
                                  <label>
                                    Blockchain address
                                    <div>{player.blockchainAddress}</div>
                                  </label>
                                )}
                            <label htmlFor="status-switch">
                              Disabled
                              <div>
                                <Switch id="status-switch"
                                  onChange={() => this.handleStatus(player.isDisabled)}
                                  checked={player.isDisabled}
                                  checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                  unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                              </div>
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                    {id && section === 'games' && (
                      <div className="panel-body hex dataFormList">
                        <div className="form-subsection">
                          <PlayerRoundList id={id} isGameMode={true} />
                        </div>
                      </div>
                    )}
                    {id && section === 'leaderboards' && (
                      <div className="panel-body hex dataFormList">
                        <div className="form-subsection">
                          <PlayerRoundList id={id} isGameMode={false} />
                        </div>
                      </div>
                    )}
                    {id && section === 'rewards' && (
                      <div className="panel-body hex dataFormList">
                        <div className="form-subsection">
                          <PlayerRewardList id={id} />
                        </div>
                      </div>
                    )}
                    {id && section === 'players' && (
                      <div className="panel-body hex dataFormList">
                        <div className="form-subsection">
                          <PlayerPlayerList id={id} />
                        </div>
                      </div>
                    )}
                    {id && section === 'mappings' && (
                      <div className="panel-body hex dataFormList">
                        <div className="form-subsection">
                          <PlayerMappingList id={id} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
        </Formik>
    );
  }
}

PlayerEdit.propTypes = {
  id: PropTypes.number,
  player: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
  requestReset: PropTypes.func.isRequired,
  hideChildFilters: PropTypes.func.isRequired,
  requestAllGroups: PropTypes.func.isRequired,
  requestAllAttributes: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

PlayerEdit.defaultProps = {
  id: null,
  player: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    player: state.player.details[helper.getIdFromProps(ownProps)],
    groups: state.group.all,
    attributes: state.playerAttribute.all,
    isAdmin: selectors.getIsAdmin(state),
  }),
  dispatch => bindActionCreators(
    {
      ...actionCreators,
      requestAllGroups: groupActionCreators.requestAll,
      requestAllAttributes: attributeActionCreators.requestAll,
    },
    dispatch,
  ),
)(PlayerEdit);
