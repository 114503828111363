import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { actionCreators } from '../../store/modules/Filter';
import { actionCreators as actionTypeActionCreators } from '../../store/modules/ActionType';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import SelectField, { Convert } from '../../controls/SelectField';
import DeleteButton from '../../controls/DeleteButton';
import DataTypeField from '../../controls/DataTypeField';
import OperatorField from '../../controls/OperatorField';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import CopyButton from '../../controls/CopyButton';
import helper from '../../utils/helper';

class FilterEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      template: {
        name: '',
        description: '',
        actionType: { id: null },
        conditions: [],
        imageId: '',
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { id, requestGet, requestAllActionTypes } = this.props;

    if (id) {
      requestGet(id);
    }
    requestAllActionTypes();
  }

  handleSave(values) {
    const {
      id, copy, requestPost, requestPut,
    } = this.props;

    if (id && !copy) {
      requestPut(id, values);
    } else {
      requestPost(values);
    }
  }

  handleStatus(enabled) {
    const { id, requestEnable, requestDisable } = this.props;

    if (enabled) {
      requestEnable(id);
    } else {
      requestDisable(id);
    }
  }

  renderFilter = (values, errors, index, arrayHelpers) => {
    const { id, actionTypes } = this.props;

    const actionTypeId = id ? values.actionType.id : values.actionType.id;

    const actionType = actionTypes.find(type => type.id === actionTypeId);

    const actionTypeFields = actionTypeId && actionType ? actionType.fields : [];

    const actionTypeField = actionTypeFields.find(field => field.id === values.conditions[index].actionTypeFieldId);

    return (
      <div className="row attribute" key={index}>
        {index > 0 && <span className="and-label">and</span>}
        <button type="button" className="glyphbutton"
          title="Delete Filter" onClick={() => arrayHelpers.remove(index)}>
          <span className="btn-sm btn-danger link glyphicon glyphicon-minus pull-right" />
        </button>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.actionTypeFieldId`} className="control-label font-bold">
              Field
              <Field id={`conditions.${index}.actionTypeFieldId`}
                name={`conditions.${index}.actionTypeFieldId`}
                component={SelectField}
                options={Convert(actionTypeFields)}
                onChange={(form) => {
                  form.setFieldValue(`conditions.${index}.operator`, null);
                  form.setFieldValue(`conditions.${index}.value`, '');
                }}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].actionTypeFieldId
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.operator`} className="control-label font-bold">
              Operator
              <Field id={`conditions.${index}.operator`}
                name={`conditions.${index}.operator`}
                component={OperatorField}
                dataType={actionTypeField ? actionTypeField.dataType : null}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].operator
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.value`} className="control-label font-bold">
              Value
              <Field id={`conditions.${index}.value`}
                name={`conditions.${index}.value`}
                component={DataTypeField}
                dataType={actionTypeField ? actionTypeField.dataType : null}
                operator={values.conditions[index].operator}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].value
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      id, copy, filter, requestDelete, actionTypes, onBackClick,
    } = this.props;
    const { template } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(50)
            .required(),
          description: Yup.string().nullable(),
          actionType: Yup.object().shape({
            id: Yup.number()
              .integer()
              .required(),
          }),
          conditions: Yup.array().of(
            Yup.object().shape({
              actionTypeFieldId: Yup.number()
                .integer()
                .required(),
              operator: Yup.number()
                .integer()
                .required(),
              value: Yup.string().required(),
            }),
          ),
          imageId: Yup.string().nullable(),
        })}
        initialValues={filter || template}>
          {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">
                    {id && !copy && 'Edit Filter'}
                    {id && copy && 'Copy Filter'}
                    {!id && 'New Filter'}
                  </span>
                  <span className="sub-heading hex-img">
                    Filter is a set of conditions only accepting actions that meet certain criteria
                  </span>
                  <div className="pageStyle">
                    <BackButton onClick={onBackClick} />
                    {id && !copy && <CopyButton to={`${id}/copy`} />}
                    {id && !copy && <DeleteButton onClick={() => requestDelete(id)} />}
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} form={this.form}
                        single />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="name" className="control-label font-bold">
                                Name
                                <Field id="name"
                                  name="name"
                                  autoFocus
                                  className={errors.name ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          {!id && (
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="actionType.id" className="control-label font-bold">
                                  Action
                                  <Field id="actionType.id"
                                    name="actionType.id"
                                    component={SelectField}
                                    options={Convert(actionTypes)}
                                    onChange={(form) => {
                                      form.setFieldValue('conditions', []);
                                    }}
                                    className={errors.actionType ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="description" className="control-label font-bold">
                                Description
                                <Field id="description"
                                  name="description"
                                  value={values.description || ''}
                                  className={errors.description ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <FieldArray name="conditions"
                          render={arrayHelpers => (
                            <div className="form-subsection">
                              <h4>Conditions</h4>
                              <button type="button"
                                className="glyphbutton"
                                title="Add"
                                onClick={() => arrayHelpers.push({
                                  actionTypeFieldId: '',
                                  operator: null,
                                  value: '',
                                })
                                }>
                                <span className="btn-sm btn-success  pull-right link glyphicon glyphicon-plus" />
                              </button>
                              {values.conditions
                                && values.conditions.map((obj, index) => this.renderFilter(values, errors, index, arrayHelpers))}
                            </div>
                          )} />
                      </div>
                      {!copy
                        && filter && (
                          <div className="col-md-3 form-additional-info">
                            <label>
                              Created
                              <div>{date.long(filter.createdOn)}</div>
                            </label>
                            <label>
                              Action
                              <div>{filter.actionType.name}</div>
                            </label>
                            <label htmlFor="status-switch">
                              Disabled
                              <div>
                                <Switch id="status-switch"
                                  onChange={() => this.handleStatus(filter.isDisabled)}
                                  checked={filter.isDisabled}
                                  checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                  unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                              </div>
                            </label>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
        </Formik>
    );
  }
}

FilterEdit.propTypes = {
  id: PropTypes.number,
  copy: PropTypes.bool,
  filter: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
  requestAllActionTypes: PropTypes.func.isRequired,
  actionTypes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  onBackClick: PropTypes.func,
};

FilterEdit.defaultProps = {
  id: null,
  copy: false,
  filter: null,
  onBackClick: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    copy: helper.getCopyFromProps(ownProps),
    filter: helper.getEntityById(state.filter.details, ownProps),
    actionTypes: state.actionType.all,
  }),
  dispatch => bindActionCreators(
    {
      ...actionCreators,
      requestAllActionTypes: actionTypeActionCreators.requestAll,
    },
    dispatch,
  ),
)(FilterEdit);
