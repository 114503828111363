import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators } from '../../store/modules/Notification';
import List, { ListPropTypes } from '../../containers/List';
import date from '../../utils/date';
import ListImage from '../../containers/ListImage';
import { NotificationType } from '../../utils/enum';

const NotificationList = props => {
  const {
    list, requestList, switchDisplayStyle, switchFilterVisibility,
  } = props;

  const columns = [
    {
      id: 'imageId',
      Header: '',
      Cell: row => (
        <ListImage imageSize={32}
          className="table-image"
          items={list.data}
          currentItem={row.original} />
      ),
      sortable: false,
      maxWidth: 40,
    },
    {
      id: 'player',
      Header: 'Player',
      accessor: 'player.name',
      sortable: true,
    },
    {
      id: 'game',
      Header: 'Source',
      accessor: 'game.name',
      sortable: true,
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: 'type',
      Cell: cell => NotificationType[cell.value],
      sortable: false,
      maxWidth: 200,
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
      sortable: true,
    },
    {
      id: 'createdOn',
      Header: 'Created',
      accessor: 'createdOn',
      Cell: cell => date.long(cell.value),
      sortable: true,
      maxWidth: 165,
    },
    {
      accessor: 'id',
      Cell: row => (
        <LinkContainer to={`/notification/${row.original.id}`} exact>
          <div className="gaas-tile-btn">See More</div>
        </LinkContainer>
      ),
      maxWidth: 150,
    },
  ];

  return (
    <List title="Notifications"
      subtitle="Notification is a player related message that is created on player progress within a game"
      requestList={requestList}
      {...list.paging}
      preferTable={list.preferTable}
      filters={list.filters}
      tableData={list.data}
      tableColumns={columns}
      switchDisplayStyle={switchDisplayStyle}
      switchFilterVisibility={switchFilterVisibility}
      newLink="/notification/new">
      {list.data.map(notification => (
        <div key={notification.id}>
          <div className="gaas-tile">
            <div className="gaas-tile-image">
              <ListImage items={list.data} currentItem={notification} />
            </div>
            <div className="gaas-tile-heading h-img">
              <span className="text-ellipsis">{notification.player.name}</span>
            </div>
            <div className="gaas-tile-body">
              <div className="m-t-md m-b-md padder">
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Type</span>
                    {' : '}
                    <span>{NotificationType[notification.type]}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Source</span>
                    {' : '}
                    <span>{notification.game ? notification.game.name : '-'}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Created</span>
                    {' : '}
                    <span>{date.long(notification.createdOn)}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="gaas-tile-footer m-t-sm" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
              <LinkContainer to={`/notification/${notification.id}`} exact>
                <div className="gaas-tile-btn">See More</div>
              </LinkContainer>
            </div>
            <div className="clear" />
          </div>
        </div>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  list: ListPropTypes.isRequired,
  requestList: PropTypes.func.isRequired,
  switchDisplayStyle: PropTypes.func.isRequired,
  switchFilterVisibility: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    list: state.notification.list,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(NotificationList);
