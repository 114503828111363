import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Default from '../images/default-image.jpg';
import Fallback from '../images/fallback-image.jpg';
import { getResourceAddress } from '../utils/restHttp';

export const GetImageSrc = (imageId, width) => {
  if (imageId) {
    return getResourceAddress(`file/${imageId}${width ? `?width=${width}` : ''}`);
  }
  return null;
};

export default class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSource: null,
    };
    this.setDisplayImage = this.setDisplayImage.bind(this);
    this.handleInitialTimeout = this.handleInitialTimeout.bind(this);
    this.isLoaded = false;
    this.timeout = null;
  }

  componentDidMount() {
    const { src, fallbackImage, initialImage } = this.props;

    this.handleInitialTimeout();
    this.displayImage = new window.Image();
    this.setDisplayImage({ image: src || initialImage, fallbacks: src ? [src + '&t=' + new Date().getTime()].concat(fallbackImage) : fallbackImage });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { src, initialImage } = this.props;

    if (nextProps.src !== src) {
      this.isLoaded = false;
      if (nextProps.initialImage) {
        this.handleInitialTimeout();
      }
      this.setDisplayImage({ image: nextProps.src || initialImage, fallbacks: nextProps.src ? [nextProps.src + '&t=' + new Date().getTime()].concat(nextProps.fallbackImage) : nextProps.fallbackImage });
    }
  }

  componentWillUnmount() {
    if (this.displayImage) {
      this.displayImage.onerror = null;
      this.displayImage.onload = null;
      this.displayImage = null;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  setDisplayImage({ image, fallbacks }) {
    const { src, onError, onLoad } = this.props;

    const imagesArray = [image].concat(fallbacks).filter(fallback => !!fallback);
    this.displayImage.onerror = () => {
      if (imagesArray.length > 2 && typeof imagesArray[1] === 'string') {
        const updatedFallbacks = imagesArray.slice(2);
        this.setDisplayImage({ image: imagesArray[1], fallbacks: updatedFallbacks });
        return;
      }
      this.isLoaded = true;
      this.setState(
        {
          imageSource: imagesArray[1] || null,
        },
        () => {
          if (onError) {
            onError(src);
          }
        },
      );
    };
    this.displayImage.onload = () => {
      this.isLoaded = true;
      this.setState(
        {
          imageSource: imagesArray[0],
        },
        () => {
          if (onLoad) {
            onLoad(imagesArray[0]);
          }
        },
      );
    };
    if (typeof imagesArray[0] === 'string') {
      this.displayImage.src = imagesArray[0];
    } else {
      this.setState(
        {
          imageSource: imagesArray[0],
        },
        () => {
          if (onLoad) {
            onLoad(imagesArray[0]);
          }
        },
      );
    }
  }

  handleInitialTimeout() {
    const { initialTimeout, initialImage } = this.props;

    if (initialTimeout && initialTimeout > 0) {
      this.timeout = setTimeout(() => {
        if (!this.isLoaded) {
          this.setState({
            imageSource: initialImage,
          });
        }
      }, initialTimeout);
    } else {
      this.setState({
        imageSource: initialImage,
      });
    }
  }

  render() {
    const { imageSource } = this.state;
    const { alt, className } = this.props;

    return typeof imageSource === 'string' ? (
      <img src={imageSource} alt={alt}
        className={this.isLoaded ? className : `image-loading ${className}`} />
    ) : (
      imageSource
    );
  }
}

Image.propTypes = {
  src: PropTypes.string,
  fallbackImage: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  initialImage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  initialTimeout: PropTypes.number,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Image.defaultProps = {
  src: null,
  fallbackImage: Fallback,
  initialImage: Default,
  onLoad: null,
  onError: null,
  initialTimeout: 0,
  className: null,
};
