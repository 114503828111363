import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators } from '../store/modules/Search';
import { actionCreators as userActionCreators, selectors } from '../store/modules/User';
import animate from '../utils/animate';
import { SearchType } from '../utils/enum';
import date from '../utils/date';
import ListImage from './ListImage';
import { AnimatePresence } from 'framer-motion';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      searchText: '',
    };
  }

  handleSearchClick = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  handleSearchResultClick = result => {
    const { isOpen } = this.state;
    const { selectTenant, isAdmin } = this.props;

    if (isAdmin) {
      selectTenant(result.tenant ? result.tenant.id : null, false);
    }

    this.setState({ isOpen: !isOpen });
  };

  handleTextChange = e => {
    const { clearList } = this.props;

    this.setState({ searchText: e.target.value });

    if (!e.target.value) {
      clearList();
    }
  };

  handleKeyPress = event => {
    const { searchText } = this.state;
    const { requestList } = this.props;

    if (event.keyCode === 13 && searchText) {
      requestList(searchText);
    } else if (event.keyCode === 27) {
      this.setState({ isOpen: false });
    }
  };

  getOffsetClassName = () => {
    const { list } = this.props;

    if (list.data.length < 4) {
      return 'col-xs-offset-2';
    }
    return null;
  };

  getLink = (type, id) => {
    if (type === SearchType.Game) {
      return `/game/game/${id}`;
    }
    if (type === SearchType.Leaderboard) {
      return `/game/leaderboard/${id}`;
    }
    if (type === SearchType.Rule) {
      return `/rule/game/${id}`;
    }
    if (type === SearchType.Ranking) {
      return `/rule/leaderboard/${id}`;
    }
    if (type === SearchType.Filter) {
      return `/filter/${id}`;
    }
    if (type === SearchType.Participant) {
      return `/participant/${id}`;
    }
    if (type === SearchType.Reward) {
      return `/reward/${id}`;
    }
    if (type === SearchType.ActionType) {
      return `/actionType/${id}`;
    }
    if (type === SearchType.Action) {
      return `/action/${id}`;
    }
    if (type === SearchType.User) {
      return `/user/${id}`;
    }
    if (type === SearchType.Player) {
      return `/player/${id}`;
    }
    if (type === SearchType.Group) {
      return `/group/${id}`;
    }
    if (type === SearchType.PlayerAttribute) {
      return `/attribute/player/${id}`;
    }
    if (type === SearchType.GameAttribute) {
      return `/attribute/game/${id}`;
    }
    if (type === SearchType.Level) {
      return `/level/${id}`;
    }

    return null;
  };

  render() {
    const { isOpen, searchText } = this.state;
    const { list, isAdmin } = this.props;

    return (
      <>
        <AnimatePresence>
          {isOpen && (
            <animate.search key="search-pose" className="search-window"
              onKeyDown={this.handleKeyPress} tabIndex="0">
              <div className="search-contents">
                <input onChange={this.handleTextChange}
                  onKeyDown={this.handleKeyPress}
                  value={searchText}
                  placeholder="Search"
                  autoFocus
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false" />
                <span>Hit ENTER to search or ESC to close. Max 3 records per type.</span>
                <div className={`search-results col-xs-12 ${this.getOffsetClassName()}`} id="scroller">
                  <AnimatePresence>
                    {searchText.length > 0
                      && list.data.map((result, index) => (
                        <animate.listItem key={SearchType[result.type] + result.id} custom={{index, length: list.data.length}}
                          className="p-t-sm search-result-item col-xs-12 col-sm-12 col-md-4 col-lg-3">
                          <div className="gaas-tile">
                            <div className="gaas-tile-image">
                              <ListImage items={list.data} currentItem={result}
                                className={result.isDisabled ? 'avatar lg disabled' : undefined} />
                            </div>
                            <div className="gaas-tile-heading h-img">
                              <span className="text-ellipsis">{result.name}</span>
                            </div>
                            <div className="gaas-tile-body">
                              <div className="m-t-md m-b-md padder">
                                <div className="row">
                                  <div className="col-xs-12">
                                    <i className="glyphicon glyphicon-screenshot m-r-sm" />
                                    <span className="text-underline">Type</span>
                                    {' : '}
                                    <span>{SearchType[result.type]}</span>
                                    <br />
                                  </div>
                                </div>
                                {isAdmin && (
                                  <div className="row">
                                    <div className="col-xs-12">
                                      <i className="glyphicon glyphicon-screenshot m-r-sm" />
                                      <span className="text-underline">Tenant</span>
                                      {' : '}
                                      <span>{result.tenant ? result.tenant.name : 'Administrator'}</span>
                                      <br />
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-xs-12">
                                    <i className="glyphicon glyphicon-knight m-r-sm" />
                                    <span className="text-underline">Created</span>
                                    {' : '}
                                    <span>{date.short(result.createdOn)}</span>
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="gaas-tile-footer m-t-sm"
                              style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
                              <LinkContainer to={this.getLink(result.type, result.id)}
                                exact
                                onClick={() => this.handleSearchResultClick(result)}>
                                <div className="gaas-tile-btn">See More</div>
                              </LinkContainer>
                            </div>
                            <div className="clear" />
                          </div>
                        </animate.listItem>
                      ))}
                  </AnimatePresence>
                </div>
              </div>
            </animate.search>
          )}
        </AnimatePresence>
        <button type="button" className={`search-button ${isOpen && 'search-button-opened'}`}
          onClick={this.handleSearchClick}>
          {isOpen ? <i className="glyphicon glyphicon-off" /> : <i className="glyphicon glyphicon-search" />}
          {isOpen ? <div className="hover-text">Close</div> : <div className="hover-text">Search</div>}
        </button>
      </>
    );
  }
}

Search.propTypes = {
  list: PropTypes.shape({}).isRequired,
  requestList: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  selectTenant: PropTypes.func.isRequired,
  clearList: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    list: state.search.list,
    isAdmin: selectors.getIsAdmin(state),
  }),
  dispatch => bindActionCreators({ ...actionCreators, selectTenant: userActionCreators.selectTenant }, dispatch),
)(Search);
