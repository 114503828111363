import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators } from '../../store/modules/Player';
import List, { ListPropTypes } from '../../containers/List';
import date from '../../utils/date';
import ListImage from '../../containers/ListImage';

const PlayerRoundList = props => {
  const {
    isGameMode, id, list, requestGameRoundList, switchGameRoundDisplayStyle, switchGameRoundFilterVisibility, requestLeaderboardRoundList, switchLeaderboardRoundDisplayStyle, switchLeaderboardRoundFilterVisibility,
  } = props;

  const requestRoundList = isGameMode ? requestGameRoundList : requestLeaderboardRoundList;
  const switchRoundDisplayStyle = isGameMode ? switchGameRoundDisplayStyle : switchLeaderboardRoundDisplayStyle;
  const switchRoundFilterVisibility = isGameMode ? switchGameRoundFilterVisibility : switchLeaderboardRoundFilterVisibility;

  const columns = [
    {
      id: 'imageId',
      Header: '',
      Cell: row => (
        <ListImage imageSize={32} className="table-image"
          items={list.data.map(r => r.game)} currentItem={row.original.game} />
      ),
      sortable: false,
      maxWidth: 40,
    },
    {
      id: 'game.name',
      Header: 'Game',
      accessor: 'game.name',
      sortable: false,
    },
    {
      id: 'participant.name',
      Header: 'Profile',
      accessor: 'participant.name',
      sortable: false,
    },
    {
      id: 'rank',
      Header: 'Rank',
      accessor: 'rank',
      sortable: false,
      show: !isGameMode,
      maxWidth: 70,
    },
    {
      id: 'aggregate',
      Header: 'Value',
      accessor: 'aggregate',
      sortable: false,
      show: !isGameMode,
      maxWidth: 70,
    },
    {
      id: 'startedOn',
      Header: 'Started',
      accessor: 'startedOn',
      Cell: cell => date.long(cell.value),
      sortable: true,
      maxWidth: 165,
    },
    {
      id: 'createdOn',
      Header: 'Created',
      accessor: 'createdOn',
      Cell: cell => date.long(cell.value),
      sortable: true,
      maxWidth: 165,
    },
    {
      id: 'completedOn',
      Header: 'Completed',
      accessor: 'completedOn',
      Cell: cell => (cell.value ? date.long(cell.value) : 'In progress'),
      sortable: true,
      maxWidth: 165,
    },
    {
      accessor: 'game.id',
      Cell: cell => (
        <LinkContainer to={`/game/${isGameMode ? 'game' : 'leaderboard'}/${cell.value}`} exact>
          <div className="gaas-tile-btn">See More</div>
        </LinkContainer>
      ),
      maxWidth: 150,
    },
  ];
  return (
    <List title={`${isGameMode ? 'Game' : 'Leaderboard'} Rounds`}
      subtitle="Round is a single try of the game or leaderboard"
      requestList={(pageIndex, pageSize, sortBy, sortDescending, filters) => requestRoundList(id, pageIndex, pageSize, sortBy, sortDescending, filters)}
      {...list.paging}
      preferTable={list.preferTable}
      filters={list.filters}
      tableData={list.data}
      tableColumns={columns}
      switchDisplayStyle={switchRoundDisplayStyle}
      switchFilterVisibility={switchRoundFilterVisibility}>
      {list.data.map(round => (
        <div key={round.id}>
          <div className="gaas-tile">
            <div className="gaas-tile-image">
              <ListImage items={list.data.map(r => r.game)} currentItem={round.game}
                className={round.game.isDisabled ? 'avatar lg disabled' : undefined} />
            </div>
            <div className="gaas-tile-heading h-img">
              <span className="text-ellipsis">{round.game.name}</span>
            </div>
            <div className="gaas-tile-body">
              <div className="m-t-md m-b-md padder">
                {
                  !isGameMode
                  && (
                    <div className="row">
                      <div className="col-xs-12">
                        <span className="text-underline">Rank</span>
                        {' : '}
                        <span>{round.rank}</span>
                        <br />
                      </div>
                    </div>
                  )
                }
                <div className="row">
                  <div className="col-xs-12">
                    <span className="text-underline">Started</span>
                    {' : '}
                    <span>{date.long(round.createdOn)}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <span className="text-underline">Completed</span>
                    {' : '}
                    <span>{round.completedOn ? date.long(round.completedOn) : 'In progress'}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="gaas-tile-footer m-t-sm" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
              <LinkContainer to={`/game/${isGameMode ? 'game' : 'leaderboard'}/${round.game.id}`} exact>
                <div className="gaas-tile-btn">See More</div>
              </LinkContainer>
            </div>
            <div className="clear" />
          </div>
        </div>
      ))}
    </List>
  );
};

PlayerRoundList.propTypes = {
  isGameMode: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  list: ListPropTypes.isRequired,
  requestGameRoundList: PropTypes.func.isRequired,
  switchGameRoundDisplayStyle: PropTypes.func.isRequired,
  switchGameRoundFilterVisibility: PropTypes.func.isRequired,
  requestLeaderboardRoundList: PropTypes.func.isRequired,
  switchLeaderboardRoundDisplayStyle: PropTypes.func.isRequired,
  switchLeaderboardRoundFilterVisibility: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => ({
    list: ownProps.isGameMode ? state.player.gameRoundList : state.player.leaderboardRoundList,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(PlayerRoundList);
