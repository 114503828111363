import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';

const CopyButton = props => {
  const { to, onClick } = props;

  if (to) {
    return (
      <LinkContainer to={to}>
        <button type="button" className="active btn-copy">
          <i className="glyphicon glyphicon-duplicate" />
          <div className="hover-text">Copy</div>
        </button>
      </LinkContainer>
    );
  }

  return (
    <button type="button" onClick={onClick}
      className="active btn-copy">
      <i className="glyphicon glyphicon-duplicate" />
      <div className="hover-text">Copy</div>
    </button>
  );
};

CopyButton.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
};

CopyButton.defaultProps = {
  to: null,
  onClick: null,
};

export default CopyButton;
