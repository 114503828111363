import axios from 'axios';
import store from '../index';
import { selectors } from '../store/modules/User';

export const BaseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const defaultOptions = {
  headers: { 'Content-Type': 'application/json' },
};

function getOptions(options) {
  const composedOptions = { ...defaultOptions, headers: { ...defaultOptions.headers }, ...options };
  const state = store.getState();
  const { user } = state;
  if (user && user.auth) composedOptions.headers.Authorization = `Bearer ${user.auth.access_token}`;
  if (user && selectors.getIsAdmin(state) && user.tenantId) composedOptions.headers.TenantId = user.tenantId;
  return composedOptions;
}

export function getResourceAddress(resource) {
  let endpoint = BaseUrl;
  if (resource.indexOf('connect/token') >= 0) {
    endpoint = BaseUrl.endsWith('/') ? `${BaseUrl}${resource}` : `${BaseUrl}/${resource}`;
  } else {
    endpoint = BaseUrl.endsWith('/') ? `${BaseUrl}web.api/${resource}` : `${BaseUrl}/web.api/${resource}`;
  }
  return endpoint;
}

const restHttp = {
  get: (resource, options = {}) => axios.get(getResourceAddress(resource), getOptions(options)),
  post: (resource, data, options = {}) => axios.post(getResourceAddress(resource), data, getOptions(options)),
  put: (resource, data, options = {}) => axios.put(getResourceAddress(resource), data, getOptions(options)),
  delete: (resource, options = {}) => axios.delete(getResourceAddress(resource), getOptions(options)),
  pageOptions: (pageIndex, pageSize, sortBy, sortDescending, filters) => ({
    params: {
      pageIndex,
      pageSize,
      sortBy,
      sortDescending,
    },
    headers: {
      filters: filters ? JSON.stringify(filters) : null,
    },
  }),
  pagingHeader: response => JSON.parse(response.headers['x-pagination']),
};

export default restHttp;
