import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators, selectors } from '../../store/modules/User';
import List, { ListPropTypes } from '../../containers/List';
import date from '../../utils/date';
import ListImage from '../../containers/ListImage';

const UserList = props => {
  const {
    list, requestList, switchDisplayStyle, isAdmin, tenantId, switchFilterVisibility,
  } = props;

  const adminMode = isAdmin && !tenantId;

  const columns = [
    {
      id: 'imageId',
      Header: '',
      Cell: row => (
        <ListImage imageSize={32} className="table-image"
          items={list.data} currentItem={row.original} />
      ),
      sortable: false,
      maxWidth: 40,
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
      sortable: true,
    },
    {
      id: 'username',
      Header: 'Username',
      accessor: 'username',
      sortable: true,
    },
    {
      id: 'createdOn',
      Header: 'Created',
      accessor: 'createdOn',
      Cell: cell => date.short(cell.value),
      sortable: true,
      maxWidth: 120,
    },
    {
      id: 'signedInOn',
      Header: 'Signed In',
      accessor: 'signedInOn',
      Cell: cell => (cell.value ? date.long(cell.value) : ''),
      sortable: true,
      maxWidth: 120,
    },
    {
      accessor: 'id',
      Cell: cell => (
        <LinkContainer to={`/user/${cell.value}`} exact>
          <div className="gaas-tile-btn">See More</div>
        </LinkContainer>
      ),
      maxWidth: 150,
    },
  ];
  return (
    <List title={adminMode ? 'Administrators' : 'Users'}
      subtitle={
        adminMode ? 'Administrator is a person with full access to GZone' : 'User is a person with limited access to GZone'
      }
      requestList={requestList}
      {...list.paging}
      preferTable={list.preferTable}
      filters={list.filters}
      tableData={list.data}
      tableColumns={columns}
      switchDisplayStyle={switchDisplayStyle}
      switchFilterVisibility={switchFilterVisibility}
      newLink="/user/new">
      {list.data.map(user => (
        <div key={user.id}>
          <div className="gaas-tile">
            <div className="gaas-tile-image">
              <ListImage items={list.data} currentItem={user}
                className={user.isDisabled ? 'avatar lg disabled' : undefined} />
            </div>
            <div className="gaas-tile-heading h-img">
              <span className="text-ellipsis">{user.name}</span>
            </div>
            <div className="gaas-tile-body">
              <div className="m-t-md m-b-md padder">
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-screenshot m-r-sm" />
                    <span className="text-underline">Username</span>
                    {' : '}
                    <span>{user.username}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <i className="glyphicon glyphicon-knight m-r-sm" />
                    <span className="text-underline">Last Signed In</span>
                    {' : '}
                    <span>{user.signedInOn ? date.short(user.signedInOn) : '-'}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="gaas-tile-footer m-t-sm" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
              <LinkContainer to={`/user/${user.id}`} exact>
                <div className="gaas-tile-btn">See More</div>
              </LinkContainer>
            </div>
            <div className="clear" />
          </div>
        </div>
      ))}
    </List>
  );
};

UserList.propTypes = {
  list: ListPropTypes.isRequired,
  requestList: PropTypes.func.isRequired,
  switchDisplayStyle: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  tenantId: PropTypes.number,
  switchFilterVisibility: PropTypes.func.isRequired,
};

UserList.defaultProps = {
  tenantId: null,
};

export default connect(
  state => ({
    list: state.user.list,
    isAdmin: selectors.getIsAdmin(state),
    tenantId: state.user.tenantId,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(UserList);
