import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { bindActionCreators } from 'redux';
import SelectField from '../../controls/SelectField';
import { Period, DataType, ConvertToOptions } from '../../utils/enum';
import DataTypeField from '../../controls/DataTypeField';
import { actionCreators as attributeActionCreators } from '../../store/modules/GameAttribute';

class GameSettings extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    const {
      requestAllAttributes,
    } = this.props;

    requestAllAttributes();

    if (this.form && this.form.current) {
      this.form.current.validateForm();
    }
  }

  componentDidUpdate() {
    const { game } = this.props;

    if (this.form && this.form.current) {
      this.form.current.validateForm(game);
    }
  }

  handleAttributeChange = (value, field) => {
    const {
      game, onChange,
    } = this.props;

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;
    if (activeParticipant)
      activeParticipant.attributes[field] = value;
    else
      game.attributes[field] = value;
    onChange();
  }

  handleFieldChange = (value, field) => {
    const {
      game, onChange,
    } = this.props;

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;
    if (activeParticipant)
      activeParticipant[field] = value;
    else
      game[field] = value;
    onChange();
  }

  testStartOnEndOn(endOn) {
    return !this.parent.startOn || !endOn || this.parent.startOn < endOn;
  }

  render() {
    const {
      game, attributes, isGameMode,
    } = this.props;

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

    const attributesFiltered = attributes.filter(a => !a.isSystem && ((game && game.attributes[a.id]) || !a.isDisabled));

    return (
      <div className="game-settings">
        <Formik enableReinitialize
          innerRef={this.form}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .max(50)
              .required(),
            description: Yup.string().nullable(),
            maxOccurrencesPlayer: Yup.number()
              .integer()
              .min(1)
              .nullable(),
            maxOccurrencesGlobal: Yup.number()
              .integer()
              .min(1)
              .nullable(),
            startOn: Yup.date().nullable(),
            endOn: Yup.date()
              .nullable()
              .test('endOn-startOn', 'End date must be greater than start date', this.testStartOnEndOn),
            period: Yup.number()
              .integer()
              .min(1)
              .nullable(),
            attributes: Yup.object().shape(
              attributes.reduce((obj, attribute) => {
                const result = obj;
                if (attribute.dataType === DataType.Number) {
                  result[attribute.id] = Yup.number().nullable();
                } else if (attribute.dataType === DataType.Boolean) {
                  result[attribute.id] = Yup.boolean().nullable();
                } else if (attribute.dataType === DataType.Date) {
                  result[attribute.id] = Yup.date().nullable();
                } else {
                  result[attribute.id] = Yup.string().nullable();
                }
                return result;
              }, {}),
            ),
          })}
          initialValues={activeParticipant ? { ...activeParticipant } : { ...game }}>
            {({ values, errors }) => (
            <Form>
              <div id="settingForm">
                <div className="row">
                  <div className="col-xs-12">
                    <h4>General</h4>
                  </div>
                </div>
                {
                  !activeParticipant && 
                  <div className="row">
                    <div className="col-xs-6">
                      <div className="form-group form-group-lg">
                        <label htmlFor="name" className="control-label font-bold">
                          Name
                          <Field id="name"
                            name="name"
                            autoFocus
                            onChange={e => this.handleFieldChange(e.target.value, e.target.name)}
                            className={errors.name ? 'form-control error' : 'form-control'} />
                        </label>
                      </div>
                    </div>
                  </div>
                }
                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group form-group-lg">
                      <label htmlFor="description" className="control-label font-bold">
                        Description
                        <Field id="description"
                          name="description"
                          value={values.description || ''}
                          onChange={e => this.handleFieldChange(e.target.value, e.target.name)}
                          className={errors.description ? 'form-control error' : 'form-control'} />
                      </label>
                    </div>
                  </div>
                </div>
                {
                  !activeParticipant &&
                  <>
                    <div className="row">
                      <div className="col-xs-12">
                        <h4>Settings</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <div className="form-group form-group-lg">
                          <label htmlFor="startOn" className="control-label font-bold">
                            Start On
                            <Field id="startOn"
                              name="startOn"
                              component={DataTypeField}
                              dataType={DataType.Date}
                              isClearable
                              readOnly={!!game.publishedOn}
                              onChange={(form, value) => this.handleFieldChange(value, 'startOn')}
                              className={errors.startOn ? 'form-control error' : 'form-control'} />
                          </label>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className="form-group form-group-lg">
                          <label htmlFor="endOn" className="control-label font-bold">
                            End On
                            <Field id="endOn"
                              name="endOn"
                              component={DataTypeField}
                              dataType={DataType.Date}
                              isClearable
                              readOnly={!!game.publishedOn}
                              onChange={(form, value) => this.handleFieldChange(value, 'endOn')}
                              className={errors.endOn ? 'form-control error' : 'form-control'} />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <div className="form-group form-group-lg">
                          <label htmlFor="period" className="control-label font-bold">
                            Period
                            <Field id="period"
                              name="period"
                              component={SelectField}
                              options={ConvertToOptions(Period)}
                              isClearable
                              isDisabled={game.publishedOn}
                              onChange={(form, value) => this.handleFieldChange(value, 'period')}
                              className={errors.period ? 'form-control error' : 'form-control'} />
                          </label>
                        </div>
                      </div>
                    </div>
                    {
                      isGameMode && 
                      <div className="row">
                        <div className="col-xs-6">
                          <div className="form-group form-group-lg">
                            <label htmlFor="maxOccurrencesGlobal" className="control-label font-bold">
                              Max Occurrences (All Players)
                              <Field id="maxOccurrencesGlobal"
                                name="maxOccurrencesGlobal"
                                type="number"
                                min="1"
                                value={values.maxOccurrencesGlobal || ''}
                                readOnly={game.publishedOn}
                                onChange={e => this.handleFieldChange(e.target.value, e.target.name)}
                                className={errors.maxOccurrencesGlobal ? 'form-control error' : 'form-control'} />
                            </label>
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <div className="form-group form-group-lg">
                            <label htmlFor="maxOccurrencesPlayer" className="control-label font-bold">
                              Max Occurrences (Individual Player)
                              <Field id="maxOccurrencesPlayer"
                                name="maxOccurrencesPlayer"
                                type="number"
                                min="1"
                                value={values.maxOccurrencesPlayer || ''}
                                readOnly={game.publishedOn}
                                onChange={e => this.handleFieldChange(e.target.value, e.target.name)}
                                className={errors.maxOccurrencesPlayer ? 'form-control error' : 'form-control'} />
                            </label>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                }
                {attributesFiltered.length > 0 && (
                  <div className="form-subsection">
                    <h4>Attributes</h4>
                    <div className="row">
                      {attributesFiltered.map(attribute => (
                        <div className="col-xs-6" key={`attributes.${attribute.id}`}>
                          <div className="form-group form-group-lg">
                            <label htmlFor={`attributes.${attribute.id}`} className="control-label font-bold">
                              {attribute.name}
                              <Field id={`attributes.${attribute.id}`}
                                name={`attributes.${attribute.id}`}
                                component={DataTypeField}
                                dataType={attribute.dataType}
                                onChange={(form, value) => this.handleAttributeChange(value, attribute.id)}
                                className={
                                  errors.attributes && errors.attributes[attribute.id]
                                    ? 'form-control error'
                                    : 'form-control'
                                } />
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
          </Formik>
      </div>
    );
  }
}

GameSettings.propTypes = {
  isGameMode: PropTypes.bool.isRequired,
  game: PropTypes.shape({
    id: PropTypes.number, name: PropTypes.string, publishedOn: PropTypes.string, attributes: PropTypes.shape({}),
  }),
  attributes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  onChange: PropTypes.func.isRequired,
  requestAllAttributes: PropTypes.func.isRequired,
};

GameSettings.defaultProps = {
  game: null,
};

export default connect(
  state => ({
    attributes: state.gameAttribute.all,
  }),
  dispatch => bindActionCreators(
    {
      requestAllAttributes: attributeActionCreators.requestAll,
    },
    dispatch,
  ),
)(GameSettings);
