import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { Conjunction } from '../../utils/enum';
import GameRuleUnion from './RuleUnion';
import GameUnionActions from './UnionActions';
import ConjunctionIndicator from '../../controls/ConjunctionIndicator';
import confirm, { NoCancel } from '../../utils/confirm';

class GameRules extends Component {
  removeFromGame = (array, element) => {
    const { onChange } = this.props;

    const index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
      onChange();
    }
  };

  render() {
    const {
      game, onChange, advancedMode, onAdd, onClick, onNotificationClick, simulation,
    } = this.props;

    const activeParticipant = game.gameParticipants.find(p => p.participant.isActive) ?? null;

    const onUnionAddClick = conjunction => {
      const participantId = activeParticipant ? activeParticipant.participant.id : null;

      game.ruleUnions.push({
        id: new Date().getMilliseconds(),
        participantId,
        rules: [],
        games: [],
        childrenConjunction: Conjunction.And,
      });
      game.ruleUnions.filter(o => o.participantId === participantId).forEach(ruleUnion => { ruleUnion.conjunction = conjunction; });
      onChange();
    };

    const onCopy = () => {
      confirm('Are you sure to copy rules from default profile?').then(
        () => {
          const defaultRuleUnions = game.ruleUnions.filter(o => o.participantId === null);
          game.ruleUnions = game.ruleUnions.filter(o => o.participantId !== activeParticipant.participant.id);
          defaultRuleUnions.forEach(o => {
            const games = JSON.parse(JSON.stringify(o.games));
            const rules = JSON.parse(JSON.stringify(o.rules));
            games.forEach(x => { x.unionEntityId = new Date().getMilliseconds(); });
            rules.forEach(x => { x.unionEntityId = new Date().getMilliseconds(); });
            game.ruleUnions.push({
              id: new Date().getMilliseconds(),
              participantId: activeParticipant.participant.id,
              rules,
              games,
              conjunction: o.conjunction,
              childrenConjunction: o.childrenConjunction,
            });
          });
          onChange();
        }, NoCancel,
      );
    };

    const ruleUnions = game.ruleUnions.filter(ru => ru.participantId === (activeParticipant ? activeParticipant.participant.id : null));

    const hasAnyRule = () => {
      let result = false;
      ruleUnions.forEach(union => {
        if (union.rules.length > 0) result = true;
        if (union.games.length > 0) result = true;
      });
      return result;
    };

    return (
      <>
        <div className={advancedMode ? '' : 'rule-group-rules'}>
          <div className={hasAnyRule() ? 'union-title valid' : 'union-title warning'}>
            <i className="glyphicon glyphicon-road" />
            Gameplay
            <span className="warning">{!hasAnyRule() && !activeParticipant ? 'All participants will succeed!' : ''}</span>
            <span className="warning">{!hasAnyRule() && activeParticipant ? 'Default player profile used!' : ''}</span>
          </div>
          <ul className={advancedMode ? 'tree' : 'no-tree'}>
            {(!game.publishedOn || hasAnyRule()) && ruleUnions.map((union, index) => (
              <li key={`union-${uid(union)}`}>
                {index > 0 && (
                  <ConjunctionIndicator conjunction={union.conjunction}
                    onChange={
                      !game.publishedOn
                        ? conjunction => {
                          ruleUnions.forEach(ruleUnion => { ruleUnion.conjunction = conjunction; });
                          onChange();
                        }
                        : null
                    } />
                )}
                <GameRuleUnion union={union}
                  notifications={game.gameNotifications}
                  onChange={onChange}
                  onUnionRemove={() => this.removeFromGame(game.ruleUnions, union)}
                  advancedMode={advancedMode}
                  canEdit={!game.publishedOn}
                  onAdd={onAdd}
                  onClick={onClick}
                  onNotificationClick={onNotificationClick}
                  simulation={simulation}
                  onCopy={activeParticipant && !advancedMode ? onCopy : null} />
              </li>
            ))}
          </ul>

          {advancedMode && !game.publishedOn && (
            <GameUnionActions data={ruleUnions}
              conjunction={
                ruleUnions.length > 0 ? ruleUnions[ruleUnions.length - 1].conjunction : Conjunction.And
              }
              groupMode
              then
              onCopy={activeParticipant ? onCopy : null}
              onSelect={conjunction => onUnionAddClick(conjunction)} />
          )}
        </div>
      </>
    );
  }
}

GameRules.propTypes = {
  game: PropTypes.shape({
    id: PropTypes.number,
    ruleUnions: PropTypes.arrayOf(PropTypes.shape({})),
    gameParticipants: PropTypes.arrayOf(PropTypes.shape({})),
    gameNotifications: PropTypes.arrayOf(PropTypes.shape({})),
    publishedOn: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  advancedMode: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  simulation: PropTypes.shape({}),
};

GameRules.defaultProps = {
  simulation: null,
};

export default GameRules;
