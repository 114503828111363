import { actionCreators as Alert } from './Alert';
import restHttp from '../../utils/restHttp';
import Base from './Base';

const base = new Base('dashboard', 'Dashboard');

const initialState = {
  chart: {},
  total: {},
  top: {},
};

export const actionCreators = {
  requestChart: (period, measure) => dispatch => {
    dispatch({ type: base.customRequestType('chart') });

    restHttp
      .get(`dashboard/chart/${measure}/${period}`)
      .then(response => {
        dispatch({
          type: base.customReceiveType('chart'),
          chart: response.data,
          period,
          measure,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestTotal: (period, measure) => dispatch => {
    dispatch({ type: base.customRequestType('total') });

    restHttp
      .get(`dashboard/total/${measure}/${period}`)
      .then(response => {
        dispatch({
          type: base.customReceiveType('total'),
          total: response.data,
          period,
          measure,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestTop: (period, measure) => dispatch => {
    dispatch({ type: base.customRequestType('top') });

    restHttp
      .get(`dashboard/top/${measure}/${period}`)
      .then(response => {
        dispatch({
          type: base.customReceiveType('top'),
          top: response.data,
          period,
          measure,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  changeLayout: layouts => dispatch => {
    dispatch({ type: base.customType('layout/change'), layouts });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (action.type === base.customReceiveType('chart')) {
    return {
      ...newState,
      chart: { ...newState.chart, [action.measure]: { ...newState.chart[action.measure], [action.period]: action.chart } },
    };
  }

  if (action.type === base.customReceiveType('total')) {
    return {
      ...newState,
      total: { ...newState.total, [action.measure]: { ...newState.total[action.measure], [action.period]: action.total } },
    };
  }

  if (action.type === base.customReceiveType('top')) {
    return {
      ...newState,
      top: { ...newState.top, [action.measure]: { ...newState.top[action.measure], [action.period]: action.top } },
    };
  }

  if (action.type === base.customType('layout/change')) {
    return {
      ...newState,
      layouts: action.layouts,
    };
  }

  return newState;
};
