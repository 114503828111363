import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/modules/Alert';

const Alert = props => {
  const { alert, removeAlert } = props;

  const onToastClose = id => {
    removeAlert(id);
    toast.dismiss(id);
  };

  if (alert.length > 0) {
    const current = alert[0];
    if (!toast.isActive(current.id)) {
      toast(current.description, {
        toastId: current.id,
        type: current.type,
        position: toast.POSITION.BOTTOM_LEFT,
        onClose: () => onToastClose(current.id),
      });
    }
  }

  return <ToastContainer autoClose={5000} toastClassName="notification-toast" />;
};

Alert.propTypes = {
  alert: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, description: PropTypes.string })),
  removeAlert: PropTypes.func.isRequired,
};

Alert.defaultProps = {
  alert: [],
};

export default connect(
  state => ({
    alert: state.alert,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(Alert);
