import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import { actionCreators } from '../../store/modules/Action';
import { actionCreators as actionTypeActionCreators } from '../../store/modules/ActionType';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import SelectField, { Convert } from '../../controls/SelectField';
import DataTypeField from '../../controls/DataTypeField';
import { DataType } from '../../utils/enum';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import helper from '../../utils/helper';

class ActionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {
        name: '',
        playerName: '',
        actionType: { id: null, name: '' },
        fields: [],
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { id, requestGet, requestAllActionTypes } = this.props;

    if (id) {
      requestGet(id);
    } else {
      requestAllActionTypes();
    }
  }

  handleSave(values) {
    const { id, requestPost, onSaveClick } = this.props;

    if (onSaveClick) {
      onSaveClick(values);
      return;
    }

    if (!id) {
      requestPost(values);
    }
  }

  testFieldValue(value) {
    if (!this.parent.dataType || this.parent.dataType === DataType.Text) {
      return Yup.string()
        .nullable()
        .isValid(value);
    }
    if (this.parent.dataType === DataType.Number) {
      return Yup.number()
        .nullable()
        .isValid(value);
    }
    if (this.parent.dataType === DataType.Boolean) {
      return Yup.boolean()
        .nullable()
        .isValid(value);
    }
    if (this.parent.dataType === DataType.Date) {
      return Yup.date()
        .nullable()
        .isValid(value);
    }
    throw Error();
  }

  render() {
    const {
      id, action, actionTypes, onBackClick, isSimulation,
    } = this.props;
    const { template } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          actionType: Yup.object().shape({
            id: Yup.number()
              .integer()
              .required(),
            name: Yup.string().nullable(),
          }),
          playerName: (!id && !isSimulation) ? Yup.string().required() : Yup.string().nullable(),
          fields: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required(),
              value: Yup.string()
                .nullable()
                .test('testFieldValue', 'Wrong data type', this.testFieldValue),
              dataType: Yup.number()
                .integer()
                .required(),
            }),
          ),
        })}
        initialValues={action || template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">{id ? 'View Action' : 'New Action'}</span>
                  <span className="sub-heading hex-img">Action is a player activity that impacts games</span>
                  <div className="pageStyle">
                    <BackButton onClick={onBackClick} />
                    {!id && <SaveButton />}
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={values.player ? [values.player.imageId] : []} form={this.form}
                        single readOnly />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        {!id && (
                          <div className="row">
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="actionType.id" className="control-label font-bold">
                                  Type
                                  <Field id="actionType.id"
                                    name="actionType.id"
                                    component={SelectField}
                                    options={Convert(actionTypes)}
                                    onChange={(form, newValue) => {
                                      form.setFieldValue(
                                        'fields',
                                        newValue ? actionTypes.find(actionType => actionType.id === newValue).fields : [],
                                      );
                                      form.setFieldValue(
                                        'actionType',
                                        newValue ? actionTypes.find(actionType => actionType.id === newValue) : {},
                                      );
                                    }}
                                    className={errors.actionType ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                            {!isSimulation
                                && (
                                  <div className="col-md-6 col-xs-12">
                                    <div className="form-group form-group-lg">
                                      <label htmlFor="playerName" className="control-label font-bold">
                                        Player
                                        <Field id="playerName"
                                          name="playerName"
                                          className={errors.playerName ? 'form-control error' : 'form-control'} />
                                      </label>
                                    </div>
                                  </div>
                                )}
                          </div>
                        )}
                        {
                          values.fields.length > 0
                          && (
                            <div className="form-subsection">
                              <h4>Fields</h4>
                            </div>
                          )
                        }
                        <div className="row">
                          <FieldArray name="fields"
                            render={() => values.fields.map((field, index) => (
                              <div className="col-xs-6" key={field.name}>
                                <div className="form-group form-group-lg">
                                  <label htmlFor={`fields.${index}.value`} className="control-label font-bold">
                                    {field.name}
                                    {field.dataType === DataType.Date
                                    && <span>(in your browser timezone)</span>}
                                    <Field id={`fields.${index}.value`}
                                      name={`fields.${index}.value`}
                                      component={DataTypeField}
                                      dataType={field.dataType}
                                      readOnly={!!id}
                                      className={
                                        errors.fields && errors.fields[index] && errors.fields[index].value
                                          ? 'form-control error'
                                          : 'form-control'
                                      } />
                                  </label>
                                </div>
                              </div>
                            ))} />
                        </div>
                      </div>
                      {id
                        && action && (
                        <div className="col-md-3 form-additional-info">
                          <label>
                            Created
                            <div>{date.long(action.createdOn)}</div>
                          </label>
                          <label>
                            Happened
                            <div>{date.long(action.generatedOn)}</div>
                          </label>
                          {action.processedOn && (
                            <label>
                              Processed
                              <div>{date.long(action.processedOn)}</div>
                            </label>
                          )}
                          <label>
                            Action
                            <div>{action.actionType.name}</div>
                          </label>
                          <label>
                            Player
                            <div>{action.player.name}</div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

ActionEdit.propTypes = {
  id: PropTypes.number,
  action: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestAllActionTypes: PropTypes.func.isRequired,
  actionTypes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  onSaveClick: PropTypes.func,
  onBackClick: PropTypes.func,
  isSimulation: PropTypes.bool,
};

ActionEdit.defaultProps = {
  id: null,
  action: null,
  onSaveClick: null,
  onBackClick: null,
  isSimulation: false,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    action: state.action.details[helper.getIdFromProps(ownProps)],
    actionTypes: state.actionType.all,
  }),
  dispatch => bindActionCreators({ ...actionCreators, requestAllActionTypes: actionTypeActionCreators.requestAll }, dispatch),
)(ActionEdit);
