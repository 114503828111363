import { push } from 'connected-react-router';
import { actionCreators as Alert } from './Alert';
import restHttp from '../../utils/restHttp';
import { DataType } from '../../utils/enum';
import Base from './Base';

const base = new Base('tenant', 'Tenant');

const initialState = {
  list: {
    data: [],
    paging: {},
    preferTable: false,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Disabled',
        column: 'IsDisabled',
        type: DataType.Boolean,
        operator: '=',
        value: null,
      },
      {
        name: 'Name',
        column: 'Name',
        type: DataType.Text,
        operator: 'Contains',
        value: null,
      }],
    },
  },
  all: [],
  details: {},
  timezones: [],
};

export const actionCreators = {
  ...base.actionCreators,
  requestGet: id => dispatch => {
    dispatch({ type: base.requestGetType });

    restHttp
      .get(id ? `tenant/${id}` : 'tenant/profile')
      .then(response => {
        dispatch({
          type: base.receiveGetType,
          current: response.data,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestPut: (id, entity) => dispatch => {
    dispatch({ type: base.requestPutType });

    restHttp
      .put(id ? `tenant/${id}` : 'tenant/profile', entity)
      .then(response => {
        dispatch({
          type: base.receivePutType,
          current: response.data,
        });
        dispatch(Alert.addSuccess('Tenant updated!'));
        if (id) dispatch(push('/tenant'));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestDelete: (id, deleteProgress, deletePlayers, deletePlayerSetup, deleteGameSetup, deleteTenant) => dispatch => {
    dispatch({ type: base.requestDeleteType });

    restHttp
      .delete(
        `tenant/${id}?deleteProgress=${deleteProgress}&deletePlayers=${deletePlayers}&deletePlayerSetup=${deletePlayerSetup}&deleteGameSetup=${deleteGameSetup}&deleteTenant=${deleteTenant}`,
      )
      .then(() => {
        dispatch({
          type: base.receiveDeleteType,
          id,
          deleteTenant,
        });
        dispatch(Alert.addSuccess('Tenant data deleted!'));
        dispatch(push('/tenant'));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestReprocess: id => dispatch => {
    dispatch({ type: base.customRequestType('reprocess') });

    restHttp
      .put(
        `tenant/${id}/reprocess`,
      )
      .then(() => {
        dispatch({
          type: base.customReceiveType('reprocess'),
        });
        dispatch(Alert.addSuccess('Player progress removed, reprocess will start shortly if tenant is enabled!'));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestReprocessBlockchain: (id, addNonBlockchainTransaction) => dispatch => {
    dispatch({ type: base.customRequestType('reprocess-blockchain') });

    restHttp
      .put(
        `tenant/${id}/blockchain/reprocess?addNonBlockchainTransaction=${addNonBlockchainTransaction}`,
      )
      .then(() => {
        dispatch({
          type: base.customReceiveType('reprocess-blockchain'),
        });
        dispatch(Alert.addSuccess('Blockchain transactions reset, reprocess will start shortly if tenant is enabled!'));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestTimezoneList: () => dispatch => {
    dispatch({ type: base.customRequestType('timezone') });

    restHttp
      .get('tenant/timezone')
      .then(response => {
        dispatch({
          type: base.customReceiveType('timezone'),
          timezones: response.data,
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (action.type === base.customReceiveType('timezone')) {
    return {
      ...newState,
      timezones: action.timezones,
    };
  }

  // special case for admin after adding tenant
  if (action.type === base.receivePostType) {
    return {
      ...newState,
      details: {
        ...newState.details,
        [action.current.id]: action.current,
      },
      all: [action.current, ...newState.all],
    };
  }

  // special case for admin after deleting tenant
  if (action.type === base.receiveDeleteType && action.deleteTenant) {
    return {
      ...newState,
      current: null,
      all: newState.all.filter(tenant => tenant.id !== action.id),
    };
  }

  return base.reducer(newState, action);
};
