import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Formik, Form, Field,
} from 'formik';
import { AnimatePresence } from 'framer-motion';
import animate from '../utils/animate';
import DataTypeField from '../controls/DataTypeField';
import SelectField, { Convert } from '../controls/SelectField';
import { actionCreators as actionTypeActionCreators } from '../store/modules/ActionType';
import { actionCreators as gameActionCreators } from '../store/modules/Game';
import { actionCreators as leaderboardActionCreators } from '../store/modules/Leaderboard';
import { actionCreators as groupActionCreators } from '../store/modules/Group';
import { actionCreators as filterActionCreators } from '../store/modules/Filter';
import { actionCreators as rewardActionCreators } from '../store/modules/Reward';
import { ConvertToOptions } from '../utils/enum';

class ListFilter extends Component {
  componentDidMount() {
    const { filters } = this.props;

    this.loadDataSource(filters);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { filters } = this.props;

    if (newProps.filters && newProps.filters.isVisible && filters && !filters.isVisible) {
      this.loadDataSource(newProps.filters);
    }
  }

  loadDataSource = filters => {
    const { dispatch, dataSource } = this.props;

    if (filters && filters.isVisible) {
      filters.fields.forEach(field => {
        if (field.dataSourceKey === 'actionType' && dataSource[field.dataSourceKey].length === 0) {
          dispatch(actionTypeActionCreators.requestAll());
        } else if (field.dataSourceKey === 'game&leaderboard' && dataSource[field.dataSourceKey].length === 0) {
          dispatch(gameActionCreators.requestAll());
          dispatch(leaderboardActionCreators.requestAll());
        } else if (field.dataSourceKey === 'game' && dataSource[field.dataSourceKey].length === 0) {
          dispatch(gameActionCreators.requestAll());
        } else if (field.dataSourceKey === 'leaderboard' && dataSource[field.dataSourceKey].length === 0) {
          dispatch(leaderboardActionCreators.requestAll());
        } else if (field.dataSourceKey === 'group' && dataSource[field.dataSourceKey].length === 0) {
          dispatch(groupActionCreators.requestAll());
        } else if (field.dataSourceKey === 'filter' && dataSource[field.dataSourceKey].length === 0) {
          dispatch(filterActionCreators.requestAll());
        } else if (field.dataSourceKey === 'reward' && dataSource[field.dataSourceKey].length === 0) {
          dispatch(rewardActionCreators.requestAll());
        }
      });
    }
  }

  render() {
    const { filters, handleFilterChange, dataSource } = this.props;

    return (
      <Formik initialValues={{}}>
        <Form className="filters row">
          <AnimatePresence>
            {filters && filters.isVisible && filters.fields && (
              <animate.filter key="filterPose">
                {
                  filters.fields.map(field => (
                    <div className="col-xs-4" key={field.name}>
                      <div className="form-group form-group-lg">
                        <label htmlFor={field.name} className="control-label font-bold">
                          {field.name}
                          <Field id={field.name}
                            name={field.name}
                            component={field.dataSourceKey ? SelectField : DataTypeField}
                            options={field.dataSourceKey && dataSource[field.dataSourceKey] ? dataSource[field.dataSourceKey] : []}
                            dataType={field.type}
                            field={field}
                            onChange={(form, val) => {
                              handleFilterChange(field, val ?? null);
                            }}
                            className="form-control" />
                        </label>
                      </div>
                    </div>
                  ))
                }
              </animate.filter>
            )}
          </AnimatePresence>
        </Form>
      </Formik>
    );
  }
}

ListFilter.propTypes = {
  filters: PropTypes.shape({ fields: PropTypes.arrayOf(PropTypes.shape({})), isVisible: PropTypes.bool }),
  dataSource: PropTypes.shape({}),
  handleFilterChange: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

ListFilter.defaultProps = {
  filters: null,
  dataSource: {},
  handleFilterChange: null,
};

export default connect((state, ownProps) => {
  const dataSource = {};
  if (ownProps.filters) {
    ownProps.filters.fields.forEach(field => {
      if (field.enum) {
        field.dataSourceKey = new Date().getDate();
        dataSource[field.dataSourceKey] = ConvertToOptions(field.enum);
      } else if (field.dataSourceKey === 'game&leaderboard') {
        dataSource[field.dataSourceKey] = [...Convert(state.game.all), ...Convert(state.leaderboard.all)];
      } else if (field.dataSourceKey) {
        dataSource[field.dataSourceKey] = Convert(state[field.dataSourceKey].all);
      }
    });
  }
  return { dataSource };
})(ListFilter);
