import React from 'react';
import { connect } from 'react-redux';
import './Dashboard.css';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { actionCreators } from '../../store/modules/Dashboard';
import DashboardChart from './Chart';
import DashboardTotal from './Total';
import DashboardTop from './Top';
import DashboardTile from './Tile';
import {
  DashboardTileType, ChartPeriod, ChartType, ChartMeasure, TopPeriod, TopMeasure,
} from '../../utils/enum';

const ResponsiveGridLayout = WidthProvider(Responsive);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compactType: 'vertical',
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  onCompactTypeChange = () => {
    const { compactType } = this.state;
    if (compactType === 'horizontal') {
      this.setState({ compactType: 'vertical' });
    }
    if (compactType === 'vertical') {
      this.setState({ compactType: null });
    }
    if (!compactType) {
      this.setState({ compactType: 'horizontal' });
    }
  };

  onTileClose = key => {
    const { layouts, changeLayout } = this.props;

    const lg = layouts.lg.filter(layout => layout.i !== key);
    const md = layouts.md.filter(layout => layout.i !== key);
    changeLayout({ lg, md });
  };

  hasChanges = newLayouts => {
    const { layouts } = this.props;

    for (let index = 0; index < layouts.lg.length; index += 1) {
      const layout = layouts.lg[index];
      const newLayout = newLayouts.lg.find(l => l.i === layout.i);
      if (layout.x !== newLayout.x || layout.y !== newLayout.y || layout.h !== newLayout.h || layout.w !== newLayout.w) {
        return true;
      }
    }

    return false;
  };

  onLayoutChange = (currentLayout, allLayouts) => {
    const { changeLayout } = this.props;

    if (this.hasChanges(allLayouts)) {
      changeLayout(allLayouts);
    }
  };

  renderTile(layout) {
    const split = layout.i.split('|');
    const tile = Number(split[1]);
    const measure = Number(split[2]);
    const period = Number(split[3]);
    const type = Number(split[4]);

    return (
      <div key={layout.i}>
        {tile === DashboardTileType.Chart && (
          <DashboardTile title={ChartMeasure[measure]} subtitle={ChartPeriod[period]}
            onClose={() => this.onTileClose(layout.i)}>
            <DashboardChart measure={measure} period={period}
              type={type} />
          </DashboardTile>
        )}
        {tile === DashboardTileType.Total && (
          <DashboardTile title={ChartMeasure[measure]} subtitle={TopPeriod[period]}
            onClose={() => this.onTileClose(layout.i)}>
            <DashboardTotal measure={measure} period={period} />
          </DashboardTile>
        )}
        {tile === DashboardTileType.Top && (
          <DashboardTile title={TopMeasure[measure]} subtitle={TopPeriod[period]}
            onClose={() => this.onTileClose(layout.i)}>
            <DashboardTop measure={measure} period={period} />
          </DashboardTile>
        )}
      </div>
    );
  }

  render() {
    const { mounted, compactType } = this.state;
    const { layouts } = this.props;

    return (
      <div className="dashboard-container">
        <ResponsiveGridLayout cols={{
          lg: 12, md: 10, sm: 6, xs: 4, xxs: 2,
        }}
          breakpoints={{
          lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0,
        }}
          rowHeight={50}
          layouts={layouts}
          onLayoutChange={this.onLayoutChange}
          measureBeforeMount={false}
          useCSSTransforms={mounted}
          compactType={compactType}
          preventCollision={!compactType}>
          {layouts.lg.map(layout => this.renderTile(layout))}
        </ResponsiveGridLayout>
      </div>
    );
  }
}

const mediumLayout = [
  {
    x: 0,
    y: 0,
    w: 3,
    h: 3,
    minH: 3,
    minW: 2,
    i: `0|${DashboardTileType.Chart}|${ChartMeasure.ExperienceEarned}|${ChartPeriod.Monthly}|${ChartType.Bar}`,
  },
  {
    x: 4,
    y: 0,
    w: 5,
    h: 3,
    minH: 3,
    minW: 2,
    i: `1|${DashboardTileType.Chart}|${ChartMeasure.PlayersCreated}|${ChartPeriod.Monthly}|${ChartType.Line}`,
  },
  {
    x: 2,
    y: 3,
    w: 5,
    h: 3,
    minH: 3,
    minW: 2,
    i: `2|${DashboardTileType.Chart}|${ChartMeasure.GameRoundsCompleted}|${ChartPeriod.Weekly}|${ChartType.Area}`,
  },
  {
    x: 7,
    y: 3,
    w: 2,
    h: 2,
    minH: 2,
    minW: 2,
    i: `7|${DashboardTileType.Total}|${ChartMeasure.GamesPublished}|${TopPeriod.AllTIme}|`,
  },
  {
    x: 7,
    y: 3,
    w: 2,
    h: 2,
    minH: 2,
    minW: 2,
    i: `8|${DashboardTileType.Total}|${ChartMeasure.LeaderboardsPublished}|${TopPeriod.AllTIme}|`,
  },
  {
    x: 3,
    y: 6,
    w: 3,
    h: 5,
    minH: 3,
    minW: 2,
    i: `3|${DashboardTileType.Chart}|${ChartMeasure.ActionsCreated}|${ChartPeriod.Daily}|${ChartType.Pie}`,
  },
  {
    x: 1,
    y: 6,
    w: 2,
    h: 2,
    minH: 2,
    minW: 2,
    i: `4|${DashboardTileType.Total}|${ChartMeasure.ActionsCreated}|${TopPeriod.Yesterday}|`,
  },
  {
    x: 0,
    y: 8,
    w: 3,
    h: 4,
    minH: 2,
    minW: 2,
    i: `5|${DashboardTileType.Total}|${ChartMeasure.ActionsCreated}|${TopPeriod.Today}|`,
  },
  {
    x: 6,
    y: 6,
    w: 3,
    h: 2,
    minH: 2,
    minW: 2,
    i: `6|${DashboardTileType.Top}|${TopMeasure.RoundsCompletedByGame}|${TopPeriod.AllTIme}|`,
  },
];

Dashboard.propTypes = {
  changeLayout: PropTypes.func.isRequired,
  layouts: PropTypes.shape({}),
};

Dashboard.defaultProps = {
  layouts: {
    lg: mediumLayout.map(l => ({ ...l, x: l.x + 1 })),
    md: mediumLayout,
  },
};

export default connect(
  state => ({
    layouts: state.dashboard.layouts,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(Dashboard);
