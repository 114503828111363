import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { Conjunction } from '../utils/enum';

const ConjunctionIndicator = props => {
  const { conjunction, onChange, then } = props;

  if (onChange) {
    return (
      <div className="conjunction-container">
        <Popup trigger={<div className="conjunction-indicator">{Conjunction[conjunction]}</div>}
          on="hover"
          position="top left"
          mouseLeaveDelay={300}
          closeOnDocumentClick
          className="conjunction-container">
          <div className="conjunctions">
            <button type="button" onClick={() => onChange(Conjunction.And)}>
              {Conjunction[Conjunction.And]}
            </button>
            <button type="button" onClick={() => onChange(Conjunction.Or)}>
              {Conjunction[Conjunction.Or]}
            </button>
            {then && (
              <button type="button" onClick={() => onChange(Conjunction.Then)}>
                {Conjunction[Conjunction.Then]}
              </button>
            )}
          </div>
        </Popup>
      </div>
    );
  }
  return <div className="conjunction-indicator">{Conjunction[conjunction]}</div>;
};

ConjunctionIndicator.propTypes = {
  conjunction: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  then: PropTypes.bool,
};

ConjunctionIndicator.defaultProps = {
  onChange: null,
  then: true,
};

export default ConjunctionIndicator;
