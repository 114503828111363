import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/modules/Player';
import List, { ListPropTypes } from '../../containers/List';
import date from '../../utils/date';

const PlayerMappingList = props => {
  const {
    id, list, requestMappingList,
  } = props;

  const columns = [
    {
      id: 'unitNo',
      Header: 'Device',
      accessor: 'unitNo',
      sortable: true,
    },
    {
      id: 'vehicleNo',
      Header: 'Vehicle',
      accessor: 'vehicleNo',
      sortable: true,
    },
    {
      id: 'policyNo',
      Header: 'Policy',
      accessor: 'policyNo',
      sortable: true,
    },
    {
      id: 'customerNo',
      Header: 'Customer',
      accessor: 'customerNo',
      Cell: cell => (cell.value ? cell.value : '-'),
      sortable: true,
    },
    {
      id: 'startOn',
      Header: 'Start',
      accessor: 'startOn',
      Cell: cell => date.long(cell.value),
      sortable: true,
      maxWidth: 165,
    },
    {
      id: 'endOn',
      Header: 'End',
      accessor: 'endOn',
      Cell: cell => (cell.value ? date.long(cell.value) : '-'),
      sortable: true,
      maxWidth: 165,
    },
  ];
  return (
    <List title="Player Mappings"
      subtitle="Player Mapping is a connection between unit, vehicle, policy, customer and driver"
      requestList={(pageIndex, pageSize, sortBy, sortDescending, filters) => requestMappingList(id, pageIndex, pageSize, sortBy, sortDescending, filters)}
      {...list.paging}
      preferTable={list.preferTable}
      tableData={list.data}
      tableColumns={columns} />
  );
};

PlayerMappingList.propTypes = {
  id: PropTypes.number.isRequired,
  list: ListPropTypes.isRequired,
  requestMappingList: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    list: state.player.mappingList,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(PlayerMappingList);
