import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { actionCreators } from '../store/modules/File';
import ImageControl, { GetImageSrc } from './Image';
import { actionCreators as alertActionCreators } from '../store/modules/Alert';

class ImageUpload extends Component {
  dropRef = React.createRef();

  dragCounter = 0;

  constructor(props) {
    super(props);
    this.state = {
      drag: false,
      section: 'image',
    };
  }

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter -= 1;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.handleFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  handleFileClick = images => {
    const { section } = this.state;
    const { openImages } = this.props;

    if (section === 'image') {
      openImages(images.map(image => ({ imageId: image })));
    } else if (section === 'video') {
      images.forEach(image => window.open(GetImageSrc(image), '_blank'));
    }
  }

  handleFileDelete = () => {
    const { section } = this.state;
    const {
      single, form, onImageChange, onVideoChange,
    } = this.props;

    if (form) {
      if (section === 'image') {
        form.current.setFieldValue(single ? 'imageId' : 'imageIds', single ? null : []);
      } else if (section === 'video') {
        form.current.setFieldValue(single ? 'videoId' : 'videoIds', single ? null : []);
      }
    }

    if (section === 'image' && onImageChange) {
      onImageChange(null);
    } else if (section === 'video' && onVideoChange) {
      onVideoChange(null);
    }
  }

  handleFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.handleFile(file);
    }
  }

  handleFile(file) {
    const { section } = this.state;
    const {
      form, single, requestFilePost, addError, onImageChange, onVideoChange, addInfo,
    } = this.props;

    const imageErrorMessage = 'Please select a valid image (.jpg, .png, .gif) with min size 100x100 and aspect ratio 1:1.';
    const imageRegex = new RegExp('([a-zA-Z0-9s_\\.-:])+(.jpg|.jpeg|.png|.gif)$');
    const videoErrorMessage = 'Please select a valid video (.mp4).';
    const videoRegex = new RegExp('([a-zA-Z0-9s_\\.-:])+(.mp4)$');

    if (section === 'image') {
      if (imageRegex.test(file.name.toLowerCase())) {
        const reader = new FileReader();
        reader.onload = e => {
          const image = new Image();
          image.onload = ev => {
            const { height } = ev.target;
            const { width } = ev.target;
            if (height < 100 || width < 100 || height !== width) {
              addError(imageErrorMessage);
            } else {
              requestFilePost(file, id => {
                if (form) {
                  form.current.setFieldValue(
                    single ? 'imageId' : 'imageIds',
                    single ? id : [...form.current.state.values.imageIds, id],
                  );
                }
                if (onImageChange) onImageChange(id);
              });
            }
          };
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        addError(imageErrorMessage);
      }
    } else if (section === 'video') {
      if (videoRegex.test(file.name.toLowerCase())) {
        requestFilePost(file, id => {
          if (form) {
            form.current.setFieldValue(
              single ? 'videoId' : 'videoIds',
              single ? id : [...form.current.state.values.videoIds, id],
            );
          }
          if (onVideoChange) onVideoChange(id);
          addInfo('Thumbnail generation for video will take a while and will not be displayed immediately.');
        });
      } else {
        addError(videoErrorMessage);
      }
    }
  }

  render() {
    const { drag, section } = this.state;
    const {
      imageIds, videoIds, single, readOnly, allowVideo,
    } = this.props;

    let images = section === 'image' ? imageIds : videoIds;

    if (single) images = images.length > 0 && images[0] ? [images[0]] : [];

    return (
      <div className="img-well" ref={this.dropRef}>
        <button type="button"
          className="image-wrapper"
          onClick={() => this.handleFileClick(images)}
          style={{ cursor: images.length > 0 ? 'pointer' : 'unset' }}>
          <ImageControl src={images.length > 0 ? GetImageSrc(images[images.length - 1], 150) : null}
            alt="upload"
            className="avatar xl" />
        </button>

        {drag && (
          <div className="image-drop" title="Drop Image">
            <span className="glyphicon glyphicon-download-alt" />
          </div>
        )}

        {!readOnly && (
          <label htmlFor="file-upload" className="custom-file-upload">
            {section === 'image' ? 'Add Image' : 'Add Video'}
            <input type="file"
              id="file-upload"
              accept={section === 'image' ? '.png, .jpg, .jpeg, .gif' : '.mp4'}
              onChange={event => this.handleFileSelect(event)} />
          </label>
        )}
        {images.length > 0
          && !readOnly && (
          <button type="button"
            className="glyphbutton clear-file"
            title="Delete"
            onClick={this.handleFileDelete}>
            <span className="btn-sm btn-danger link glyphicon glyphicon-remove pull-right" />
          </button>
        )}
        {allowVideo && (
          <div className="panel-menu image-menu">
            <div className={section === 'image' ? 'active' : ''}>
              <button type="button"
                onClick={() => this.setState({ section: 'image' })}
                className="glyphbutton"
                title="Image">
                <i className="glyphicon glyphicon-camera" />
              </button>
            </div>
            <div className={section === 'video' ? 'active' : ''}>
              <button type="button"
                onClick={() => this.setState({ section: 'video' })}
                className="glyphbutton"
                title="Video">
                <i className="glyphicon glyphicon-facetime-video" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ImageUpload.propTypes = {
  imageIds: PropTypes.arrayOf(PropTypes.string),
  requestFilePost: PropTypes.func.isRequired,
  form: PropTypes.shape(),
  single: PropTypes.bool,
  readOnly: PropTypes.bool,
  onImageChange: PropTypes.func,
  onVideoChange: PropTypes.func,
  addError: PropTypes.func.isRequired,
  addInfo: PropTypes.func.isRequired,
  openImages: PropTypes.func.isRequired,
  allowVideo: PropTypes.bool,
  videoIds: PropTypes.arrayOf(PropTypes.string),
};

ImageUpload.defaultProps = {
  imageIds: [],
  videoIds: [],
  single: false,
  readOnly: false,
  onImageChange: null,
  onVideoChange: null,
  form: null,
  allowVideo: false,
};

export default connect(
  null,
  dispatch => bindActionCreators(
    {
      ...actionCreators,
      requestFilePost: actionCreators.requestPost,
      addError: alertActionCreators.addError,
      addInfo: alertActionCreators.addInfo,
    },
    dispatch,
  ),
)(ImageUpload);
