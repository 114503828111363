import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { SettingType, DataType } from '../utils/enum';
import DataTypeField from '../controls/DataTypeField';

const SettingsTemplate = props => {
  const { settings, errors } = props;
  return (
    <>
      <FieldArray name="settings"
        render={() => settings.map((setting, index) => (
          <div className="col-xs-6" key={SettingType[setting.settingType]}>
            <div className="form-group form-group-lg">
              <label htmlFor={`settings.${index}.value`} className="control-label font-bold">
                {SettingType[setting.settingType]}
                <Field id={`settings.${index}.value`}
                  name={`settings.${index}.value`}
                  component={DataTypeField}
                  dataType={setting.dataType}
                  className={
                    errors.settings && errors.settings[index] && errors.settings[index].value
                      ? 'form-control error'
                      : 'form-control'
                  } />
              </label>
            </div>
          </div>
        ))} />
    </>
  );
};

SettingsTemplate.propTypes = {
  settings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errors: PropTypes.shape({ settings: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })) }).isRequired,
};

export default SettingsTemplate;

export const SettingsValidationRules = settings => {
  if (!settings) return null;

  return Yup.array().of(
    Yup.object().shape({
      value: Yup.mixed().when('dataType', (dataType, schema) => {
        if (dataType === DataType.Number) return Yup.number().required();
        if (dataType === DataType.Boolean) return Yup.boolean().required();

        return schema;
      }),
    }),
  );
};
