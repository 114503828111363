import { DataType } from '../../utils/enum';
import Base from './Base';

const base = new Base('rule/game', 'Rule');

const initialState = {
  list: {
    data: [],
    paging: {},
    preferTable: false,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Disabled',
        column: 'IsDisabled',
        type: DataType.Boolean,
        operator: '=',
        value: null,
      }, {
        name: 'Action Type',
        column: 'ActionTypeId',
        type: DataType.Number,
        operator: '=',
        value: null,
        dataSourceKey: 'actionType',
      }, {
        name: 'Filter',
        column: 'RuleFilters.FilterId',
        type: DataType.Number,
        operator: 'Any',
        value: null,
        dataSourceKey: 'filter',
      }],
    },
  },
  all: [],
  details: {},
};

export const actionCreators = {
  ...base.actionCreators,
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  return base.reducer(newState, action);
};
