import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/modules/Dashboard';
import DashboardTotal from './Total';

class DashboardTop extends React.Component {
  componentDidMount() {
    const { requestTop, period, measure } = this.props;

    requestTop(period, measure);
  }

  render() {
    const { top, period, measure } = this.props;

    const measureData = top[measure] ? top[measure] : {};
    const data = measureData[period] ? measureData[period] : [];

    if (data.length === 0) { return (<DashboardTotal period={period} measure={0} />); }

    return (
      <div className="dashboard-top">
        {data.map((d, index) => (
          <div key={d.name}>
            {index + 1}. {d.name} ({d.value})
          </div>
        ))}
      </div>
    );
  }
}

DashboardTop.propTypes = {
  period: PropTypes.number.isRequired,
  measure: PropTypes.number.isRequired,
  requestTop: PropTypes.func.isRequired,
  top: PropTypes.shape({}).isRequired,
};

export default connect(
  state => ({
    top: state.dashboard.top,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(DashboardTop);
