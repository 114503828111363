import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { actionCreators, selectors } from '../../store/modules/User';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import SettingsTemplate, { SettingsValidationRules } from '../../templates/Settings';
import helper from '../../utils/helper';

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {
        name: '',
        username: '',
        email: '',
        password: '',
        imageId: '',
        settings: [],
      },
      adminMode: props.isAdmin && !props.tenantId,
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { id, requestGet } = this.props;

    if (id) {
      requestGet(id);
    }
  }

  handleSave(values) {
    const { id, requestPost, requestPut } = this.props;

    if (id) {
      requestPut(id, values);
    } else {
      requestPost(values);
    }
  }

  handleStatus(enabled) {
    const { id, requestEnable, requestDisable } = this.props;

    if (enabled) {
      requestEnable(id);
    } else {
      requestDisable(id);
    }
  }

  render() {
    const { id, user } = this.props;
    const { template, adminMode } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          username: Yup.string()
            .max(50)
            .required(),
          password: id
            ? Yup.string()
              .min(6)
              .max(50)
              .nullable()
            : Yup.string()
              .min(6)
              .max(50)
              .required(),
          name: Yup.string()
            .max(50)
            .required(),
          email: Yup.string()
            .email()
            .max(50)
            .required(),
          imageId: Yup.string().nullable(),
          settings: SettingsValidationRules(user ? user.settings : null),
        })}
        initialValues={user || template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">
                    {id ? 'Edit' : 'New'} {adminMode ? 'Administrator' : 'User'}
                  </span>
                  <span className="sub-heading hex-img">
                    {adminMode
                      ? 'Administrator is a person with full access to GZone'
                      : 'User is a person with limited access to GZone'}
                  </span>
                  <div className="pageStyle">
                    <BackButton />
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} form={this.form}
                        single />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="username" className="control-label font-bold">
                                Username
                                <Field id="username"
                                  name="username"
                                  autoFocus
                                  autoComplete="off"
                                  className={errors.username ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="password" className="control-label font-bold">
                                Password
                                <Field id="password"
                                  value={values.password || ''}
                                  name="password"
                                  type="password"
                                  autoComplete="new-password"
                                  placeholder={id ? 'Leave empty to skip' : ''}
                                  className={errors.password ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="name" className="control-label font-bold">
                                Name
                                <Field id="name" name="name"
                                  className={errors.name ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="email" className="control-label font-bold">
                                Email
                                <Field id="email"
                                  name="email"
                                  type="email"
                                  className={errors.email ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        {id && (
                          <div className="form-subsection">
                            <h4>Settings</h4>
                            <div className="row">
                              <SettingsTemplate settings={values.settings} errors={errors} />
                            </div>
                          </div>
                        )}
                      </div>
                      {id
                        && user && (
                        <div className="col-md-3 form-additional-info">
                          <label>
                            Created
                            <div>{date.long(user.createdOn)}</div>
                          </label>
                          <label>
                            Singed In
                            <div>{user.signedInOn ? date.long(user.signedInOn) : '-'}</div>
                          </label>
                          <label htmlFor="status-switch">
                            Disabled
                            <div>
                              <Switch id="status-switch"
                                onChange={() => this.handleStatus(user.isDisabled)}
                                checked={user.isDisabled}
                                checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

UserEdit.propTypes = {
  id: PropTypes.number,
  user: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  tenantId: PropTypes.number,
};

UserEdit.defaultProps = {
  id: null,
  user: null,
  tenantId: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    user: state.user.details[helper.getIdFromProps(ownProps)],
    isAdmin: selectors.getIsAdmin(state),
    tenantId: state.user.tenantId,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(UserEdit);
