import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Popup from 'reactjs-popup';

const ConfirmDialog = props => {
  const defaultOptions = {
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
  };

  const {
    confirmation, show, proceed, cancel, options,
  } = props;

  const mergedOptions = { ...defaultOptions, ...options };

  return (
    <Popup open={show} closeOnDocumentClick
      className="confirm-dialog">
      <div className="confirm-container">
        <p>{confirmation || 'Are you sure?'}</p>

        <div className="confirm-buttons">
          <button type="button" onClick={() => cancel('arguments will be passed to the callback')}
            color="primary">
            {mergedOptions.cancelLabel}
          </button>
          <button type="button" onClick={() => proceed('same as cancel')}
            color="secondary">
            {mergedOptions.confirmLabel}
          </button>
        </div>
      </div>
    </Popup>
  );
};

ConfirmDialog.propTypes = {
  show: PropTypes.bool.isRequired, // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func.isRequired, // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func.isRequired, // from confirmable. call to close the dialog with promise rejected.
  // dismiss: PropTypes.func.isRequired, // from confirmable. call to only close the dialog.
  confirmation: PropTypes.string, // arguments of your confirm function
  options: PropTypes.PropTypes.shape(), // arguments of your confirm function
};

ConfirmDialog.defaultProps = {
  confirmation: '',
  options: {},
};

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmDialog);
