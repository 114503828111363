import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GameElement } from '../../utils/enum';
import Image, { GetImageSrc } from '../../controls/Image';
import GameElementSelector from './ElementSelector';
import GameUnionActions from './UnionActions';
import ConjunctionIndicator from '../../controls/ConjunctionIndicator';

class GameParticipantUnion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participantSelectionIsOpen: false,
    };
  }

  addParticipant = (participant) => {
    const { union, onChange } = this.props;

    union.participants.push(participant);
    onChange();
    this.setState({ participantSelectionIsOpen: false });
  };

  removeFromGame = (array, element) => {
    const { onChange } = this.props;

    const index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
      onChange();
    }
  };

  onAddClick = (conjunction) => {
    const { union } = this.props;

    union.childrenConjunction = conjunction;
    this.setState({ participantSelectionIsOpen: true });
  };

  render() {
    const {
      union, onChange, onUnionRemove, advancedMode, canEdit, onAdd, onClick,
    } = this.props;
    const { participantSelectionIsOpen } = this.state;

    return (
      <div className={advancedMode ? 'union-advanced-mode' : 'union-simple-mode'}>
        {advancedMode && (
          <div className="union-title">
            <i className="glyphicon glyphicon-screenshot" />
            Participants
          </div>
        )}
        <ul className="tree">
          {union.participants.map((participant, index) => (
            <li key={`union-${union.id}-participant-${participant.id}`}>
              {index > 0 && (
                <ConjunctionIndicator conjunction={union.childrenConjunction}
                  then={false}
                  onChange={
                    canEdit
                      ? (conjunction) => {
                        union.childrenConjunction = conjunction;
                        onChange();
                      }
                      : null
                  } />
              )}
              <button type="button"
                onClick={() => onClick(participant, GameElement.Participants)}
                className="participant"
                title={participant.name}>
                <Image src={GetImageSrc(participant.imageId, 40)} alt="participant" />
                {participant.name}
              </button>
              {canEdit && (
                <button type="button"
                  className="btn-remove"
                  title="Delete"
                  onClick={() => {
                    this.removeFromGame(union.participants, participant);
                  }}>
                  <i className="glyphicon glyphicon-remove" />
                </button>
              )}
            </li>
          ))}
        </ul>

        {canEdit && (
          <GameUnionActions data={union.participants}
            conjunction={union.childrenConjunction}
            then={false}
            onSelect={conjunction => this.onAddClick(conjunction)} />
        )}

        {advancedMode
          && canEdit && (
            <button type="button" className="btn-remove"
              title="Delete Group" onClick={() => onUnionRemove(union) && onChange()}>
              <i className="glyphicon glyphicon-remove" />
            </button>
        )}
        <GameElementSelector isOpen={participantSelectionIsOpen}
          mode={GameElement.Participants}
          existing={union.participants}
          onClose={() => this.setState({ participantSelectionIsOpen: false })}
          onSelect={participant => this.addParticipant(participant)}
          onAdd={copy => onAdd(union.participants, GameElement.Participants, copy)} />
      </div>
    );
  }
}

GameParticipantUnion.propTypes = {
  union: PropTypes.shape({ id: PropTypes.number }).isRequired,
  onChange: PropTypes.func.isRequired,
  onUnionRemove: PropTypes.func.isRequired,
  advancedMode: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GameParticipantUnion;
