import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BarChart,
  LineChart,
  AreaChart,
  PieChart,
  Bar,
  Line,
  Area,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from 'recharts';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/modules/Dashboard';
import { ChartType } from '../../utils/enum';
import DashboardTotal from './Total';

const COLORS = ['#19A979', '#5899DA', '#E8743B', '#ED4A7B', '#945ECF', '#2F6497', '#BF399E', '#EE6868'];
const stroke = 'white';
const fill = COLORS[0];
const fillOpacity = 0.5;

class DashboardChart extends React.Component {
  componentDidMount() {
    const { requestChart, period, measure } = this.props;

    requestChart(period, measure);
  }

  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return <div className="chart-tooltip">{`${label || (payload && payload[0].name)} : ${payload && payload[0].value}`}</div>;
    }

    return null;
  };

  render() {
    const {
      chart, period, measure, type,
    } = this.props;

    const measureData = chart[measure] ? chart[measure] : {};
    const data = measureData[period] ? measureData[period] : [];

    const margin = {
      top: 5,
      right: 5,
      bottom: 0,
      left: 0,
    };

    if (!data.find(x => x.value !== 0))
      return (<DashboardTotal period={period} measure={0} />);

    if (type === ChartType.Area) {
      return (
        <ResponsiveContainer>
          <AreaChart data={data} margin={margin}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={this.CustomTooltip} />
            <Area type="monotone" dataKey="value"
              stroke={stroke} fillOpacity={fillOpacity}
              fill={fill} />
          </AreaChart>
        </ResponsiveContainer>
      );
    }
    if (type === ChartType.Bar) {
      return (
        <ResponsiveContainer>
          <BarChart data={data} margin={margin}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip cursor={false} content={this.CustomTooltip} />
            <Bar type="monotone" dataKey="value"
              stroke={stroke} fillOpacity={fillOpacity}
              fill={fill} />
          </BarChart>
        </ResponsiveContainer>
      );
    }
    if (type === ChartType.Line) {
      return (
        <ResponsiveContainer>
          <LineChart data={data} margin={margin}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={this.CustomTooltip} />
            <Line type="monotone" dataKey="value"
              stroke={stroke} />
          </LineChart>
        </ResponsiveContainer>
      );
    }
    if (type === ChartType.Pie) {
      return (
        <ResponsiveContainer>
          <PieChart margin={margin}>
            <Tooltip content={this.CustomTooltip} />
            <Legend layout="vertical" align="left" />
            <Pie data={data}
              nameKey="name"
              type="monotone"
              dataKey="value"
              innerRadius="60%"
              outerRadius="90%"
              legendType="square">
              {data.map((entry, index) => (
                <Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]}
                  fillOpacity={fillOpacity} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
    }

    return <div>Invalid Type</div>;
  }
}

DashboardChart.propTypes = {
  period: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  measure: PropTypes.number.isRequired,
  requestChart: PropTypes.func.isRequired,
  chart: PropTypes.shape({}).isRequired,
};

export default connect(
  state => ({
    chart: state.dashboard.chart,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(DashboardChart);
