import Base from './Base';

const base = new Base('level', 'Level');

const initialState = {
  list: {
    data: [],
    paging: { sortBy: 'experienceFrom' },
    preferTable: false,
    filters: {
      isVisible: false,
    },
  },
  all: [],
  details: {},
};

export const actionCreators = {
  ...base.actionCreators,
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  return base.reducer(newState, action);
};
