import Base from './Base';
import { DataType, NotificationType } from '../../utils/enum';

const base = new Base('notification', 'Notification');

const initialState = {
  list: {
    data: [],
    paging: { sortBy: 'createdOn', sortDescending: true },
    preferTable: true,
    filters: {
      isVisible: false,
      fields: [
        {
          name: 'Type',
          column: 'Type',
          type: DataType.Number,
          operator: '=',
          value: null,
          enum: NotificationType,
        }, {
          name: 'Source',
          column: 'Round.GameId',
          type: DataType.Number,
          operator: '=',
          value: null,
          dataSourceKey: 'game&leaderboard',
        }, {
          name: 'Player',
          column: 'Player.Name',
          type: DataType.Text,
          operator: 'Contains',
          value: null,
        }],
    },
  },
  details: {},
};

export const actionCreators = {
  ...base.actionCreators,
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  return base.reducer(newState, action);
};
