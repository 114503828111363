import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as fileActionCreators } from '../store/modules/File';
import Image, { GetImageSrc } from '../controls/Image';

const ListImage = props => {
  const {
    items, currentItem, openImages, className, imageSize,
  } = props;

  function openImage() {
    if (currentItem.imageId) {
      const imageItems = items.filter(item => item.imageId);

      if (imageItems.indexOf(currentItem) < 0) {
        imageItems.push(currentItem);
      }

      openImages(imageItems, imageItems.indexOf(currentItem));
    }
  }

  return (
    <button type="button"
      className="image-wrapper"
      style={{ cursor: currentItem.imageId ? 'pointer' : 'default' }}
      onClick={() => openImage()}>
      <Image className={className} src={GetImageSrc(currentItem.imageId, imageSize)}
        alt={currentItem.name ?? currentItem.id} />
    </button>
  );
};

ListImage.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  currentItem: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, imageId: PropTypes.string }).isRequired,
  openImages: PropTypes.func.isRequired,
  className: PropTypes.string,
  imageSize: PropTypes.number,
};

ListImage.defaultProps = {
  items: [],
  className: 'avatar lg',
  imageSize: 80,
};

export default connect(
  null,
  dispatch => bindActionCreators({ openImages: fileActionCreators.openImages }, dispatch),
)(ListImage);
