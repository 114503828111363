import { DataType } from '../../utils/enum';
import Base from './Base';

const base = new Base('attribute/player', 'Attribute');

const initialState = {
  list: {
    data: [],
    paging: {},
    preferTable: false,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Disabled',
        column: 'IsDisabled',
        type: DataType.Boolean,
        operator: '=',
        value: null,
      },
      ],
    },
  },
  all: [],
  details: {},
};

export const actionCreators = {
  ...base.actionCreators,
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  return base.reducer(newState, action);
};
