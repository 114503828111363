import { toast } from 'react-toastify';
import * as User from './User';

const remove = 'gzone/alert/remove';
const initialState = [];

export const actionCreators = {
  addError: payload => async dispatch => {
    let description;

    // check if api response
    if (payload.response) {
      if (payload.response.status === 401) {
        dispatch({ type: User.requestLogoutType });
        description = 'Unauthorized. Please relogin.';
      } else {
        const response = payload.response.data;
        description = response.error_description ? response.error_description : response.error;
      }

      if (!description) {
        description = JSON.stringify(payload.response.data);
      }
    }

    if (!description && payload.message) {
      description = payload.message;
    }

    if (!description) {
      description = payload;
    }

    dispatch({
      type: toast.TYPE.ERROR,
      description,
    });
  },
  addSuccess: description => async dispatch => {
    dispatch({
      type: toast.TYPE.SUCCESS,
      description,
    });
  },
  addWarning: description => async dispatch => {
    dispatch({
      type: toast.TYPE.WARNING,
      description,
    });
  },
  addInfo: description => async dispatch => {
    dispatch({
      type: toast.TYPE.INFO,
      description,
    });
  },
  removeAlert: id => async dispatch => {
    dispatch({ type: remove, id });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (
    (action.type === toast.TYPE.ERROR && !newState.find(e => e.type === action.type && e.description === action.description))
    || action.type === toast.TYPE.SUCCESS
    || action.type === toast.TYPE.INFO
    || action.type === toast.TYPE.WARNING
  ) {
    return newState.concat({
      id: new Date().getTime(),
      description: action.description,
      type: action.type,
    });
  }

  if (action.type === remove) {
    return newState.filter(e => e.id !== action.id);
  }

  return newState;
};
