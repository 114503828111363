import React from 'react';
import PropTypes from 'prop-types';
import SelectField from './SelectField';
import { DataType, Operator } from '../utils/enum';

const OperatorField = ({
  field, form, className, dataType, onChange,
}) => {
  const options = [];

  options.push({ value: Operator.EqualTo, label: Operator[Operator.EqualTo] });
  options.push({ value: Operator.NotEqualTo, label: Operator[Operator.NotEqualTo] });

  if (dataType === DataType.Text) {
    options.push({ value: Operator.Contains, label: Operator[Operator.Contains] });
    options.push({ value: Operator.DoesNotContain, label: Operator[Operator.DoesNotContain] });
  }

  if (dataType === DataType.Number || dataType === DataType.Date) {
    options.push({ value: Operator.GreaterThan, label: Operator[Operator.GreaterThan] });
    options.push({ value: Operator.GreaterOrEqualTo, label: Operator[Operator.GreaterOrEqualTo] });
    options.push({ value: Operator.LessThan, label: Operator[Operator.LessThan] });
    options.push({ value: Operator.LessOrEqualTo, label: Operator[Operator.LessOrEqualTo] });
  }

  if (dataType === DataType.Date) {
    options.push({ value: Operator.WeekdayIs, label: Operator[Operator.WeekdayIs] });
    options.push({ value: Operator.WeekdayIsNot, label: Operator[Operator.WeekdayIsNot] });
    options.push({ value: Operator.YearIs, label: Operator[Operator.YearIs] });
    options.push({ value: Operator.YearIsNot, label: Operator[Operator.YearIsNot] });
    options.push({ value: Operator.MonthIs, label: Operator[Operator.MonthIs] });
    options.push({ value: Operator.MonthIsNot, label: Operator[Operator.MonthIsNot] });
    options.push({ value: Operator.DayIs, label: Operator[Operator.DayIs] });
    options.push({ value: Operator.DayIsNot, label: Operator[Operator.DayIsNot] });
    options.push({ value: Operator.HourIs, label: Operator[Operator.HourIs] });
    options.push({ value: Operator.HourIsNot, label: Operator[Operator.HourIsNot] });
  }

  if (dataType) {
    return (
      <SelectField options={options} field={field}
        onChange={onChange} form={form}
        className={className} />
    );
  }

  return <div className={className} />;
};

OperatorField.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  className: PropTypes.string.isRequired,
  dataType: PropTypes.number,
  onChange: PropTypes.func,
};

OperatorField.defaultProps = {
  dataType: null,
  onChange: null,
};

export default OperatorField;
