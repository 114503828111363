import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { LinkContainer } from 'react-router-bootstrap';
import { bindActionCreators } from 'redux';
import Carousel, { Modal, ModalGateway } from 'react-images';
import NavMenu from './NavMenu';
import Logo from '../images/logo.png';
import Image, { GetImageSrc } from '../controls/Image';
import { actionCreators, selectors } from '../store/modules/User';
import { actionCreators as tenantActionCreators } from '../store/modules/Tenant';
import { actionCreators as fileActionCreators } from '../store/modules/File';
import 'rc-switch/assets/index.css';
import ListImage from './ListImage';
import { Convert, Option, SingleValue } from '../controls/SelectField';

class Layout extends PureComponent {
  componentDidMount() {
    const { isAdmin, requestAllTenants } = this.props;

    if (isAdmin) {
      requestAllTenants();
    }
  }

  render() {
    const {
      children,
      username,
      name,
      tenantName,
      imageId,
      tenants,
      selectTenant,
      tenantId,
      isAdmin,
      openedImages,
      openedImageIndex,
      openImages,
    } = this.props;

    const onTenantSelect = selected => {
      const newTenantId = selected ? selected.value : null;
      const redirect = newTenantId == null || tenantId == null;
      if (tenantId !== newTenantId) selectTenant(newTenantId, redirect);
    };

    return (
      <>
        <div id="gaas-sidebar" className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
          <div className="gaas-sidebar-logo">
            <Image src={Logo} alt="logo"
              initialTimeout={5000} />
          </div>
          <div id="gaas-sidebar-user">
            <ListImage imageSize={60} className=""
              currentItem={{ imageId, name }} />
            <div>
              <LinkContainer to="/profile" className="link">
                <div>
                  {name}
                  <br />
                  {username}
                </div>
              </LinkContainer>
              {tenantName && (
                <LinkContainer to="/tenant/profile" className="link">
                  <div>{tenantName}</div>
                </LinkContainer>
              )}
            </div>
          </div>
          <hr />
          {isAdmin
            && tenants
            && tenants.length > 0 && (
            <div>
              <Select placeholder="Administrator"
                className="react-select-container"
                classNamePrefix="react-select"
                options={Convert(tenants)}
                value={
                  Convert(tenants).find(option => option.value === tenantId)
                }
                isClearable
                isOptionDisabled={() => false}
                onChange={onTenantSelect}
                components={{ SingleValue, Option }} />
              <hr />
            </div>
          )}
          <NavMenu />
          <div className="scope-title">by Scope Technology</div>
        </div>

        <div id="gaas-content" className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
          <div id="content">
            <div id="content-loader"
              className={
                children.props
                && children.props.children
                && children.props.children.key
                && (children.props.children.key.indexOf('/game/') > -1 || children.props.children.key === 'route-container-/')
                  ? 'wide'
                  : ''
              }>
              {children}
            </div>
          </div>
        </div>

        <ModalGateway>
          {openedImages.length > 0 ? (
            <Modal onClose={() => {
              openImages([]);
            }}>
              <Carousel currentIndex={openedImageIndex}
                frameProps={{ autoSize: 'height' }}
                views={openedImages.map(item => ({
                  src: GetImageSrc(item.imageId),
                  thumbnail: GetImageSrc(item.imageId, 50),
                  caption: item.name,
                }))} />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  username: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tenantName: PropTypes.string,
  imageId: PropTypes.string,
  selectTenant: PropTypes.func.isRequired,
  tenants: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })).isRequired,
  tenantId: PropTypes.number,
  isAdmin: PropTypes.bool.isRequired,
  requestAllTenants: PropTypes.func.isRequired,
  openedImages: PropTypes.arrayOf(PropTypes.shape({ imageId: PropTypes.string, name: PropTypes.string })),
  openedImageIndex: PropTypes.number,
  openImages: PropTypes.func.isRequired,
};

Layout.defaultProps = {
  imageId: null,
  tenantId: null,
  tenantName: null,
  openedImages: [],
  openedImageIndex: 0,
};

export default connect(
  state => ({
    ...state.user.auth,
    tenants: state.tenant.all,
    isAdmin: selectors.getIsAdmin(state),
    tenantId: state.user.tenantId,
    openedImages: state.file.openedImages,
    openedImageIndex: state.file.openedImageIndex,
  }),
  dispatch => bindActionCreators(
    {
      ...actionCreators,
      requestAllTenants: tenantActionCreators.requestAll,
      openImages: fileActionCreators.openImages,
    },
    dispatch,
  ),
)(Layout);
